<template>
  <v-card :rounded="!flat" :elevation="flat ? 0 : 3" class="calendesk-tabs">
    <v-card-text class="pa-0 ma-0 mb-8">
      <slot name="top" />
      <v-tabs
        v-if="!hiddenTabs"
        v-model="tab"
        :centered="centered"
        height="55"
        color="blue"
        show-arrows
        @change="onChange"
      >
        <v-tab
          v-for="(item, index) in items"
          :id="'tab_' + index"
          :key="item.tab"
          :disabled="disabled"
        >
          <div v-if="item.chip">
            {{ $trans(item.tab) }}
            <v-chip
              :color="item.chip_color"
              class="font-weight-bold white--text"
            >
              {{ item.chip }}
              <v-icon v-if="item.chip_icon" small>
                {{ item.chip_icon }}
              </v-icon>
            </v-chip>
          </div>
          <span v-else>
            {{ $trans(item.tab) }}
          </span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in items"
          :key="item.tab"
          :transition="false"
          :reverse-transition="false"
        >
          <div v-if="item.content" :class="itemClasses">
            <component :is="item.content" v-bind="item.props" :ref="item.tab" />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CTabs",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    centered: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hiddenTabs: {
      type: Boolean,
      default: false,
    },
    initialTab: {
      type: Number,
      default: 0,
    },
    itemClasses: {
      type: Object,
      default: () => {
        return { "mt-8": true };
      },
    },
  },
  data() {
    return {
      tab: this.initialTab,
    };
  },
  methods: {
    onChange(e) {
      this.$emit("change", e);
    },
  },
};
</script>
