import { mapGetters } from "vuex";
import { debounce } from "debounce";
import eventRoot from "@/components/Event/mixins/eventRoot";
import eventParser from "@/calendesk/mixins/eventParser";

export default {
  model: {
    prop: "value",
    event: "input",
  },
  mixins: [eventRoot, eventParser],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    rawEvent: {
      type: Object,
      default: () => ({
        id: null,
      }),
    },
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  watch: {
    value(value) {
      this.dialog = value;
    },
    dialog(value) {
      this.parsedEvent = null;
      if (value) {
        this.parseEventDebounce(value);
      }
      this.$emit("input", value);
    },
    shouldRefreshBookings(val) {
      if (this.dialog && val) {
        this.parseEvent();
      }
    },
  },
  computed: {
    ...mapGetters({
      shouldRefreshBookings: "booking/shouldRefreshBookings",
    }),
  },
  methods: {
    parseEventDebounce: debounce(function () {
      this.parseEvent();
    }, 600),
    async parseEvent() {
      const parsedEvent = await this.parseRawEvent(this.rawEvent);

      if (parsedEvent) {
        this.parsedEvent = parsedEvent;
      } else {
        this.$root.$emit("closeEventModal", true);
        this.$root.$emit("closeEventPreviewModal", true);
      }
    },
  },
};
