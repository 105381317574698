<template>
  <v-row>
    <v-col cols="12">
      <div>
        <div class="font-weight-medium mb-2">{{ $trans("description") }}:</div>
        <div>
          {{ parsedEvent.description }}
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "EventDescription",
  props: {
    parsedEvent: {
      type: Object,
      required: true,
    },
  },
};
</script>
