<template>
  <v-container fluid>
    <v-row justify="center" class="mt-8">
      <v-col class="text-center">
        <img :src="require('@/assets/images/thankyou-page-image.jpg')" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="text-center mb-4" cols="12" md="8">
        <h3 class="text-h3 font-weight-bold">
          {{ $trans("plans_thank_you_page_title_part_1") }}
          <span class="green--text">{{
            $trans("plans_thank_you_page_title_part_2")
          }}</span>
        </h3>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col cols="12" md="8" class="pa-2 d-flex justify-center text-center">
        {{ $trans("plans_thank_you_page_subtitle") }}
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="text-center">
        <v-btn
          class="my-6"
          color="blue"
          x-large
          dark
          :loading="isFetchingPlanData"
          @click="doneHandle"
        >
          {{ $trans("plans_thank_you_page_button") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import planActions from "@/calendesk/mixins/planActions";

export default {
  name: "PlanConfigurationSummary",
  mixins: [planActions],
  data() {
    return {
      isFetchingPlanData: false,
      interval: null,
    };
  },
  methods: {
    doneHandle() {
      this.isFetchingPlanData = true;
      this.reload();
      this.interval = setInterval(() => {
        this.reload();
      }, 10000);
    },
    reload() {
      this.fetchRequiredAppComponents().finally(() => {
        if (
          this.currentPlan.on_trial ||
          this.$moment(this.currentPlan.current_period_end).isAfter()
        ) {
          clearInterval(this.interval);
          this.interval = null;
          this.$root.$emit("closePlanView", true);
        }
      });
    },
  },
};
</script>
