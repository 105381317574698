import dialogTypes from "@/components/Dialogs/dialogTypes";
import sharedDialogTypes from "@/lib/calendesk-js-library/components/dialogs/sharedDialogTypes";

export default [
  // Shared
  {
    type: sharedDialogTypes.ASSETS_DIALOG,
    content: () =>
      import(
        "@/lib/calendesk-js-library/components/dialogs/components/AssetsDialog/AssetsDialog"
      ),
  },
  {
    type: sharedDialogTypes.EDITOR_IMAGE_DIALOG,
    content: () =>
      import(
        "@/lib/calendesk-js-library/components/dialogs/components/EditorImageDialog/EditorImageDialog"
      ),
  },
  {
    type: sharedDialogTypes.FEATURE_NOT_AVAILABLE_DIALOG,
    content: () =>
      import(
        "@/lib/calendesk-js-library/components/dialogs/components/FeatureNotAvailableDialog/FeatureNotAvailableDialog"
      ),
  },
  {
    type: sharedDialogTypes.PACKAGE_NOT_AVAILABLE_DIALOG,
    content: () =>
      import(
        "@/lib/calendesk-js-library/components/dialogs/components/PackageNotAvailableDialog/PackageNotAvailableDialog"
      ),
  },

  // Local
  {
    type: dialogTypes.CREATE_USER_SUBSCRIPTION,
    content: () => import("./CreateUserSubscriptionDialog"),
  },
  {
    type: dialogTypes.CONFIRM_PASSWORD_CHANGED,
    content: () => import("./ConfirmPasswordChangedDialog"),
  },
  {
    type: dialogTypes.FORGOTTEN_PASSWORD,
    content: () => import("./ForgottenPassword/ForgottenPasswordDialog"),
  },
  {
    type: dialogTypes.RESET_PASSWORD,
    content: () => import("./ResetPassword/ResetPasswordDialog"),
  },
  {
    type: dialogTypes.CONFIRM_SEND_MAIL,
    content: () => import("./ConfirmSendMailDialog"),
  },
  {
    type: dialogTypes.PUBLISH_MOBILE_DRAFT_DIALOG,
    content: () => import("./PublishMobileDraftDialog"),
  },
  {
    type: dialogTypes.BUY_NOTIFICATION_DIALOG,
    content: () => import("./BuyNotificationDialog"),
  },
  {
    type: dialogTypes.CANCEL_PLAN_DIALOG,
    content: () => import("./CancelPlanDialog/CancelPlanDialog"),
  },
  {
    type: dialogTypes.CREATE_MOBILE_DRAFT_DIALOG,
    content: () => import("./CreateMobileDraftDialog"),
  },
  {
    type: dialogTypes.SEND_MOBILE_APP_LINK_DIALOG,
    content: () => import("./SendMobileAppLinkDialog/SendMobileAppLinkDialog"),
  },
  {
    type: dialogTypes.ADD_PAYMENT_METHOD_DIALOG,
    content: () => import("./PaymentMethod/AddPaymentMethodDialog"),
  },
  {
    type: dialogTypes.ADD_NEW_EMPLOYEE,
    content: () => import("./NewEmployeeDialog"),
  },
  {
    type: dialogTypes.CHANGE_DRAFT_NAME,
    content: () => import("./ChangeDraftNameDialog"),
  },
  {
    type: dialogTypes.CHOOSE_TENANT,
    content: () => import("./ChooseTenantDialog"),
  },
  {
    type: dialogTypes.NEWSLETTER_CREATE_NEW_RECORD,
    content: () => import("./AddNewsletterRecordDialog"),
  },
  {
    type: dialogTypes.SEND_CUSTOMER_NOTIFICATION,
    content: () => import("./SendCustomerNotificationDialog"),
  },
  {
    type: dialogTypes.DISPLAY_CUSTOMER_NOTIFICATION,
    content: () => import("./DisplayCustomerNotificationDialog"),
  },
  {
    type: dialogTypes.ENABLE_ZOOM,
    content: () => import("./EnableZoomDialog"),
  },
  {
    type: dialogTypes.ZOOM,
    content: () =>
      import("@/views/dashboard/pages/Integrations/Zoom/ConnectedZoom"),
  },
  {
    type: dialogTypes.GOOGLE_CALENDAR,
    content: () =>
      import(
        "@/views/dashboard/pages/Integrations/GoogleCalendar/GoogleCalendar"
      ),
  },
  {
    type: dialogTypes.OUTLOOK_CALENDAR,
    content: () =>
      import(
        "@/views/dashboard/pages/Integrations/OutlookCalendar/OutlookCalendar"
      ),
  },
  {
    type: dialogTypes.WHATSAPP,
    content: () =>
      import("@/views/dashboard/pages/Integrations/WhatsApp/WhatsApp"),
  },
  {
    type: dialogTypes.STRIPE,
    content: () =>
      import("@/views/dashboard/pages/Integrations/Stripe/StripeIntegration"),
  },
  {
    type: dialogTypes.FAKTUROWNIA,
    content: () =>
      import(
        "@/views/dashboard/pages/Integrations/Fakturownia/FakturowniaIntegration"
      ),
  },
  {
    type: dialogTypes.ZAPIER,
    content: () => import("./ZapierDialog"),
  },
  {
    type: dialogTypes.API_INTEGRATION,
    content: () =>
      import("@/views/dashboard/pages/Integrations/API/ApiIntegration"),
  },
  {
    type: dialogTypes.HELP_CENTER,
    content: () => import("./HelpCenterDialog"),
  },
  {
    type: dialogTypes.AVAILABILITY_SCHEDULE,
    content: () =>
      import("./AvailabilityScheduleDialog/AvailabilityScheduleDialog"),
  },
  {
    type: dialogTypes.USER_PAYMENT_TRANSACTIONS,
    content: () => import("./UserPaymentTransactionsDialog"),
  },
  {
    type: dialogTypes.ADD_SIMPLE_STORE_PRODUCT_PAYMENT_TRANSACTION,
    content: () => import("./CreateSimpleStoreProductPaymentTransactionDialog"),
  },
  {
    type: dialogTypes.CALENDAR_SETTINGS,
    content: () =>
      import(
        "@/views/dashboard/pages/Calendar/components/forms/CalendarSettingsForm"
      ),
  },
  {
    type: dialogTypes.MANAGE_LOCATION,
    content: () => import("./ManageLocationDialog"),
  },
  {
    type: dialogTypes.AVAILABLE_SCHEDULE_ASSIGN_TO_EMPLOYEES,
    content: () => import("./AvailabilityScheduleAssignToEmployeesDialog.vue"),
  },
  {
    type: dialogTypes.AVAILABLE_SCHEDULE_ASSIGN_TO_SERVICES,
    content: () => import("./AvailabilityScheduleAssignToServicesDialog.vue"),
  },
];
