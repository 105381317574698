var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getCreateBookingTransactionDialog)?_c('v-dialog',{attrs:{"persistent":"","scrollable":"","width":!_vm.$vuetify.breakpoint.mdAndUp ? null : '900px',"fullscreen":!_vm.$vuetify.breakpoint.mdAndUp},model:{value:(_vm.getCreateBookingTransactionDialog),callback:function ($$v) {_vm.getCreateBookingTransactionDialog=$$v},expression:"getCreateBookingTransactionDialog"}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSave.apply(null, arguments)}}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-toolbar',{attrs:{"flat":"","max-height":"56px","fixed":""}},[_c('v-btn',{attrs:{"fab":"","x-small":"","disabled":_vm.isLoading || _vm.isMakingPayment},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v(" $close ")])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$trans("booking_payment_transaction_create_title"))+" ("+_vm._s(_vm.getCreateBookingTransactionDialog.booking.id)+") ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","depressed":"","outlined":"","loading":_vm.isMakingPayment,"disabled":_vm.isLoading || _vm.isMakingPayment},on:{"click":_vm.handleSave}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$cash")]),_c('span',[_vm._v(_vm._s(_vm.$trans("pay"))+" ("+_vm._s(_vm._f("money")(_vm.paymentSum))+")")])],1)],1),(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-toolbar',{staticClass:"d-flex justify-center",attrs:{"flat":"","max-height":"56px","fixed":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$trans("booking_payment_transaction_create_title"))+" ("+_vm._s(_vm.getCreateBookingTransactionDialog.booking.id)+") ")])],1):_vm._e(),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[(!_vm.paysWithSubscription)?_c('v-row',[(
                !_vm.showFakturowniaWarning && !_vm.showCustomerDataInvoiceWarning
              )?_c('v-col',{attrs:{"cols":"12"}},[_c('calendesk-information-message',[_vm._v(" "+_vm._s(_vm.$trans("create_payment_invoice_info"))+" ")])],1):(_vm.showFakturowniaWarning)?_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12"}},[_c('calendesk-information-message',{attrs:{"color":"orange","additional-class":"white--text","icon-color":"white","icon":"$alert"}},[_vm._v(" "+_vm._s(_vm.$trans("invoice_warning_fakturownia_disabled"))+" ")])],1):(_vm.showCustomerDataInvoiceWarning)?_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12"}},[_c('calendesk-information-message',{attrs:{"color":"orange","additional-class":"white--text","icon-color":"white","icon":"$alert"}},[_vm._v(" "+_vm._s(_vm.$trans("invoice_warning_client_data"))+" ")])],1):_vm._e()],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.paymentMethods,"hide-details":"","label":_vm.$trans('payment_method'),"outlined":"","rules":[_vm.rules.required],"disabled":_vm.isLoading || _vm.isMakingPayment},model:{value:(_vm.paymentMethod),callback:function ($$v) {_vm.paymentMethod=$$v},expression:"paymentMethod"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(!_vm.paysWithSubscription)?_c('v-text-field',{attrs:{"hide-details":"","type":"number","rules":[
                  _vm.rules.required,
                  _vm.rules.maxNumber,
                  _vm.rules.minNumber(_vm.amount, 0) ],"label":_vm.$trans('invoice_total'),"outlined":"","suffix":_vm.$config('currency'),"disabled":_vm.isLoading || _vm.isMakingPayment || _vm.paysWithSubscription},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}):_c('v-select',{attrs:{"rules":[_vm.rules.required],"items":_vm.acceptedSubscriptions,"item-text":_vm.getSubscriptionText,"item-value":"id","outlined":"","label":_vm.$trans('select_subscription'),"disabled":_vm.isLoading || _vm.isMakingPayment},model:{value:(_vm.selectedUserSubscriptionId),callback:function ($$v) {_vm.selectedUserSubscriptionId=$$v},expression:"selectedUserSubscriptionId"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.statuses,"hide-details":"","rules":[_vm.rules.required],"label":_vm.$trans('status'),"outlined":"","disabled":_vm.isLoading || _vm.isMakingPayment || _vm.paysWithSubscription},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"label":_vm.$trans('paid'),"hide-details":"","disabled":_vm.isLoading || _vm.isMakingPayment || _vm.paysWithSubscription},model:{value:(_vm.isPaid),callback:function ($$v) {_vm.isPaid=$$v},expression:"isPaid"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(!_vm.paysWithSubscription)?_c('v-text-field',{attrs:{"hide-details":"","rules":[_vm.rules.maxChars(255)],"label":_vm.$trans('payment_transaction_transaction_id'),"outlined":"","disabled":_vm.isLoading || _vm.isMakingPayment || _vm.paysWithSubscription},model:{value:(_vm.transactionId),callback:function ($$v) {_vm.transactionId=$$v},expression:"transactionId"}}):_vm._e()],1)],1),(
              !_vm.paysWithSubscription &&
              _vm.isPaid &&
              _vm.getCreateBookingTransactionDialog.booking.products &&
              _vm.getCreateBookingTransactionDialog.booking.products.length > 0
            )?_c('v-row',[_c('v-col',{staticClass:"text-h6 mb-0 pb-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$trans("pay_for_products"))+": ")]),_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.getCreateBookingTransactionDialog.booking
                  .products),function(product){return _c('v-checkbox',{key:product.id,attrs:{"value":product.id,"label":_vm.getProductLabel(product),"disabled":_vm.isLoading ||
                  _vm.isMakingPayment ||
                  _vm.productHasTransaction(product),"hide-details":"auto"},model:{value:(_vm.productsToPay),callback:function ($$v) {_vm.productsToPay=$$v},expression:"productsToPay"}})}),1)],1):_vm._e()],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }