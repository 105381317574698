<template>
  <v-tooltip
    v-if="getEmployee && getEmployee.user"
    bottom
    :disabled="
      !detailsInTooltip ||
      (!getEmployee.user.email &&
        !getEmployee.user.default_phone &&
        !getEmployee.title)
    "
  >
    <template #activator="{ on }">
      <div v-on="on">
        <div
          class="d-flex align-center"
          :class="{
            'justify-left': justifyLeft,
            'justify-center': !justifyLeft,
            pointer: clickable,
          }"
          @click.stop="
            clickable
              ? $helpers.goToEmployee(getEmployee, pushTransition)
              : null
          "
        >
          <v-avatar left class="mr-2 align-self-start" :size="dense ? 32 : 42">
            <v-img :src="$helpers.getAvatarSrc(getEmployee.user)" />
          </v-avatar>
          <span>{{ $helpers.getEmployeeName(getEmployee) }}</span>
        </div>
        <div
          v-if="
            !detailsInTooltip &&
            (getEmployee.user.email ||
              getEmployee.user.default_phone ||
              getEmployee.user.title)
          "
          class="mt-4"
        >
          <div v-if="getEmployee.title && !hideTitle">
            {{ $trans("employee_position") }}: {{ getEmployee.title }}
          </div>
          <div v-if="getEmployee.user.email && !hideEmail">
            {{ $trans("email") }}: {{ getEmployee.user.email }}
          </div>
          <div v-if="getEmployee.user.default_phone && !hidePhone">
            {{ $trans("phone") }}: {{ getEmployee.user.default_phone.e164 }}
          </div>
        </div>
      </div>
    </template>
    <div
      v-if="
        detailsInTooltip &&
        (getEmployee.user.email ||
          getEmployee.user.default_phone ||
          getEmployee.title)
      "
    >
      <div v-if="getEmployee.title && !hideTitle">
        {{ $trans("employee_position") }}: {{ getEmployee.title }}
      </div>
      <div v-if="getEmployee.user.email && !hideEmail">
        {{ $trans("email") }}: {{ getEmployee.user.email }}
      </div>
      <div v-if="getEmployee.user.default_phone && !hidePhone">
        {{ $trans("phone") }}: {{ getEmployee.user.default_phone.e164 }}
      </div>
    </div>
  </v-tooltip>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    employee: {
      type: Object,
      required: false,
      default: () => {},
    },
    employeeId: {
      type: Number,
      required: false,
      default: null,
    },
    justifyLeft: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    detailsInTooltip: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    hidePhone: {
      type: Boolean,
      default: false,
    },
    hideEmail: {
      type: Boolean,
      default: false,
    },
    pushTransition: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      employees: "employee/getAllEmployees",
    }),
    getEmployee() {
      if (this.employeeId && this.employees) {
        return this.employees.find(
          (employee) => employee.id === this.employeeId
        );
      }

      return this.employee;
    },
  },
};
</script>
