export default {
  services: [
    {
      id: 1,
      name: "Online Consultation",
      default_image: null,
      status: "active",
      order_position: "65535",
      images: [],
      services: [
        {
          id: 1,
          category_id: 1,
          name: "Online Consultation",
          description:
            "Get expert advice on various topics from our experienced consultants. From business to personal issues, our consultants are here to help you. Book a consultation now and get the guidance you need!",
          booking_time_before: 1440,
          cancel_time_before: 1440,
          change_time_before: 1440,
          awaiting_booking_payment_time: 60,
          max_people: 1,
          duration: 60,
          slots_every: 15,
          price: 5000,
          payment_required: false,
          status: "active",
          order_position: "65535",
          default_booking_status: "approved",
          default_image: null,
          category: {
            id: 1,
            name: "Consultation Services",
            default_image: null,
            status: "active",
            order_position: "65535",
          },
          employees: [
            {
              id: 1,
              title: null,
              description:
                "Our consultants are experts in their field. They have years of experience and are up-to-date with the latest trends and practices. Book a consultation with us now and get the guidance you need!",
              facebook: null,
              instagram: null,
              youtube: null,
              twitter: null,
              user: {
                id: 2,
                name: "John",
                surname: "Smith",
                status: "active",
                default_image: {
                  id: 192,
                  name: "/demo/placeholder.jpg",
                  user_image_id: null,
                },
              },
              services: [
                {
                  id: 1,
                  name: "Online Consultation",
                },
              ],
            },
          ],
          types: [],
          resources: [],
          images: [],
        },
      ],
    },
  ],
  employees: [
    {
      id: 1,
      title: null,
      description: null,
      facebook: null,
      instagram: null,
      youtube: null,
      twitter: null,
      color: "#DCFFDB",
      is_public: true,
      days: [],
      user: {
        id: 2,
        name: "John",
        surname: "Smith",
        email: "john@calendesk.com",
        status: "active",
        gender: null,
        date_of_birth: null,
        permissions: [],
        roles: [],
        default_image: {
          id: 192,
          name: "/demo/placeholder.jpg",
          user_image_id: null,
        },
        default_phone: null,
        default_address: null,
        created_at: "2020-11-03T07:53:36.000000Z",
      },
      services: [{ id: 1, name: "Online Consultation" }],
    },
  ],
  companyWorkingHours: [
    {
      day: "monday",
      open: "09:00",
      close: "17:00",
      is_open: true,
    },
    {
      day: "tuesday",
      open: "09:00",
      close: "17:00",
      is_open: true,
    },
    {
      day: "wednesday",
      open: "09:00",
      close: "17:00",
      is_open: true,
    },
    {
      day: "thursday",
      open: "09:00",
      close: "17:00",
      is_open: true,
    },
    {
      day: "friday",
      open: "09:00",
      close: "17:00",
      is_open: true,
    },
    {
      day: "saturday",
      open: null,
      close: null,
      is_open: false,
    },
    {
      day: "sunday",
      open: null,
      close: null,
      is_open: false,
    },
  ],
  scheduledBookings: [
    {
      date: "February 5, 2022 12:00 PM",
      title: "Online Consultation",
      description:
        "Get expert advice on various topics from our experienced consultants.",
      cost: "12000",
      employee: {
        fullName: "John Smith",
        avatar: "https://media.calendesk.com/demo/c0-employee-2.jpg",
      },
      phone: "+1 123 456 7890",
      status: 0,
    },
    {
      date: "February 5, 2022 12:00 PM",
      title: "Online Consultation",
      description:
        "Get expert advice on various topics from our experienced consultants.",
      cost: "12000",
      employee: {
        fullName: "John Smith",
        avatar: "https://media.calendesk.com/demo/c0-employee-2.jpg",
      },
      phone: "+1 123 456 7890",
      status: 0,
    },
  ],
  afterBookings: [
    {
      date: "February 5, 2022 12:00 PM",
      title: "Online Consultation",
      description:
        "Get expert advice on various topics from our experienced consultants.",
      cost: "12000",
      employee: {
        fullName: "John Smith",
        avatar: "https://media.calendesk.com/demo/c0-employee-2.jpg",
      },
      phone: "+1 123 456 7890",
      status: 1,
    },
    {
      date: "February 5, 2022 12:00 PM",
      title: "Online Consultation",
      description:
        "Get expert advice on various topics from our experienced consultants.",
      cost: "12000",
      employee: {
        fullName: "John Smith",
        avatar: "https://media.calendesk.com/demo/c0-employee-2.jpg",
      },
      phone: "+1 123 456 7890",
      status: 2,
    },
  ],
  profile: {
    fullName: "John Smith",
    email: "john@calendesk.com",
    avatar: "https://media.calendesk.com/demo/c0-employee-1.jpg",
  },
};
