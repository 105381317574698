export default {
  computed: {
    helpUrl() {
      if (localStorage.locale === "pl") {
        return "https://calendesk.com/pl/help/";
      }

      return "https://calendesk.com/help/";
    },
  },
};
