<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="name"
          :label="$trans('name')"
          :rules="[rules.required, rules.maxChars(50)]"
          validate-on-blur
          outlined
          hide-details="auto"
          prepend-icon="$account"
          @change="updated"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="surname"
          :label="$trans('surname')"
          :rules="[rules.required, rules.maxChars(50)]"
          validate-on-blur
          outlined
          hide-details="auto"
          :prepend-icon="$vuetify.breakpoint.mdAndUp ? null : '$account'"
          @change="updated"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="companyName"
          :label="$trans('login_tenant')"
          :rules="[rules.required, rules.minChars(4), rules.maxChars(50)]"
          color="primary"
          outlined
          prepend-icon="$home"
          :placeholder="$trans('full_company_name_example')"
          validate-on-blur
          hide-details="auto"
          @change="updated"
        />
      </v-col>
      <v-col cols="12">
        <phone-input
          ref="phoneInput"
          v-model="phone"
          prepend-icon="$phone"
          :default-country-code="phoneCountryIsoCode"
          :rules="[rules.required]"
          hide-details="auto"
        />
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="12" md="6">
        <c-time-zone-autocomplete
          v-model="timeZone"
          show-icon
          dense
          :label="$trans('c_time_zone_autocomplete_label')"
          :rules="[rules.required]"
        />
      </v-col>
      <v-col cols="12" md="6">
        <c-currency-select
          v-model="currency"
          show-icon
          dense
          :label="$trans('c_currency_select_label')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import {
  email,
  maxChars,
  minChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import PhoneInput from "@/components/Forms/PhoneInput/PhoneInput";
import CTimeZoneAutocomplete from "@/views/dashboard/pages/Settings/components/CTimeZoneAutocomplete";
import CCurrencySelect from "@/views/dashboard/pages/Settings/components/CCurrencySelect";

export default {
  name: "CSignupOnboardingCompany",
  components: { CCurrencySelect, CTimeZoneAutocomplete, PhoneInput },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      companyName: null,
      name: null,
      surname: null,
      phone: null,
      phoneCountryIsoCode: null,
      timeZone: null,
      currency: null,
      rules: {
        required,
        email,
        minChars,
        maxChars,
      },
    };
  },
  computed: {
    ...mapGetters({
      locale: "setup/getLocale",
    }),
  },
  created() {
    this.reloadLocale();
    this.restoreData();

    this.$watch("locale", this.reloadLocale);
    this.$watch("phone", this.updated);
    this.$watch("timeZone", this.updated);
    this.$watch("currency", this.updated);
  },
  methods: {
    restoreData() {
      if (this.data && this.data.name) {
        this.name = this.data.name;
        this.surname = this.data.surname;
        this.companyName = this.data.companyName;
        this.phone = this.data.phone;
        this.phoneCountryIsoCode = this.data.phoneCountryIsoCode;
        this.timeZone = this.data.timeZone;
        this.currency = this.data.currency;
      }
    },
    reloadLocale() {
      if (this.locale) {
        this.phoneCountryIsoCode = this.locale.country_iso_code || "gb";
        this.currency = this.locale.currency || "USD";
        this.timeZone = this.locale.timezone || "UTC";
      } else {
        this.phoneCountryIsoCode = "gb";
        this.currency = "USD";
        this.timeZone = "UTC";
      }
    },
    updated() {
      const phoneCountryIsoCode =
        this.$refs.phoneInput && this.$refs.phoneInput.country
          ? this.$refs.phoneInput.country.iso2UpperCase
          : this.phoneCountryIsoCode;

      this.$emit("update", {
        name: this.name,
        surname: this.surname,
        companyName: this.companyName,
        phone: this.phone,
        phoneCountryIsoCode,
        timeZone: this.timeZone,
        currency: this.currency,
      });
    },
  },
};
</script>
