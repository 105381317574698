<template>
  <v-container fluid>
    <template v-for="(employee, index) in employees">
      <v-row :key="index">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="employee.name"
            dense
            :label="$trans('name')"
            :rules="[rules.required, rules.maxChars(50)]"
            validate-on-blur
            outlined
            hide-details="auto"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="employee.surname"
            dense
            :label="$trans('surname')"
            :rules="[rules.required, rules.maxChars(50)]"
            validate-on-blur
            outlined
            hide-details="auto"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="employee.email"
            dense
            :disabled="employee.root"
            :label="$trans('email')"
            :rules="[
              rules.email,
              rules.maxChars(60),
              rules.uniqueStringValue(
                employee.email,
                getAllFilteredEmail(employee.tmpId),
                $trans('email_must_be_unique')
              ),
            ]"
            validate-on-blur
            outlined
            hide-details="auto"
            :hint="employee.root ? $trans('root_email_info') : null"
            :persistent-hint="employee.root"
          />
        </v-col>
        <v-col cols="12" md="6">
          <phone-input
            v-model="employee.phone"
            :default-country-code="defaultCountryCode"
            dense
          />
        </v-col>
        <v-col cols="10" class="d-flex align-center">
          <v-checkbox
            v-model="employee.isPublic"
            :label="$trans('employee_public_checkbox')"
            @change="updated"
          >
            <template #label>
              <v-tooltip bottom max-width="400">
                <template #activator="{ on }">
                  <div v-on="on">
                    <span>{{ $trans("employee_public_checkbox") }}</span>
                  </div>
                </template>
                {{ $trans("signup_onboarding_employee_is_public_info") }}
              </v-tooltip>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="2" class="d-flex align-center justify-end">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                v-if="!employee.root"
                color="light"
                icon
                @click.native="remove(index)"
                v-on="on"
              >
                <v-icon>$trash-default</v-icon>
              </v-btn>
            </template>
            {{ $trans("delete") }}
          </v-tooltip>
        </v-col>
        <v-col cols="12">
          <v-divider class="mb-4" />
        </v-col>
      </v-row>
    </template>
    <v-row v-if="employees && employees.length < 5">
      <v-col>
        <v-btn color="blue" dark outlined @click.native="add">
          {{ $trans("add_employee") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  email,
  maxChars,
  minChars,
  required,
  uniqueStringValue,
} from "@/lib/calendesk-js-library/forms/validators";
import PhoneInput from "@/components/Forms/PhoneInput/PhoneInput";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "CSignupOnboardingEmployees",
  components: { PhoneInput },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    defaultCountryCode: {
      type: String,
      default: () => "gb",
    },
  },
  data() {
    return {
      employees: this.value,
      rules: {
        required,
        email,
        minChars,
        maxChars,
        uniqueStringValue,
      },
    };
  },
  watch: {
    value(val) {
      this.employees = val;
    },
  },
  created() {
    this.$watch("employees", this.updated);
  },
  methods: {
    getAllFilteredEmail(employeeTmpId) {
      if (this.employees) {
        return this.employees
          .filter(
            (employee) =>
              employee.tmpId !== employeeTmpId &&
              employee.email &&
              employee.email !== "" &&
              employee.email !== null
          )
          .map((employee) => employee.email);
      }

      return [];
    },
    add() {
      pushEvent("onboardingStepEmployeesAdd");

      this.employees.push({
        isPublic: true,
        tmpId: this.employees.length + 1,
      });

      this.updated();
    },
    remove(employeeIndex) {
      pushEvent("onboardingStepEmployeesRemove");

      const employees = this.$helpers.cloneObject(this.employees);
      this.employees = employees.filter(
        (item, index) => index !== employeeIndex
      );

      this.updated();
    },
    updated() {
      this.$emit("change", this.employees);
    },
  },
};
</script>
