var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.parsedEvent.paymentTransaction ||
    (_vm.parsedEvent.simpleStoreProductPaymentTransactions &&
      _vm.parsedEvent.simpleStoreProductPaymentTransactions.length > 0)
  )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('div',{staticClass:"font-weight-medium mb-2"},[_vm._v(_vm._s(_vm.$trans("payment_2"))+":")]),_c('div',[(_vm.parsedEvent.paymentTransaction)?_c('div',[_vm._v(" "+_vm._s(_vm._f("dateTime")(_vm.parsedEvent.paymentTransaction.paid_at,_vm.$helpers.hourDayMonthAndYearDate))+" - "+_vm._s(_vm._f("money")(_vm.parsedEvent.paymentTransaction.price))+" ("+_vm._s(_vm.$trans(_vm.parsedEvent.paymentTransaction.provider_tag))),(_vm.parsedEvent.paymentTransaction.transaction_id)?_c('span',[_vm._v(", "+_vm._s(_vm.parsedEvent.paymentTransaction.transaction_id))]):_vm._e(),(_vm.parsedEvent.paymentTransaction.promo_code)?_c('span',[_vm._v(", "+_vm._s(_vm.parsedEvent.paymentTransaction.promo_code))]):_vm._e(),_vm._v(") "),(_vm.parsedEvent.paymentTransaction.invoice_number)?[_vm._v(" "+_vm._s(_vm.parsedEvent.paymentTransaction.invoice_number)+" ")]:_vm._e()],2):_vm._e(),(
            _vm.parsedEvent.simpleStoreProductPaymentTransactions &&
            _vm.parsedEvent.simpleStoreProductPaymentTransactions.length > 0
          )?_c('div',{class:{
            'mt-4': _vm.parsedEvent.paymentTransaction,
          }},_vm._l((_vm.parsedEvent.simpleStoreProductPaymentTransactions),function(transaction,index){return _c('div',{key:index},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$basket")]),_vm._v(" "+_vm._s(transaction.product.name)+", "+_vm._s(_vm._f("dateTime")(transaction.paid_at,_vm.$helpers.hourDayMonthAndYearDate))+" - "+_vm._s(_vm._f("money")(transaction.price))+" ("+_vm._s(_vm.$trans(transaction.provider_tag))),(transaction.transaction_id)?_c('span',[_vm._v(", "+_vm._s(transaction.transaction_id))]):_vm._e(),(transaction.promo_code)?_c('span',[_vm._v(", "+_vm._s(transaction.promo_code))]):_vm._e(),_vm._v(") "),(transaction.invoice_number)?[_vm._v(" "+_vm._s(transaction.invoice_number)+" ")]:_vm._e()],2)}),0):_vm._e()])])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }