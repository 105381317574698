import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  allBookingReminders: [],
  refreshStatus: 0,
});

const getters = {
  getAllBookingReminders: (state) => state.allBookingReminders,
  getRefreshStatus: (state) => state.refreshStatus,
};

const state = getDefaultState();

const actions = {
  fetchAllBookingReminders({ commit }, data) {
    if (!data) {
      data = {
        limit: 999999,
        oder_by: "id",
        ascending: 1,
      };
    }

    return new Promise((resolve, reject) => {
      api
        .getBookingReminders(data)
        .then(({ data }) => {
          commit("SET_CURRENT_ALL_BOOKING_REMINDERS", data.data);
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  create(context, data) {
    return new Promise((resolve, reject) => {
      api
        .createBookingReminder(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  delete(context, reminderId) {
    return new Promise((resolve, reject) => {
      api
        .deleteBookingReminder(reminderId)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  refreshBookingReminders({ commit }) {
    commit("SET_REFRESH_STATUS", Math.floor(Math.random() * 10000000));
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_CURRENT_ALL_BOOKING_REMINDERS(state, response) {
    state.allBookingReminders = response;
  },
  SET_REFRESH_STATUS(state, value) {
    state.refreshStatus = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
