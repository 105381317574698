<template>
  <v-autocomplete
    v-model="timeZone"
    outlined
    :items="items"
    :label="label"
    :disabled="disabled"
    :dense="dense"
    :rules="rules"
    :placeholder="$trans('autocomplete_hint')"
    :hide-details="hideDetails"
    :prepend-icon="showIcon ? '$clock' : null"
    @input="inputEventHandle"
  >
    <template #no-data>
      <div class="v-subheader theme--light">
        {{ $trans("nothing_found_here") }}
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import timeZones from "@/lib/calendesk-js-library/forms/timeZones";

export default {
  name: "CTimeZoneAutocomplete",
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "Time zone",
    },
    hideDetails: {
      type: String,
      default: undefined,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: timeZones,
      timeZone: this.value,
    };
  },
  watch: {
    value() {
      this.timeZone = this.value;
    },
  },
  methods: {
    inputEventHandle(timeZone) {
      this.$emit("input", timeZone);
    },
  },
};
</script>
