import { mapActions } from "vuex";

export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      setManageEventTitle: "booking/setManageEventTitle",
      setManageEventButtonTitle: "booking/setManageEventButtonTitle",
      setManageEventButtonState: "booking/setManageEventButtonState",
    }),
    reloadTitles(isBookingType) {
      const isGroupBooking =
        this.event.type === this.$helpers.eventTypes.groupBooking;
      const isGroupBookingInstance =
        this.event.type === this.$helpers.eventTypes.booking &&
        !!this.event.multiSlotGroupId;

      let title = this.generateTitle(isBookingType);
      let buttonTitle = this.generateButtonTitle(isBookingType);

      if (this.isUpdating) {
        title = this.generateTitleForUpdateMode(
          isBookingType,
          isGroupBooking,
          isGroupBookingInstance
        );
        buttonTitle = this.$trans("save");
      }

      this.setManageEventTitle(title);
      this.setManageEventButtonTitle(buttonTitle);
    },
    generateTitle(isBooking) {
      return isBooking
        ? this.$trans("new_booking_title")
        : this.$trans("new_unavailable_booking_slot_title");
    },
    generateTitleForUpdateMode(
      isBooking,
      isGroupBooking,
      isGroupBookingInstance
    ) {
      let title = isBooking
        ? isGroupBooking
          ? this.$trans("edit_group_booking_title")
          : isGroupBookingInstance
          ? this.$trans("edit_single_instance_group_booking_title")
          : this.$trans("edit_booking_title")
        : this.$trans("edit_unavailable_booking_slot_title");

      const isGroupRecurring =
        isGroupBooking &&
        this.event.bookings &&
        this.event.bookings.length > 0 &&
        this.event.bookings[0].groupId;

      if (this.event.groupId || isGroupRecurring) {
        title += ` - ${this.$trans("recurrence_event").toLowerCase()}`;
      }

      if (this.event.id) {
        title += ` (${this.$trans("id")}: ${this.event.id})`;
      }

      return title;
    },
    generateButtonTitle(isBooking) {
      return isBooking
        ? this.$trans("add_booking")
        : this.$trans("add_unavailable_booking_slot");
    },
  },
};
