<template>
  <v-row :no-gutters="dense">
    <v-col
      cols="12"
      :class="{
        'my-0 py-0 cd-p-0': dense,
      }"
    >
      <div>
        <div class="font-weight-medium mb-2">
          {{ $trans("booking_form_custom_fields") }}:
        </div>
        <div>
          <div
            v-for="(field, index) in customFields"
            :key="index"
            :class="{
              'my-2': !dense,
            }"
          >
            <div
              v-if="field.description"
              class="font-weight-medium"
              v-html="sanitizeHtml(stripSurroundingPTags(field.description))"
            />
            <div v-else-if="field.label" class="font-weight-medium">
              {{ field.label }}<span v-if="field.required">*</span>
            </div>
            <div>
              {{ parseCustomFieldValue(field.value) }}
              <small v-if="field.equal"
                >({{ $trans("booking_form_custom_fields_equal_note") }}:
                {{ field.equal }})</small
              >
            </div>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { stripSurroundingPTags } from "@/lib/calendesk-js-library/filters/stripSurroundingPTags";
import sanitizeHtml from "@/lib/calendesk-js-library/filters/sanitizeHtml";

export default {
  name: "EventCustomFields",
  props: {
    customFields: {
      type: Array,
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    parseCustomFieldValue(value) {
      if (value === true) {
        return this.$trans("yes");
      }
      if (value === false) {
        return this.$trans("no");
      }

      return value;
    },
    stripSurroundingPTags,
    sanitizeHtml,
  },
};
</script>
