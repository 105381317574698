import { render, staticRenderFns } from "./CSignupOnboardingIntegrations.vue?vue&type=template&id=b8206f14&scoped=true"
var script = {}
import style0 from "./CSignupOnboardingIntegrations.vue?vue&type=style&index=0&id=b8206f14&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8206f14",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard,VCardSubtitle,VCardTitle})
