<template>
  <v-expansion-panel-header class="font-weight-bold c-text-18 my-2">
    <template #actions>
      <v-icon class="expand-icon mr-3" x-large> $chevron-down</v-icon>
    </template>
    <span class="expand-header">{{ title }}</span>
  </v-expansion-panel-header>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
  },
};
</script>
