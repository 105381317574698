<template>
  <v-tooltip color="c_dark" bottom max-width="400" z-index="999999">
    <template #activator="{ on, attrs }">
      <div
        class="d-none d-md-flex align-center wrapper"
        v-bind="attrs"
        v-on="on"
      >
        <template v-if="feature[planType + '_icon']">
          <v-img
            v-if="feature.icon_as_image"
            :src="feature[planType + '_icon']"
            max-width="24"
            max-height="24"
            class="mr-2"
          />
          <v-icon
            v-else
            size="24"
            class="mr-2"
            :color="feature[planType + '_icon_color']"
          >
            {{ feature[planType + "_icon"] }}
          </v-icon>
        </template>
        <span
          class="c-text-16"
          :class="{
            'font-weight-bold': feature['bold_' + planType],
            [feature[planType + '_text_color'] + '--text']: true,
          }"
        >
          {{ feature[planType] }}
        </span>
      </div>
      <div
        class="d-md-none align-center text-center wrapper"
        v-bind="attrs"
        v-on="on"
      >
        <div v-if="feature[planType + '_icon']" class="full-width">
          <v-img
            v-if="feature.icon_as_image"
            :src="feature[planType + '_icon']"
            max-width="24"
            max-height="24"
            class="mx-auto mb-2"
          />
          <v-icon v-else size="24" :color="feature[planType + '_icon_color']">
            {{ feature[planType + "_icon"] }}
          </v-icon>
        </div>
        <span
          class="c-text-13"
          :class="{
            'font-weight-bold': feature['bold_' + planType],
            [feature[planType + '_text_color'] + '--text']: true,
          }"
        >
          {{ feature[planType] }}
        </span>
      </div>
    </template>
    <span>{{ feature["tooltip_" + planType] }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    feature: {
      type: Object,
      default: () => {},
    },
    planType: {
      type: String,
      default: "standard",
      validator(value) {
        return ["standard", "pro", "enterprise"].includes(value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
}

.full-width {
  width: 100%;
}
</style>
