<template>
  <v-row justify="center">
    <v-col cols="12">
      <div>
        <div class="font-weight-medium mb-2">{{ $trans("service") }}:</div>
        <event-service
          :service="service"
          :service-type="getServiceType"
          :products="products"
          :dense="dense"
          :hide-details="hideDetails"
          clickable
          justify-left
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import EventService from "@/components/Event/EventService.vue";

export default {
  name: "EventServiceRow",
  components: { EventService },
  props: {
    service: {
      type: Object,
      required: true,
    },
    serviceType: {
      type: Object,
      required: false,
      default: null,
    },
    products: {
      type: Array,
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getServiceType() {
      return this.serviceType || this.service.selectedType || null;
    },
  },
  methods: {
    getProductName(product) {
      return (
        product.name + " (" + this.$options.filters.money(product.price) + ")"
      );
    },
  },
};
</script>
