<template>
  <v-row>
    <v-col cols="12">
      <div class="d-flex align-center flex-wrap mb-2">
        <v-icon
          v-if="getIconForType(parsedEvent.location.type)"
          class="mr-2"
          color="primary"
        >
          {{ getIconForType(parsedEvent.location.type) }}
        </v-icon>
        <img
          v-else
          height="15"
          class="mr-2 ml-1"
          :src="getImageForType(parsedEvent.location.type)"
          :alt="$trans('service_location_' + parsedEvent.location.type)"
        />
        <div class="font-weight-medium">
          {{ $trans("service_location") }}:
          {{ $trans("service_location_" + parsedEvent.location.type) }}
        </div>
      </div>
      <div
        v-if="
          parsedEvent.location.type ===
            $helpers.serviceLocationTypes.GOOGLE_MEET ||
          parsedEvent.location.type === $helpers.serviceLocationTypes.ZOOM ||
          parsedEvent.location.type === $helpers.serviceLocationTypes.TEAMS ||
          parsedEvent.location.type === $helpers.serviceLocationTypes.SKYPE ||
          parsedEvent.location.type === $helpers.serviceLocationTypes.WHATSAPP
        "
        class="d-flex align-center"
      >
        <div class="text-break">
          <a
            :href="getLink"
            target="_blank"
            :title="parsedEvent.location.type"
            >{{ getLink }}</a
          >
        </div>
        <v-btn
          v-if="getLink"
          class="mr-4"
          color="blue"
          icon
          @click.stop="$helpers.copyAndNotify(getLink)"
        >
          <v-icon>$copy</v-icon>
        </v-btn>
      </div>
      <div v-else-if="parsedEvent.location.data">
        {{ parsedEvent.location.data }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import serviceLocationActions from "@/calendesk/mixins/serviceLocationActions";

export default {
  name: "EventLocation",
  mixins: [serviceLocationActions],
  props: {
    parsedEvent: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getLink() {
      switch (this.parsedEvent.location.type) {
        case this.$helpers.serviceLocationTypes.GOOGLE_MEET:
          return this.parsedEvent.googleMeetUrl;
        case this.$helpers.serviceLocationTypes.ZOOM:
          return this.parsedEvent.zoomJoinUrl;
        case this.$helpers.serviceLocationTypes.TEAMS:
          return this.parsedEvent.teamsUrl;
        case this.$helpers.serviceLocationTypes.SKYPE:
          return this.parsedEvent.skypeUrl;
        case this.$helpers.serviceLocationTypes.WHATSAPP:
          if (!this.parsedEvent.multiSlotGroupId) {
            return this.parsedEvent.customerWhatsAppUrl;
          } else {
            return this.parsedEvent.employeeWhatsAppUrl;
          }
        default:
          return "";
      }
    },
  },
};
</script>
