<template>
  <div>
    <c-tabs
      :items="items"
      centered
      :flat="flat"
      :initial-tab="1"
      show-arrows
      @change="onChange"
    >
      <template #top>
        <v-card elevation="0" class="mb-8 text-center">
          <v-card-title class="justify-center break-word-normal">
            <span v-if="planExpired" class="c-text-36 font-weight-bold mt-4">{{
              $trans("plan_expired_title")
            }}</span>
            <span v-else class="c-text-36 font-weight-bold mt-4">{{
              $trans("plans_headline")
            }}</span>
          </v-card-title>
          <v-card-subtitle class="text-center mt-4">
            <span class="text-body-1 dark--text">{{
              $trans("plans_headline_2")
            }}</span>
          </v-card-subtitle>
        </v-card>
      </template>
    </c-tabs>
    <v-divider />
    <v-container v-if="currentPlan" class="mt-4">
      <template v-if="$vuetify.breakpoint.smAndDown">
        <v-row no-gutters class="d-none d-sm-flex mb-0 pb-0">
          <v-col cols="12" sm="4" />
          <v-col cols="12" sm="4" class="c_dark cd-tx-radius text-right pa-2">
            <span
              class="text-uppercase font-weight-bold c-text-12 c_orange--text"
              >{{ $trans("plans_featured") }}</span
            >
            <v-icon color="c_orange"> $fire</v-icon>
          </v-col>
          <v-col cols="12" sm="4" />
        </v-row>
        <v-row justify="center" no-gutters>
          <v-col
            cols="12"
            sm="4"
            class="justify-center text-center cd-outline pa-4 pa-sm-2"
          >
            <div>
              <div class="mb-2">
                <c-plan-header-name :header="headers[0]" />
              </div>
              <div class="c-text-16 font-weight-regular c_gray_3--text">
                {{ headers[0].description }}
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            class="justify-center text-center cd-y-outline c_dark pa-4 pa-sm-2"
          >
            <div>
              <div class="text-right mb-2 d-sm-none">
                <span
                  class="text-uppercase font-weight-bold c-text-12 c_orange--text"
                  >{{ $trans("plans_featured") }}</span
                >
                <v-icon color="c_orange"> $fire</v-icon>
              </div>
              <div class="mb-2">
                <c-plan-header-name :header="headers[1]" featured />
              </div>
              <div class="c-text-16 font-weight-regular c_gray_3--text">
                {{ headers[1].description }}
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            class="justify-center text-center cd-outline pa-4 pa-sm-2"
          >
            <div>
              <div class="mb-2">
                <c-plan-header-name is-enterprise :header="headers[2]" />
              </div>
              <div class="c-text-16 font-weight-regular c_gray_3--text">
                {{ headers[2].description }}
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-8 mb-4 sticky">
          <v-col cols="4" class="white text-center">
            <div class="cd-outline pa-4 font-weight-bold c-text-16 fill-height">
              {{ headers[0].name }}
            </div>
          </v-col>
          <v-col cols="4" class="c_dark text-center">
            <div
              class="font-weight-bold white--text c-text-16 cd-r-outline cd-y-outline pa-4 fill-height"
            >
              {{ headers[1].name }}
            </div>
          </v-col>
          <v-col cols="4" class="white text-center">
            <div
              class="cd-l-outline cd-y-outline pa-4 font-weight-bold c-text-16 fill-height"
            >
              {{ headers[2].name }}
            </div>
          </v-col>
        </v-row>
        <v-row v-for="feature in features" :key="feature.name" no-gutters>
          <v-col
            cols="12"
            class="c_gray_5 font-weight-bold c-text-16 text-uppercase pa-4 c_dark_2--text"
          >
            {{ feature.name }}
          </v-col>
          <v-col
            cols="4"
            class="d-flex cd-b-outline cd-l-outline pa-4 align-center"
          >
            <c-plan-feature-tooltip :feature="feature" plan-type="standard" />
          </v-col>
          <v-col
            cols="4"
            class="d-flex cd-b-outline cd-l-outline pa-4 align-center"
          >
            <c-plan-feature-tooltip :feature="feature" plan-type="pro" />
          </v-col>
          <v-col
            cols="4"
            class="d-flex cd-b-outline cd-x-outline pa-4 align-center"
          >
            <c-plan-feature-tooltip :feature="feature" plan-type="enterprise" />
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-8 sticky">
          <v-col cols="4" class="white text-center">
            <div class="cd-outline pa-4 font-weight-bold c-text-16 fill-height">
              {{ headers[0].name }}
            </div>
          </v-col>
          <v-col cols="4" class="c_dark text-center">
            <div
              class="font-weight-bold white--text c-text-16 cd-r-outline cd-y-outline pa-4 fill-height"
            >
              {{ headers[1].name }}
            </div>
          </v-col>
          <v-col cols="4" class="white text-center">
            <div
              class="cd-l-outline cd-y-outline pa-4 font-weight-bold c-text-16 fill-height"
            >
              {{ headers[2].name }}
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            cols="4"
            class="d-flex cd-b-outline cd-l-outline pa-4 align-center justify-center"
          >
            <v-btn
              class="pa-5 text-none tag-go-to-plans c_white_1--text"
              color="c_dark_3"
              rounded
              small
              elevation="0"
              @click="selectPlan(plans.CD_BASE_STANDARD)"
            >
              <span class="font-sans-bold">
                {{ $trans("select") }}
              </span>
            </v-btn>
          </v-col>
          <v-col
            cols="4"
            class="d-flex cd-b-outline cd-l-outline pa-4 align-center justify-center"
          >
            <v-btn
              class="pa-5 text-none tag-go-to-plans c_white_1--text"
              color="c_dark_3"
              rounded
              small
              elevation="0"
              @click="selectPlan(plans.CD_BASE_PRO)"
            >
              <span class="font-sans-bold">
                {{ $trans("select") }}
              </span>
            </v-btn>
          </v-col>
          <v-col
            cols="4"
            class="d-flex cd-b-outline cd-x-outline pa-4 align-center justify-center"
          >
            <v-btn
              class="pa-5 text-none tag-go-to-plans c_white_1--text"
              color="c_dark_3"
              rounded
              small
              elevation="0"
              @click="goToContactForm"
            >
              <span class="font-sans-bold">
                {{ $trans("contact_sales") }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-row no-gutters>
          <v-col cols="3" />
          <v-col cols="3" />
          <v-col cols="3" class="c_dark cd-tx-radius text-right pa-2">
            <span
              class="text-uppercase font-weight-bold c-text-12 c_orange--text"
              >{{ $trans("plans_featured") }}</span
            >
            <v-icon color="c_orange"> $fire</v-icon>
          </v-col>
          <v-col cols="3" />
        </v-row>
        <v-row justify="center" no-gutters class="sticky">
          <v-col cols="3" class="white" />
          <v-col cols="3" class="text-center white">
            <c-plan-header-name
              :header="headers[0]"
              class="sticky-header cd-t-outline cd-tl-radius cd-l-outline pt-4"
            />
          </v-col>
          <v-col cols="3" class="text-center c_dark sticky-header">
            <c-plan-header-name
              :header="headers[1]"
              featured
              class="sticky-header featured pt-4 featured"
            />
          </v-col>
          <v-col cols="3" class="text-center white sticky-header">
            <c-plan-header-name
              is-enterprise
              :header="headers[2]"
              class="sticky-header cd-t-outline cd-tr-radius cd-r-outline pt-4"
            />
          </v-col>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-col cols="3" />
          <v-col cols="3" class="white">
            <c-plan-description :header="headers[0]" class="cd-l-outline" />
          </v-col>
          <v-col cols="3" class="c_dark">
            <c-plan-description featured :header="headers[1]" />
          </v-col>
          <v-col cols="3" class="white">
            <c-plan-description :header="headers[2]" class="cd-r-outline" />
          </v-col>
        </v-row>
        <v-row no-gutters class="sticky-outline">
          <v-col cols="3" />
          <v-col cols="3" class="cd-b-outline" />
          <v-col cols="3" class="cd-b-outline" />
          <v-col cols="3" class="cd-b-outline" />
        </v-row>
        <v-row v-for="feature in features" :key="feature.name" no-gutters>
          <v-col
            cols="3"
            class="d-flex justify-end pa-4 pr-8 font-weight-bold c-text-16 text-uppercase text-right align-center"
          >
            {{ feature.name }}
          </v-col>
          <v-col
            cols="3"
            class="d-flex cd-b-outline cd-l-outline pa-4 align-center"
          >
            <c-plan-feature-tooltip :feature="feature" plan-type="standard" />
          </v-col>
          <v-col
            cols="3"
            class="d-flex cd-b-outline cd-l-outline pa-4 align-center"
          >
            <c-plan-feature-tooltip :feature="feature" plan-type="pro" />
          </v-col>
          <v-col
            cols="3"
            class="d-flex cd-b-outline cd-x-outline pa-4 align-center"
          >
            <c-plan-feature-tooltip :feature="feature" plan-type="enterprise" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            cols="3"
            class="d-flex justify-end pa-4 pr-8 font-weight-bold c-text-16 text-uppercase text-right align-center"
          />
          <v-col
            cols="3"
            class="d-flex cd-b-outline cd-l-outline pa-4 align-center justify-center"
          >
            <v-btn
              class="pa-5 text-none tag-go-to-plans c_white_1--text"
              color="c_dark_3"
              rounded
              small
              elevation="0"
              @click="selectPlan(plans.CD_BASE_STANDARD)"
            >
              <span class="font-sans-bold">
                {{ $trans("select") }}
              </span>
            </v-btn>
          </v-col>
          <v-col
            cols="3"
            class="d-flex cd-b-outline cd-l-outline pa-4 align-center justify-center"
          >
            <v-btn
              class="pa-5 text-none tag-go-to-plans c_white_1--text"
              color="c_dark_3"
              rounded
              small
              elevation="0"
              @click="selectPlan(plans.CD_BASE_PRO)"
            >
              <span class="font-sans-bold">
                {{ $trans("select") }}
              </span>
            </v-btn>
          </v-col>
          <v-col
            cols="3"
            class="d-flex cd-b-outline cd-x-outline pa-4 align-center justify-center"
          >
            <v-btn
              class="pa-5 text-none tag-go-to-plans c_white_1--text"
              color="c_dark_3"
              rounded
              small
              elevation="0"
              @click="selectPlan(plans.CD_BASE_ENTERPRISE)"
            >
              <span class="font-sans-bold">
                {{ $trans("contact_sales") }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-container>
    <v-container class="mt-8">
      <v-row justify="center">
        <v-col cols="12" md="8">
          <c-plan-f-a-q />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CTabs from "@/components/CTabs/CTabs.vue";
import CPlanDescription from "@/views/dashboard/pages/Plans/components/CPlanDescription.vue";
import CPlanHeaderName from "@/views/dashboard/pages/Plans/components/CPlanHeaderName.vue";
import CPlanFeatureTooltip from "@/views/dashboard/pages/Plans/components/CPlanFeatureTooltip.vue";
import planFeatures from "@/calendesk/mixins/planFeatures";
import CPlanFAQ from "@/views/dashboard/pages/Plans/components/CPlanFAQ.vue";

export default {
  name: "PlanTabs",
  components: {
    CPlanFAQ,
    CTabs,
    CPlanDescription,
    CPlanHeaderName,
    CPlanFeatureTooltip,
  },
  mixins: [planFeatures],
  props: {
    flat: {
      type: Boolean,
      default: false,
    },
    planExpired: {
      type: Boolean,
      default: false,
    },
    currentPlan: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      plans: this.$helpers.plans,
      selectedTab: 1,
    };
  },
  computed: {
    headers() {
      return [
        {
          period: this.selectedTab === 0 ? "month" : "year",
          name: this.$trans("cd_base_standard"),
          description: this.$trans("plans_description_standard"),
          value: "cd_base_standard",
          price: {
            pln: this.selectedTab === 0 ? "19700" : "15700", // Monthly: 197 zł, Yearly (equivalent monthly): 157 zł
            usd: this.selectedTab === 0 ? "9700" : "7900", // Monthly: 97 USD, Yearly (equivalent monthly): 79 USD
            eur: this.selectedTab === 0 ? "8900" : "6900", // Monthly: 89 EUR, Yearly (equivalent monthly): 69 EUR
            gbp: this.selectedTab === 0 ? "7700" : "6400", // Monthly: 77 GBP, Yearly (equivalent monthly): 64 GBP
          },
        },
        {
          period: this.selectedTab === 0 ? "month" : "year",
          name: this.$trans("cd_base_pro"),
          description: this.$trans("plans_description_pro"),
          value: "cd_base_pro",
          price: {
            pln: this.selectedTab === 0 ? "34700" : "28700", // Monthly: 347 zł, Yearly (equivalent monthly): 287 zł
            usd: this.selectedTab === 0 ? "14700" : "11700", // Monthly: 147 USD, Yearly (equivalent monthly): 117 USD
            eur: this.selectedTab === 0 ? "12900" : "10700", // Monthly: 129 EUR, Yearly (equivalent monthly): 107 EUR
            gbp: this.selectedTab === 0 ? "11700" : "9700", // Monthly: 117 GBP, Yearly (equivalent monthly): 97 GBP
          },
        },
        {
          period: this.selectedTab === 0 ? "month" : "year",
          name: this.$trans("cd_base_enterprise"),
          description: this.$trans("plans_description_enterprise"),
          value: "cd_base_enterprise",
        },
      ];
    },
    items() {
      return [
        {
          tab: "monthly_payment",
        },
        {
          tab: "annual_payment",
          chip: "-20%",
          chip_color: "c_orange",
          chip_icon: "$rocket",
        },
      ];
    },
  },
  methods: {
    onChange(tabId) {
      this.selectedTab = tabId;
    },
    selectPlan(slug) {
      const period = this.selectedTab === 0 ? "month" : "year";
      this.$root.$emit("planSelectedEvent", {
        slug,
        period_type: period,
      });
    },
    goToContactForm() {
      this.$root.$emit("openContactForm");
    },
  },
};
</script>
<style lang="scss" scoped>
.sticky {
  position: sticky;
  top: 0;
  z-index: 3;
}

.sticky-header {
  min-height: 140px;
}

.sticky-outline {
  position: sticky;
  top: 200px;
}

@media only screen and (min-width: 1264px) {
  .sticky-header {
    min-height: 170px;
  }
}

.featured {
  background-color: var(--v-c_dark--base);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
</style>
