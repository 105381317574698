<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-img :src="getImageSrc" height="210" contain />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="card-title">
        <h3 class="text-h6">
          {{
            $trans("plugins_" + plugin.service.slug + "_" + planSlug + "_name")
          }}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="card-description">
        <span class="text-body-2">{{
          $trans(
            "plugins_" + plugin.service.slug + "_" + planSlug + "_description"
          )
        }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <span class="text-h4">{{
          plugin.price.amount
            | money(
              adminConfiguration ? adminConfiguration.tenant_currency : null
            )
        }}</span
        ><span class="text-caption">/ {{ periodPaymentLabel }}</span>
      </v-col>
      <v-col cols="4">
        <v-btn
          :color="isSelected ? 'red' : 'blue'"
          :outlined="isSelected"
          rounded
          elevation="0"
          class="white--text"
          @click="onChange"
        >
          <template v-if="isSelected">
            <span v-if="oneTimeBought">{{ $trans("purchase_remove") }}</span>
            <span v-else>{{ $trans("disable") }}</span>
          </template>
          <span v-else-if="oneTimeBought">{{ $trans("buy") }}</span>
          <span v-else>{{ $trans("enable") }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="mt-2" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CPluginCard",
  props: {
    plugin: {
      type: Object,
      default: () => {},
    },
    period: {
      type: String,
      default: null,
    },
    isUsing: {
      type: Boolean,
      default: false,
    },
    planSlug: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isSelected: false,
      images: {
        pl: {
          "sms-notifications_cd_base_lite":
            "sms-notifications_cd_base_lite.jpg",
          "sms-notifications_cd_base_standard":
            "sms-notifications_cd_base_standard.jpg",
          "sms-notifications_cd_base_pro": "sms-notifications_cd_base_pro.jpg",
          "mobile-app-staff_cd_base_lite":
            "mobile-app-staff_cd_base_lite_pl.jpg",
          booking_cd_base_lite: "booking_cd_base_lite_pl.jpg",
          "google-calendar_cd_base_standard":
            "google-calendar_cd_base_standard.jpg",
          mobile_cd_base_lite: "mobile_cd_base_lite.jpg",
          "mobile-app-customers-pro_cd_base_standard":
            "mobile-app-customers-pro_cd_base_standard.jpg",
          "mobile-app-customers-pro_cd_base_pro":
            "mobile-app-customers-pro_cd_base_standard.jpg",
          management_cd_base_lite: "management_cd_base_lite.jpg",
          support_cd_base_standard: "support_cd_base_standard.jpg",
          "support-expert_cd_base_pro": "support-expert_cd_base_pro.jpg",
          "easy-going_cd_base_standard": "easy-going_cd_base_standard.jpg",
          "alphanumeric-sms-sender_cd_base_standard":
            "alphanumeric-sms-sender.jpg",
          "alphanumeric-sms-sender_cd_base_pro": "alphanumeric-sms-sender.jpg",
        },
        en: {
          "sms-notifications_cd_base_lite":
            "sms-notifications_cd_base_lite.jpg",
          "sms-notifications_cd_base_standard":
            "sms-notifications_cd_base_standard.jpg",
          "sms-notifications_cd_base_pro": "sms-notifications_cd_base_pro.jpg",
          "mobile-app-staff_cd_base_lite":
            "mobile-app-staff_cd_base_lite_en.jpg",
          booking_cd_base_lite: "booking_cd_base_lite_en.jpg",
          "google-calendar_cd_base_standard":
            "google-calendar_cd_base_standard.jpg",
          mobile_cd_base_lite: "mobile_cd_base_lite.jpg",
          "mobile-app-customers-pro_cd_base_standard":
            "mobile-app-customers-pro_cd_base_standard.jpg",
          "mobile-app-customers-pro_cd_base_pro":
            "mobile-app-customers-pro_cd_base_standard.jpg",
          management_cd_base_lite: "management_cd_base_lite.jpg",
          support_cd_base_standard: "support_cd_base_standard.jpg",
          "support-expert_cd_base_pro": "support-expert_cd_base_pro.jpg",
          "easy-going_cd_base_standard": "easy-going_cd_base_standard.jpg",
          "alphanumeric-sms-sender_cd_base_standard":
            "alphanumeric-sms-sender.jpg",
          "alphanumeric-sms-sender_cd_base_pro": "alphanumeric-sms-sender.jpg",
        },
        es: {
          "sms-notifications_cd_base_lite":
            "sms-notifications_cd_base_lite.jpg",
          "sms-notifications_cd_base_standard":
            "sms-notifications_cd_base_standard.jpg",
          "sms-notifications_cd_base_pro": "sms-notifications_cd_base_pro.jpg",
          "mobile-app-staff_cd_base_lite":
            "mobile-app-staff_cd_base_lite_es.jpg",
          booking_cd_base_lite: "booking_cd_base_lite_es.jpg",
          "google-calendar_cd_base_standard":
            "google-calendar_cd_base_standard.jpg",
          mobile_cd_base_lite: "mobile_cd_base_lite.jpg",
          "mobile-app-customers-pro_cd_base_standard":
            "mobile-app-customers-pro_cd_base_standard.jpg",
          "mobile-app-customers-pro_cd_base_pro":
            "mobile-app-customers-pro_cd_base_standard.jpg",
          management_cd_base_lite: "management_cd_base_lite.jpg",
          support_cd_base_standard: "support_cd_base_standard.jpg",
          "support-expert_cd_base_pro": "support-expert_cd_base_pro.jpg",
          "easy-going_cd_base_standard": "easy-going_cd_base_standard.jpg",
          "alphanumeric-sms-sender_cd_base_standard":
            "alphanumeric-sms-sender.jpg",
          "alphanumeric-sms-sender_cd_base_pro": "alphanumeric-sms-sender.jpg",
        },
        de: {
          "sms-notifications_cd_base_lite":
            "sms-notifications_cd_base_lite.jpg",
          "sms-notifications_cd_base_standard":
            "sms-notifications_cd_base_standard.jpg",
          "sms-notifications_cd_base_pro": "sms-notifications_cd_base_pro.jpg",
          "mobile-app-staff_cd_base_lite":
            "mobile-app-staff_cd_base_lite_de.jpg",
          booking_cd_base_lite: "booking_cd_base_lite_de.jpg",
          "google-calendar_cd_base_standard":
            "google-calendar_cd_base_standard.jpg",
          mobile_cd_base_lite: "mobile_cd_base_lite.jpg",
          "mobile-app-customers-pro_cd_base_standard":
            "mobile-app-customers-pro_cd_base_standard.jpg",
          "mobile-app-customers-pro_cd_base_pro":
            "mobile-app-customers-pro_cd_base_standard.jpg",
          management_cd_base_lite: "management_cd_base_lite.jpg",
          support_cd_base_standard: "support_cd_base_standard.jpg",
          "support-expert_cd_base_pro": "support-expert_cd_base_pro.jpg",
          "easy-going_cd_base_standard": "easy-going_cd_base_standard.jpg",
          "alphanumeric-sms-sender_cd_base_standard":
            "alphanumeric-sms-sender.jpg",
          "alphanumeric-sms-sender_cd_base_pro": "alphanumeric-sms-sender.jpg",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      configuration: "setup/getConfiguration",
      adminConfiguration: "setup/getAdminConfiguration",
    }),
    oneTimeBought() {
      return this.plugin.type === this.$helpers.planPackagesTypes.EXTRA;
    },
    getImageSrc() {
      if (
        this.images[this.configuration.language] &&
        this.images[this.configuration.language][
          `${this.plugin.service.slug}_${this.planSlug}`
        ]
      ) {
        return require(`@/assets/plugins/${
          this.images[this.configuration.language][
            `${this.plugin.service.slug}_${this.planSlug}`
          ]
        }`);
      }

      return null;
    },
    periodPaymentLabel() {
      if (this.plugin.type === this.$helpers.planPackagesTypes.EXTRA) {
        return this.$trans("once_no_vat");
      }
      if (this.period === this.$helpers.periodTypes.MONTH) {
        return this.$trans("monthly_no_vat");
      }
      if (this.period === this.$helpers.periodTypes.YEAR) {
        return this.$trans("yearly_no_vat");
      }

      return null;
    },
  },
  created() {
    this.isSelected = this.isUsing;
  },
  methods: {
    onChange() {
      this.isSelected = !this.isSelected;
      this.$emit("change", { plugin: this.plugin, selected: this.isSelected });
    },
    selectPluginSilently() {
      this.isSelected = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 960px) {
  .card-description {
    min-height: 200px;
  }
}
</style>
