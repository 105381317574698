<template>
  <div class="text-center my-8">
    <img height="200" :src="require('@/assets/trial-info.png')" />
    <div v-if="getDomain" class="mt-8">
      <div class="font-weight-medium mt-4">
        {{ $trans("calendar_url_link") }}:
        <v-progress-circular
          v-if="isLoadingCalendarURL"
          indeterminate
          color="primary"
          size="24"
          class="mx-2"
        />
        <a v-else @click="openCalendar">{{ getDomain }}</a>
      </div>
      <small>*{{ $trans("signup_onboarding_domain_label") }}</small>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "CSignupOnboardingSummary",
  data() {
    return {
      isLoadingCalendarURL: true,
    };
  },
  computed: {
    ...mapGetters({
      webDrafts: "webDraft/drafts",
    }),
    getDomain() {
      if (
        this.webDrafts &&
        this.webDrafts.length > 0 &&
        this.webDrafts[0].domain
      ) {
        return `https://${this.webDrafts[0].domain.domain}`;
      }
      return null;
    },
  },
  mounted() {
    setTimeout(() => {
      this.isLoadingCalendarURL = false;
    }, 8000); // 8-second delay, the calendar is being created in the background.
  },
  methods: {
    openCalendar() {
      pushEvent("onboardingStepSummaryOpenCalendar");
      window.open(this.getDomain, "_blank");
    },
  },
};
</script>
