<template>
  <v-row justify="center">
    <v-col cols="12">
      <div>
        <div class="font-weight-medium mb-2">{{ $trans("employee") }}:</div>
        <div>
          <div v-if="parsedEvent.employee">
            <employee-card-details
              dense
              justify-left
              clickable
              :employee="parsedEvent.employee"
            />
            <div
              v-if="
                isBookingType &&
                adminConfiguration &&
                adminConfiguration.user_requested_employee_enabled &&
                parsedEvent.data &&
                parsedEvent.data.user_requested_employee
              "
            >
              <v-icon color="warning" class="mr-1">$info</v-icon>
              {{ $trans("user_requested_employee_title") }}
            </div>
          </div>
          <div v-else-if="isUnavailableBookingSlotType">
            {{ $trans("all_employees") }}
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import EmployeeCardDetails from "@/components/EmployeeCardDetails.vue";
import { mapGetters } from "vuex";
import eventTypes from "@/components/Event/mixins/eventTypes";

export default {
  name: "EventEmployee",
  components: {
    EmployeeCardDetails,
  },
  mixins: [eventTypes],
  props: {
    parsedEvent: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      adminConfiguration: "setup/getAdminConfiguration",
    }),
  },
};
</script>
