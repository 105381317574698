<template>
  <div class="text-center px-4 mb-4 fill-height">
    <v-row no-gutters>
      <v-col class="c-text-16 font-weight-regular c_gray_3--text">
        {{ header.description }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
