<template>
  <v-select
    v-model="selectedPrice"
    outlined
    :items="prices"
    :label="$trans('product_price_in_stripe')"
    :item-text="getStripePriceName"
    :rules="rules"
    return-object
    hide-details="auto"
    :loading="loading"
    :disabled="disabled"
    :no-data-text="$trans('nothing_here')"
    @change="$emit('input', selectedPrice ? selectedPrice.id : null)"
  >
    <template v-if="!hideAddButton" #append-outer>
      <slot />

      <v-tooltip bottom>
        <template #activator="{ on }">
          <div class="outlined-icon ml-3 mt-1" @click="handleAddNew" v-on="on">
            <v-icon>$plus</v-icon>
          </div>
        </template>
        {{ $trans("add") }}
      </v-tooltip>
    </template>
  </v-select>
</template>

<script>
import dialogTypes from "@/components/ConfirmDialogs/confirmDialogTypes";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import { getStripePriceName } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "StripeProductPriceField",
  mixins: [sharedActions],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    product: {
      type: Object,
      default: null,
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideAddButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedPrice: null,
      prices: [],
    };
  },
  watch: {
    value(val) {
      this.selectedPrice = this.prices.find((price) => price.id === this.value);
    },
    product: {
      handler(val) {
        if (val) {
          this.prices = this.product.prices;
        }
      },
      deep: true,
    },
  },
  created() {
    this.prices = this.product ? this.product.prices : [];

    if (this.value) {
      this.selectedPrice = this.prices.find((price) => price.id === this.value);
    }
  },
  methods: {
    getStripePriceName,
    handleAddNew() {
      this.openConfirmDialog({
        type: dialogTypes.ADD_STRIPE_PRODUCT_PRICE,
        data: {
          product: this.product,
        },
      });
    },
  },
};
</script>
