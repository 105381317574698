<template>
  <v-container
    ref="mainContainer"
    fluid
    :class="{
      'mb-12': !isInStore,
    }"
  >
    <v-container
      :class="{
        'mb-12': !isInStore,
      }"
    >
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <div class="c-text-36 font-weight-bold mt-8 mt-md-4">
            {{ $trans("order_plan_summary_title") }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-0">
          <order-plan-summary :disabled="isLoadingData" />
        </v-col>
      </v-row>
      <template v-if="hasPrice">
        <v-row>
          <v-col cols="12">
            <h6 class="text-h6" v-text="$trans('invoice_data')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-form ref="InvoiceDataForm">
              <c-company-data
                ref="company_data"
                :my-form="form.default_address"
                :loading="isLoadingData"
                required
                :save-button="false"
                @defaultAddress="getDefaultAddress"
              />
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h6 class="text-h6" v-text="$trans('payment_method')" />
          </v-col>
        </v-row>
        <v-row v-if="defaultPaymentMethod">
          <v-col>
            <payments-method />
          </v-col>
        </v-row>
        <template v-else>
          <v-form ref="PaymentMethodForm">
            <v-row>
              <v-col cols="12" sm="6" class="pb-0 mb-0">
                <v-stripe-card
                  ref="stripeCard"
                  v-model="stripe"
                  :api-key="apiKey"
                  :loading="isLoadingData"
                  :hide-postal-code="true"
                  outlined
                  :disabled="isLoadingData"
                  @blur="stripe = null"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-0 pt-0" cols="12" sm="6">
                <v-text-field
                  v-model="cardName"
                  :disabled="isLoadingData"
                  :rules="[rules.required, rules.maxChars(255)]"
                  :label="$trans('name_and_surname')"
                  outlined
                  :loading="isLoadingData"
                />
              </v-col>
            </v-row>
          </v-form>
        </template>
      </template>
    </v-container>
    <v-container
      fluid
      class="white pa-0 ma-0"
      :class="{
        'bottom-bar': !isInStore,
      }"
    >
      <v-row no-gutters>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>
      <v-row no-gutters justify="center">
        <v-col class="d-flex justify-center">
          <v-btn
            color="blue"
            dark
            class="ma-4"
            :loading="isLoadingData"
            x-large
            @click="subscribeAction"
          >
            {{ $trans("activate_plan") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import OrderPlanSummary from "@/views/dashboard/pages/Plans/OrderPlanSummary.vue";
import PaymentsMethod from "@/views/dashboard/pages/Billing/components/PaymentMethod.vue";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import CCompanyData from "@/components/CCompanyData/CCompanyData.vue";
import invoiceActions from "@/calendesk/mixins/invoiceActions";
import stripeActions from "@/calendesk/mixins/stripeActions";
import {
  maxChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import { debounce } from "debounce";

export default {
  components: { CCompanyData, PaymentsMethod, OrderPlanSummary },
  mixins: [sharedActions, invoiceActions, stripeActions],
  props: {
    flat: {
      type: Boolean,
      default: false,
    },
    isInStore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: {
        required,
        maxChars,
      },
      period: null,
      selectedPlanPackagesPrice: 0,
      selectedExtraPackagesPrice: 0,
      isLoadingData: false,
      form: {
        default_address: {
          name: null,
          street: null,
          postal_code: null,
          city: null,
          country_iso_code: null,
          tax_number: null,
          vat_iso_prefix: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      defaultPaymentMethod: "payments/getDefaultPaymentMethod",
      subscriptionToPay: "payments/getSubscriptionToPay",
    }),
    hasPrice() {
      return (
        (this.period && this.period.price.amount > 0) ||
        this.selectedPlanPackagesPrice > 0 ||
        this.selectedExtraPackagesPrice > 0
      );
    },
  },
  created() {
    this.period = this.subscriptionToPay.period;
    this.selectedPlanPackagesPrice =
      this.subscriptionToPay.selectedPlanPackagesPrice;
    this.selectedExtraPackagesPrice =
      this.subscriptionToPay.selectedExtraPackagesPrice;

    this.$nextTick(() => {
      this.$refs.mainContainer.scrollIntoView({ behavior: "smooth" });
    });
  },
  methods: {
    subscribeAction() {
      if (this.isLoadingData) {
        return;
      }

      this.isLoadingData = true;
      this.handlePayDebounced();
    },
    handlePayDebounced: debounce(function () {
      this.handlePay();
    }, 500),
    async handlePay() {
      if (this.hasPrice && !this.$refs.InvoiceDataForm.validate()) {
        errorNotification("fill_invoice_data", null, false);
        this.isLoadingData = false;
        return;
      }

      if (this.hasPrice && !this.defaultPaymentMethod) {
        if (!this.$refs.PaymentMethodForm.validate()) {
          errorNotification("add_payment_method", null, false);
          this.isLoadingData = false;
          return;
        }

        if (!this.stripe) {
          errorNotification("enter_card_data_error", null, false);
          this.isLoadingData = false;
          return;
        }
      }

      if (this.hasPrice) {
        try {
          await this.saveInvoiceData();
        } catch (error) {
          errorNotification("invoice_data_update_fail", error, false);
          this.isLoadingData = false;
          return;
        }
      }

      try {
        let paymentStored = true;

        if (this.hasPrice && !this.defaultPaymentMethod) {
          paymentStored = await this.processPaymentStoring();
        }

        if (paymentStored) {
          this.$root.$emit("planSummaryAcceptedEvent");
        }
      } catch (error) {
        errorNotification("general_payment_error", error);
      }

      this.isLoadingData = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
