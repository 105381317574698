const { SegmentedMessage } = require("sms-segments-calculator");

export default {
  methods: {
    getSMSHint(content) {
      if (content && content.length > 0) {
        const parsedContent = this.$options.filters.tenantVariable(content);
        const segmentedMessage = new SegmentedMessage(parsedContent);
        const { segmentsCount } = segmentedMessage;
        return `${this.$trans("number_of_sms_messages")}: ${segmentsCount}*`;
      }

      return null;
    },
    getNonGsmCharacters(content) {
      if (content && content.length > 0) {
        const segmentedMessage = new SegmentedMessage(content);
        return segmentedMessage.getNonGsmCharacters();
      }

      return null;
    },
  },
};
