<template>
  <v-container class="fill-height">
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-container>
          <v-row>
            <v-col class="text-center pa-0 py-4">
              <h2 class="text-h4">
                {{ $trans("signup_onboarding_title_" + step) }}
              </h2>
              <p class="text-body-1 mt-4">
                {{ $trans("signup_onboarding_subtitle_" + step) }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0 ma-0" cols="12">
              <v-card
                :elevation="3"
                :outlined="$vuetify.breakpoint.smAndDown"
                class="pa-0 pa-md-4"
                style="border-radius: 8px"
              >
                <v-card-title class="justify-center">
                  {{ $trans("signup_onboarding_card_title_" + step) }}
                </v-card-title>
                <v-card-subtitle class="text-center">
                  {{ $trans("signup_onboarding_card_subtitle_" + step) }}
                </v-card-subtitle>
                <v-window v-model="step">
                  <v-window-item :value="1">
                    <v-form ref="formStep1">
                      <c-signup-onboarding-company
                        :data="companyData"
                        @update="updateCompanyData"
                      />
                    </v-form>
                  </v-window-item>
                  <v-window-item :value="2">
                    <v-form ref="formStep2">
                      <c-signup-onboarding-employees
                        v-model="employees"
                        :default-country-code="getDefaultPhoneCountryIsoCode"
                      />
                    </v-form>
                  </v-window-item>
                  <v-window-item :value="3">
                    <v-form ref="formStep3">
                      <c-signup-onboarding-services
                        v-if="companyData"
                        v-model="services"
                        :currency="companyData.currency"
                        :employees="publicEmployees"
                      />
                    </v-form>
                  </v-window-item>
                  <v-window-item :value="4">
                    <v-form ref="formStep4">
                      <c-schedule
                        ref="EmployeeSchedule"
                        v-model="workingHours"
                        :not-selected-text="$trans('closed')"
                      />
                    </v-form>
                  </v-window-item>
                  <v-window-item :value="5">
                    <c-signup-onboarding-integrations />
                  </v-window-item>
                  <v-window-item :value="6">
                    <c-signup-onboarding-summary />
                  </v-window-item>
                </v-window>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mt-4 mt-md-8" justify="center">
            <v-col class="d-flex align-center" cols="12" md="6">
              <template v-if="step === 1">
                <v-btn block color="primary" depressed @click="nextStep">
                  {{ $trans("next") }}
                </v-btn>
              </template>
              <template v-else-if="step === 5">
                <v-btn
                  :disabled="!isUserLogged"
                  block
                  color="primary"
                  depressed
                  @click="nextStep"
                >
                  {{ $trans("finish") }}
                </v-btn>
              </template>
              <template v-else-if="step === 6">
                <v-btn
                  block
                  :disabled="!isUserLogged"
                  :loading="isLoadingDashboard"
                  color="primary"
                  depressed
                  @click="nextStep"
                >
                  {{ $trans("plans_thank_you_page_button") }}
                </v-btn>
              </template>
              <template v-else>
                <v-btn color="light" outlined text @click="prevStep">
                  {{ $trans("back") }}
                </v-btn>
                <v-spacer />
                <v-btn color="primary" depressed @click="nextStep">
                  {{ $trans("next") }}
                </v-btn>
              </template>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="5">
              <v-progress-linear
                :value="getStepProgress"
                background-color="dark"
                background-opacity="0.16"
                color="primary"
                rounded
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "debounce";
import {
  email,
  maxChars,
  minChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import CSignupOnboardingEmployees from "@/components/CSignUpOnboarding/CSignupOnboardingEmployees";
import CSignupOnboardingCompany from "@/components/CSignUpOnboarding/CSignupOnboardingCompany";
import {
  errorNotification,
  reportError,
} from "@/lib/calendesk-js-library/tools/notification";
import CSignupOnboardingServices from "@/components/CSignUpOnboarding/CSignupOnboardingServices";
import CSchedule from "@/components/CSchedule/CSchedule";
import {
  defaultCalendarWeekDays,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import CSignupOnboardingIntegrations from "@/components/CSignUpOnboarding/CSignupOnboardingIntegrations";
import CSignupOnboardingSummary from "@/components/CSignUpOnboarding/CSignupOnboardingSummary";
import api from "@/lib/calendesk-js-library/api/api";
import { setItem } from "@/lib/calendesk-js-library/tools/localStorageManager";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "CSignupOnboarding",
  components: {
    CSignupOnboardingSummary,
    CSignupOnboardingIntegrations,
    CSchedule,
    CSignupOnboardingServices,
    CSignupOnboardingCompany,
    CSignupOnboardingEmployees,
  },
  mixins: [sharedActions],
  props: {
    tenantEmail: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      step: 1,
      isLoadingDashboard: false,
      onboardingFired: false,
      onboardingFinished: false,
      companyData: null,
      employees: [],
      workingHours: defaultCalendarWeekDays(true),
      services: [],
      localData: null,
      rules: {
        required,
        email,
        minChars,
        maxChars,
      },
    };
  },
  computed: {
    ...mapGetters({
      adminConfiguration: "setup/getAdminConfiguration",
    }),
    getStepProgress() {
      return this.step * 16.66;
    },
    currentForm() {
      return this.$refs[`formStep${this.step}`];
    },
    currentData() {
      if (this.localData) {
        return this.localData;
      }
      if (
        this.adminConfiguration &&
        this.adminConfiguration.onboarding_admin &&
        this.adminConfiguration.onboarding_admin.step
      ) {
        return this.$helpers.cloneObject(
          this.adminConfiguration.onboarding_admin,
        );
      }

      return null;
    },
    getDefaultPhoneCountryIsoCode() {
      if (this.companyData && this.companyData.phoneCountryIsoCode) {
        return this.companyData.phoneCountryIsoCode.toLowerCase();
      }

      return "gb";
    },
    publicEmployees() {
      return this.employees.filter((employee) => !!employee.isPublic);
    },
  },
  beforeMount() {
    if (this.isUserLogged) {
      this.restoreOnboardingProgress();
    }
  },
  created() {
    this.$watch("employees", this.storeOnboardingProgress);
    this.$watch("services", this.storeOnboardingProgress);
    this.$watch("workingHours", this.storeOnboardingProgress);
  },
  methods: {
    ...mapActions({
      getUserData: "auth/getUserData",
      fetchAdminConfiguration: "setup/fetchAdminConfiguration",
      fetchConfiguration: "setup/fetchConfiguration",
    }),
    nextStep() {
      document.documentElement.scrollTop = 0;

      if (this.step >= 1 && this.step <= 3 && this.currentForm) {
        if (!this.currentForm.validate()) {
          errorNotification("form_is_invalid", {}, false);
          return;
        }

        this.currentForm.resetErrorBag();
        this.currentForm.resetValidation();

        if (this.step === 1) {
          this.fillEmployeesWithRoot();
        }

        if (this.step === 2) {
          this.fillServicesWithEmployees();
        }

        this.step++;
        this.storeOnboardingProgress();
      } else if (this.step === 4 || this.step === 5) {
        if (
          this.step === 4 &&
          (!this.workingHours || this.workingHours.length === 0)
        ) {
          errorNotification("fill_availability_schedules", {}, false);
          return;
        }

        this.fireOnboarding();
        this.step++;
        this.storeOnboardingProgress();
        pushEvent(`onboardingStep_${this.step}`);
      } else if (this.step === 6) {
        pushEvent(`onboardingStep_${this.step}`);
        this.fireOnboarding();
        this.goToDashboard();
      } else {
        reportError("ONBOARDING_ERROR_DETAILS - next step", {
          form: this.currentForm,
          step: this.step,
        });
      }
    },
    prevStep() {
      document.documentElement.scrollTop = 0;

      if (this.step > 1 && this.step <= 4 && this.currentForm) {
        this.currentForm.resetErrorBag();
        this.currentForm.resetValidation();

        if (this.step > 1) {
          this.step--;
          pushEvent(`onboardingStep_${this.step}`);
        }

        this.storeOnboardingProgress();
      } else {
        reportError("ONBOARDING_ERROR_DETAILS - prev step", {
          form: this.currentForm,
          step: this.step,
        });
      }
    },
    fillEmployeesWithRoot() {
      const hasRoot = this.employees.find((employee) => employee.root === true);

      if (!hasRoot) {
        this.employees.push({
          name: this.companyData.name,
          surname: this.companyData.surname,
          email: this.tenantEmail.toLowerCase().trim(),
          phone: this.companyData.phone,
          root: true,
          isPublic: true,
          tmpId: 1,
        });
      }
    },
    fillServicesWithEmployees() {
      if (this.services && this.services.length === 0) {
        const employeeIds = this.publicEmployees.map(
          (employee) => employee.tmpId,
        );

        this.services.push({
          name: this.$trans("service_example_name_2"),
          price: 100,
          duration: 60,
          employeeIds,
          isFree: false,
        });
      }
    },
    updateCompanyData(data) {
      this.companyData = data;
      this.storeOnboardingProgress();
    },
    storeOnboardingProgress: debounce(function () {
      if (!this.onboardingFired) {
        const data = {
          step: this.step,
          companyData: this.companyData,
          employees: this.employees,
          services: this.services,
          workingHours: this.workingHours,
        };

        this.localData = this.$helpers.cloneObject(data);

        if (this.isUserLogged) {
          api.updateConfiguration({
            onboarding_admin: this.localData,
          });
        }
      }
    }, 1000),
    restoreOnboardingProgress() {
      if (this.currentData) {
        const data = this.currentData;

        this.step = data.step;

        if (data.companyData) {
          this.companyData = data.companyData;
        }

        if (data.employees) {
          this.employees = data.employees;
        }

        if (data.services) {
          this.services = data.services;
        }

        if (data.workingHours) {
          this.workingHours = data.workingHours;
        }
      }
    },
    async fireOnboarding() {
      if (!this.onboardingFired && !this.onboardingFinished) {
        this.onboardingFired = true;

        try {
          await this.processCompanyData();
          await this.processEmployees();
          await this.processUsers();
          await this.processServices();

          pushEvent("onboardingDone");
        } catch (error) {
          errorNotification("", error);
          reportError("ONBOARDING_ERROR_DETAILS - fireOnboarding", {
            form: this.currentData,
            error,
          });
        }

        this.onboardingFinished = true;
      }
    },
    async goToDashboard() {
      this.isLoadingDashboard = true;

      if (this.onboardingFinished) {
        await this.fetchConfiguration();

        if (this.$route.name !== "dashboard") {
          this.$router.push({ name: "dashboard" });
        }
      } else {
        this.fireOnboarding();
        setTimeout(this.goToDashboard, 1000);
      }
    },
    getWorkScheduleRules() {
      const rules = [];
      if (this.workingHours && this.workingHours.length > 0) {
        this.workingHours.forEach((day) => {
          rules.push({
            intervals: [
              {
                start_time: day.start_time,
                end_time: day.end_time,
              },
            ],
            type: this.$helpers.scheduleRuleType.WDAY,
            wday: day.day,
          });
        });
      }

      return rules;
    },
    async processServices() {
      if (this.services && this.services.length > 0) {
        // Create category
        const categoryResponse = await this.createCategory();

        // Create locations in parallel
        await this.createLocations();

        if (categoryResponse) {
          // Create services
          await this.createServices(categoryResponse);
        }
      }
    },
    async createCategory() {
      try {
        return await api.createCategory({
          name: this.$trans("category_default_name"),
        });
      } catch (error) {
        reportError("ONBOARDING_ERROR_DETAILS - createCategory", { error });
      }
    },

    async createLocations() {
      const locationTypes = [
        this.$helpers.serviceLocationTypes.GOOGLE_MEET,
        this.$helpers.serviceLocationTypes.ZOOM,
        this.$helpers.serviceLocationTypes.TEAMS,
        this.$helpers.serviceLocationTypes.SKYPE,
        this.$helpers.serviceLocationTypes.WHATSAPP,
      ];

      const locationPromises = locationTypes.map(async (type) => {
        try {
          await api.createLocation({ type });
        } catch (error) {
          reportError("ONBOARDING_ERROR_DETAILS - createLocation", {
            type,
            error,
          });
        }
      });

      await Promise.all(locationPromises);
    },

    async createServices(categoryResponse) {
      for (const service of this.services) {
        try {
          await api.createService({
            category_id:
              categoryResponse && categoryResponse.data
                ? categoryResponse.data.id
                : 1,
            name: service.name,
            price: service.isFree ? 0 : Math.round(service.price * 100),
            duration: service.duration,
            employees: service.employeeIds,
            break_time_before: 0,
            break_time_after: 0,
            slots_every: 60,
            allow_online_payment: true,
            awaiting_booking_payment_time: 60,
            payment_required: false,
            booking_time_before: 60,
            cancel_time_before: 60,
            change_time_before: 60,
          });
        } catch (error) {
          reportError("ONBOARDING_ERROR_DETAILS - createService", {
            serviceName: service.name,
            error,
          });
        }
      }
    },
    async processUsers() {
      try {
        await api.createUser({
          name: this.$trans("customer_example_name"),
          surname: this.$trans("customer_example_surname"),
        });
      } catch (error) {
        reportError("ONBOARDING_ERROR_DETAILS - processUsers", {
          error,
        });
      }
    },
    async processCompanyData() {
      if (this.companyData) {
        try {
          await api.updateConfiguration({
            company_name: this.companyData.companyName,
            currency: this.companyData.currency,
            company_phone: this.companyData.phone,
            preferred_phone_countries: [this.getDefaultPhoneCountryIsoCode],
            time_zone: this.companyData.timeZone,
            onboarding_admin: [],
          });

          const companyWorkingHours = defaultCalendarWeekDays().map(
            (defaultItem) => {
              const day = this.workingHours.find(
                (item) => defaultItem.day === item.day,
              );

              return {
                day: defaultItem.day,
                is_open: !!day,
                open: day ? day.start_time : "08:00",
                close: day ? day.end_time : "17:00",
              };
            },
          );

          await api.updateCompanyWorkingHours({ days: companyWorkingHours });
        } catch (error) {
          reportError("ONBOARDING_ERROR_DETAILS - processCompanyData", {
            error,
          });
        }
      }
    },
    async processEmployees() {
      if (this.employees && this.employees.length > 0) {
        try {
          const addedEmployeeIds = [];
          for (const employee of this.employees) {
            if (employee.root) {
              await api.updateUser(
                {
                  name: employee.name,
                  surname: employee.surname,
                  email: employee.email,
                  default_phone: employee.phone,
                },
                1,
              );
            }

            let scheduleResponse = null;
            if (employee.isPublic) {
              scheduleResponse = await api.createAvailabilitySchedule({
                name: `${employee.name} ${employee.surname} - ${this.$trans(
                  "work_schedule",
                )}`,
                rules: this.getWorkScheduleRules(),
              });
            }

            const data = {
              is_public: employee.isPublic,
              default_phone: employee.phone,
              role_ids: [this.$helpers.roles.employee],
              availability_schedule_id:
                scheduleResponse && scheduleResponse.data
                  ? scheduleResponse.data.id
                  : null,
              name: employee.name,
              surname: employee.surname,
              email: employee.email,
            };

            if (employee.email && !employee.root) {
              data.send_employee_welcome_email = 1;
            }

            try {
              const response = await api.createEmployee(data);

              if (employee.isPublic) {
                addedEmployeeIds.push(response.data.id);
              }
            } catch (error) {
              reportError(
                "ONBOARDING_ERROR_DETAILS - createEmployee exception",
                {
                  error,
                },
              );
            }
          }

          setItem("calendarSelectedEmployees", addedEmployeeIds, true, true);
        } catch (error) {
          reportError("ONBOARDING_ERROR_DETAILS - processEmployees", {
            error,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cd-logo {
  position: absolute;
  top: 40px;
  left: 40px;
}
</style>
