<template>
  <div>
    <v-chip
      :class="$helpers.getStatusColor(parsedEvent.status).class"
      :color="$helpers.getStatusColor(parsedEvent.status).color"
      class="mr-1"
      x-small
    >
      {{ $trans(parsedEvent.status) }}
    </v-chip>
    <v-chip
      v-if="parsedEvent.paid"
      class="green--text"
      color="green-light"
      x-small
    >
      <span>{{ $trans("paid") }}</span>
    </v-chip>
    <v-chip v-else class="red--text" color="red-light" x-small>
      {{ $trans("not_paid") }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "EventStatusChips",
  props: {
    parsedEvent: {
      type: Object,
      required: true,
    },
  },
};
</script>
