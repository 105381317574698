import { mapActions } from "vuex";
import confirmDialogTypes from "@/components/ConfirmDialogs/confirmDialogTypes";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import planActions from "@/calendesk/mixins/planActions";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import { downloadFile } from "@/lib/calendesk-js-library/tools/downloadFile";
import sharedListActions from "@/calendesk/mixins/sharedListActions";
import roleActions from "@/calendesk/mixins/roleActions";

export default {
  mixins: [sharedListActions, planActions, roleActions],
  data() {
    return {
      isLoading: false,
      isFetchingFile: false,
      isRemovingRecordsFile: false,
    };
  },
  methods: {
    ...mapActions({
      fetchStats: "dashboard/fetchStats",
      updateUser: "user/update",
      updateMultipleUsers: "user/updateMultiple",
      deleteMultipleUsers: "user/deleteMultiple",
      updateMultipleEmployees: "employee/updateMultiple",
      deleteMultipleEmployees: "employee/deleteMultiple",
      updateEmployee: "employee/update",
      deleteUser: "user/delete",
      getUser: "user/get",
      getEmployee: "employee/get",
      refreshUsers: "user/refreshUsers",
      refreshEmployees: "employee/refreshEmployees",
      refreshBookings: "booking/refreshBookings",
      refreshGroups: "group/refreshGroups",
      fetchUserFile: "user/fetchFile",
      fetchEmployeeFile: "employee/fetchFile",
      refreshSimpleStoreTransactionList: "simpleStore/refreshTransactionList",
      refreshUserSubscriptionsList:
        "subscriptions/refreshUserSubscriptionsList",
    }),
    handleResetPassword(user, sendLinkForEmployees) {
      this.openConfirmDialog({
        type: confirmDialogTypes.SET_NEW_PASSWORD,
        data: {
          user,
          sendLinkForEmployees,
        },
      });
    },
    handleDownloadFile(selected, target, type) {
      pushEvent("downloadFile_" + type + "_" + target);

      let data = {};
      let fileName = null;
      let action = null;

      if (target === "user") {
        action = "fetchUserFile";
        fileName = this.$trans("users").toLowerCase();
        data = {
          user_ids: selected.map((obj) => obj.id),
          type: type,
        };
      } else if (target === "employee") {
        action = "fetchEmployeeFile";
        fileName = this.$trans("employees").toLowerCase();

        data = {
          employee_ids: selected.map((obj) => obj.employeeId),
          type: type,
        };
      } else {
        errorNotification(null, {
          error: "userActions@handleDownloadFile - Target unknown!",
        });
        return;
      }

      this.isFetchingFile = true;
      this[action](data)
        .then((file) => {
          downloadFile(file, type, fileName, true);
        })
        .catch((error) => {
          errorNotification(null, error);
        })
        .finally(() => {
          this.isFetchingFile = false;
        });
    },
    sendCustomerNotification(selectedUsers) {
      this.sendNotificationsToUsers(
        selectedUsers,
        this.$helpers.customerNotificationCustomerType.user,
        this.$trans("send_customers_notification_title"),
      );
    },
    sendEmployeeNotification(selectedItems) {
      const employees = selectedItems.map((item) => item.employee);

      this.sendNotificationsToUsers(
        employees,
        this.$helpers.customerNotificationCustomerType.employee,
        this.$trans("send_employee_notification_title"),
      );
    },
    handleRemove(idArray, target) {
      this.openConfirmDialog({
        type: confirmDialogTypes.USER_REMOVE,
        data: { list: idArray, target },
      });
    },
    handleLock(idArray) {
      this.openConfirmDialog({
        type: confirmDialogTypes.USER_LOCK,
        data: { list: idArray },
      });
    },
    handleUnlock(idArray) {
      this.openConfirmDialog({
        type: confirmDialogTypes.USER_UNLOCK,
        data: { list: idArray },
      });
    },
    handleAddToGroup(idArray) {
      this.openConfirmDialog({
        type: confirmDialogTypes.ADD_TO_GROUP,
        data: idArray,
      });
    },
    async setStatus({ list }, status) {
      if (Array.isArray(list)) {
        this.$emit("loading", true);
        this.isLoading = true;
        const ids = list.map((item) => item.id);

        const payload = {
          user_ids: ids,
          fields: {
            status,
          },
        };

        await this.updateMultiple(payload);
      }
    },
    updateMultiple(payload) {
      pushEvent("updateUsers");

      return this.updateMultipleUsers(payload)
        .then(() => {
          if (this.$route.name === "user") {
            this.getUser(this.$route.params.id);
          } else if (this.$route.name === "employee") {
            this.getEmployee(this.$route.params.id);
          }

          successNotification("update_success");
        })
        .catch((err) => {
          errorNotification("operation_failed", err);
        })
        .finally(() => {
          this.$emit("loading", false);
          this.isLoading = false;
          this.$emit("close");

          if (this.$route.name === "user" || this.$route.name === "users") {
            this.refreshUsers();
          } else if (
            this.$route.name === "employee" ||
            this.$route.name === "employees"
          ) {
            this.refreshEmployees();
          }

          this.refreshBookings();
          this.refreshGroups();
        });
    },
    async deleteMultiple({ list, target }) {
      if (Array.isArray(list) && target) {
        this.$emit("loading", true);
        this.isLoading = true;
        this.isRemovingRecordsFile = true;

        const ids = list.map((item) =>
          target === "user" ? item.id : item.employeeId,
        );

        let payload = {
          user_ids: ids,
        };

        let action = this.deleteMultipleUsers;

        let errorKey = "user_remove_failed_bookings";

        if (target === "employee") {
          pushEvent("removeEmployees", {
            employee_ids: ids,
          });

          payload = {
            employee_ids: ids,
          };
          action = this.deleteMultipleEmployees;
          errorKey = "employee_remove_failed_bookings_or_services";
        } else {
          pushEvent("removeUsers", {
            user_ids: ids,
          });
        }

        action(payload)
          .then(() => {
            successNotification("delete_success");
            if (this.$route.name === "user") {
              this.$router.push({ name: "users" });
            } else if (this.$route.name === "employee") {
              this.$router.push({ name: "employees" });
            }
          })
          .catch((err) => {
            errorNotification(errorKey, err, false);
          })
          .finally(() => {
            this.$emit("close");
            this.$emit("loading", false);
            this.isLoading = false;
            this.isRemovingRecordsFile = false;

            this.fetchStats();

            if (this.$route.name === "user" || this.$route.name === "users") {
              this.clearCache("user-list-v2");
              this.clearCache("user-list-filters");
              this.clearCache("event-list-user");
              this.clearCache("event-list");
              this.clearCache("event-list-filters");
              this.refreshUsers();
              this.refreshSimpleStoreTransactionList();
              this.refreshUserSubscriptionsList();
            } else if (
              this.$route.name === "employee" ||
              this.$route.name === "employees"
            ) {
              this.clearCache("event-list-user");
              this.clearCache("event-list");
              this.clearCache("event-list-filters");
              this.clearCache("availability-list");
              this.clearCache("availability-list-filters");
              this.refreshEmployees();
            }

            this.refreshBookings();
            this.refreshGroups();
          });
      }
    },
  },
};
