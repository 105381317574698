<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    :fullscreen="isFullScreen"
    :width="getWidth"
    :loading="isLoading"
    content-class="white fill-height"
    retain-focus
  >
    <v-card flat>
      <v-toolbar v-if="parsedEvent" flat max-height="56px" fixed>
        <v-btn
          :disabled="manageEventButtonLoadingState"
          fab
          x-small
          @click="closeModal(false)"
        >
          <v-icon> $close </v-icon>
        </v-btn>
        <v-toolbar-title
          v-if="$vuetify.breakpoint.mdAndUp"
          class="ml-4 text-wrap"
          max-height="56px"
          >{{ manageEventTitle }}</v-toolbar-title
        >
        <v-spacer />
        <v-btn
          color="secondary"
          depressed
          outlined
          :loading="manageEventButtonLoadingState"
          :disabled="manageEventButtonLoadingState"
          @click="$refs.manageEvent && $refs.manageEvent.fire()"
        >
          <v-icon left> $save</v-icon>
          <span>{{ manageEventButtonTitle }}</span>
        </v-btn>
      </v-toolbar>
      <v-card-text
        v-if="isLoading && !parsedEvent"
        class="d-flex align-center justify-center"
      >
        <v-progress-circular indeterminate color="primary" />
      </v-card-text>
      <template v-else-if="parsedEvent">
        <v-toolbar
          v-if="!$vuetify.breakpoint.mdAndUp"
          flat
          fixed
          max-height="56px"
          class="d-flex align-center justify-center mt-2"
        >
          <v-toolbar-title class="text-wrap text-center">{{
            manageEventTitle
          }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text
          class="mx-0 px-0 mb-0 pb-0"
          :class="{
            'mt-4': $vuetify.breakpoint.mdAndUp,
            'mt-0 pt-0': !$vuetify.breakpoint.mdAndUp,
          }"
        >
          <manage-event
            ref="manageEvent"
            :key="parsedEvent.key"
            :event="parsedEvent"
            :disabled="manageEventButtonLoadingState"
          />
        </v-card-text>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import ManageEvent from "@/views/dashboard/pages/Calendar/components/ManageEvent/ManageEvent.vue";
import eventDialogParser from "@/calendesk/mixins/eventDialogParser";
import sharedEventDialog from "@/calendesk/mixins/sharedEventDialog";

export default {
  name: "EventDialog",
  components: { ManageEvent },
  mixins: [eventDialogParser, sharedEventDialog],
  computed: {
    ...mapGetters({
      manageEventTitle: "booking/getManageEventTitle",
      manageEventButtonTitle: "booking/getManageEventButtonTitle",
      manageEventButtonLoadingState: "booking/getManageEventButtonLoadingState",
    }),
  },
  methods: {
    closeModal(reload) {
      this.dialog = false;
      this.parsedEvent = null;
      this.$root.$emit("closeEventModal", reload);
    },
  },
};
</script>
