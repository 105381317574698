<template>
  <v-dialog
    v-if="getCreateBookingTransactionDialog"
    v-model="getCreateBookingTransactionDialog"
    persistent
    scrollable
    :width="!$vuetify.breakpoint.mdAndUp ? null : '900px'"
    :fullscreen="!$vuetify.breakpoint.mdAndUp"
  >
    <v-form ref="form" @submit.prevent="handleSave">
      <v-card :loading="isLoading">
        <v-toolbar flat max-height="56px" fixed>
          <v-btn
            fab
            x-small
            :disabled="isLoading || isMakingPayment"
            @click="closeModal"
          >
            <v-icon> $close </v-icon>
          </v-btn>
          <v-card-title v-if="$vuetify.breakpoint.mdAndUp">
            {{ $trans("booking_payment_transaction_create_title") }} ({{
              getCreateBookingTransactionDialog.booking.id
            }})
          </v-card-title>
          <v-spacer />
          <v-btn
            color="secondary"
            depressed
            outlined
            :loading="isMakingPayment"
            :disabled="isLoading || isMakingPayment"
            @click="handleSave"
          >
            <v-icon left>$cash</v-icon>
            <span>{{ $trans("pay") }} ({{ paymentSum | money }})</span>
          </v-btn>
        </v-toolbar>
        <v-toolbar
          v-if="!$vuetify.breakpoint.mdAndUp"
          flat
          max-height="56px"
          fixed
          class="d-flex justify-center"
        >
          <v-card-title>
            {{ $trans("booking_payment_transaction_create_title") }} ({{
              getCreateBookingTransactionDialog.booking.id
            }})
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row v-if="!paysWithSubscription">
              <v-col
                v-if="
                  !showFakturowniaWarning && !showCustomerDataInvoiceWarning
                "
                cols="12"
              >
                <calendesk-information-message>
                  {{ $trans("create_payment_invoice_info") }}
                </calendesk-information-message>
              </v-col>
              <v-col
                v-else-if="showFakturowniaWarning"
                class="pt-0 mt-0"
                cols="12"
              >
                <calendesk-information-message
                  color="orange"
                  additional-class="white--text"
                  icon-color="white"
                  icon="$alert"
                >
                  {{ $trans("invoice_warning_fakturownia_disabled") }}
                </calendesk-information-message>
              </v-col>
              <v-col
                v-else-if="showCustomerDataInvoiceWarning"
                class="pt-0 mt-0"
                cols="12"
              >
                <calendesk-information-message
                  color="orange"
                  additional-class="white--text"
                  icon-color="white"
                  icon="$alert"
                >
                  {{ $trans("invoice_warning_client_data") }}
                </calendesk-information-message>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="paymentMethod"
                  :items="paymentMethods"
                  hide-details
                  :label="$trans('payment_method')"
                  outlined
                  :rules="[rules.required]"
                  :disabled="isLoading || isMakingPayment"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-if="!paysWithSubscription"
                  v-model="amount"
                  hide-details
                  type="number"
                  :rules="[
                    rules.required,
                    rules.maxNumber,
                    rules.minNumber(amount, 0),
                  ]"
                  :label="$trans('invoice_total')"
                  outlined
                  :suffix="$config('currency')"
                  :disabled="
                    isLoading || isMakingPayment || paysWithSubscription
                  "
                />
                <v-select
                  v-else
                  v-model="selectedUserSubscriptionId"
                  :rules="[rules.required]"
                  :items="acceptedSubscriptions"
                  :item-text="getSubscriptionText"
                  item-value="id"
                  outlined
                  :label="$trans('select_subscription')"
                  :disabled="isLoading || isMakingPayment"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="status"
                  :items="statuses"
                  hide-details
                  :rules="[rules.required]"
                  :label="$trans('status')"
                  outlined
                  :disabled="
                    isLoading || isMakingPayment || paysWithSubscription
                  "
                />
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-model="isPaid"
                  :label="$trans('paid')"
                  hide-details
                  :disabled="
                    isLoading || isMakingPayment || paysWithSubscription
                  "
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-if="!paysWithSubscription"
                  v-model="transactionId"
                  hide-details
                  :rules="[rules.maxChars(255)]"
                  :label="$trans('payment_transaction_transaction_id')"
                  outlined
                  :disabled="
                    isLoading || isMakingPayment || paysWithSubscription
                  "
                />
              </v-col>
            </v-row>
            <v-row
              v-if="
                !paysWithSubscription &&
                isPaid &&
                getCreateBookingTransactionDialog.booking.products &&
                getCreateBookingTransactionDialog.booking.products.length > 0
              "
            >
              <v-col cols="12" class="text-h6 mb-0 pb-0">
                {{ $trans("pay_for_products") }}:
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-for="product in getCreateBookingTransactionDialog.booking
                    .products"
                  :key="product.id"
                  v-model="productsToPay"
                  :value="product.id"
                  :label="getProductLabel(product)"
                  :disabled="
                    isLoading ||
                    isMakingPayment ||
                    productHasTransaction(product)
                  "
                  hide-details="auto"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  maxChars,
  maxNumber,
  minNumber,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import { bookingStatuses } from "@/calendesk/prototypes/bookingStatuses";
import helpers, {
  canIssueInvoiceForUser,
  paymentMethodsForSelect,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import planActions from "@/calendesk/mixins/planActions";

export default {
  name: "CreateBookingPaymentTransactionDialog",
  components: { CalendeskInformationMessage },
  mixins: [sharedActions, planActions],
  data() {
    return {
      isLoading: false,
      isMakingPayment: false,
      isPaid: true,
      transactionId: null,
      amount: 0,
      userSubscriptions: null,
      selectedUserSubscriptionId: null,
      status: bookingStatuses.approved,
      statuses: bookingStatuses.inputValues(),
      paymentMethod: this.$helpers.paymentMethods.CASH,
      paymentMethods: paymentMethodsForSelect(true),
      productsToPay: [],
      rules: {
        required,
        maxChars,
        minNumber,
        maxNumber,
      },
    };
  },
  computed: {
    ...mapGetters({
      getCreateBookingTransactionDialog:
        "booking/getCreateBookingTransactionDialog",
    }),
    paymentSum() {
      let productsToPayAmount = 0;
      if (
        this.getCreateBookingTransactionDialog.booking &&
        this.getCreateBookingTransactionDialog.booking.products
      ) {
        productsToPayAmount = this.productsToPay.reduce((total, productId) => {
          const product =
            this.getCreateBookingTransactionDialog.booking.products.find(
              (p) => p.id === productId
            );
          if (product && !this.productHasTransaction(product)) {
            return total + product.price;
          }
          return total;
        }, 0);
      }

      return this.amount * 100 + productsToPayAmount;
    },

    paysWithSubscription() {
      return this.paymentMethod === this.$helpers.paymentMethods.SUBSCRIPTION;
    },
    acceptedSubscriptions() {
      if (this.userSubscriptions && this.userSubscriptions.length > 0) {
        return this.userSubscriptions.filter((userSubscription) => {
          return (
            userSubscription.status ===
              this.$helpers.userSubscriptionStatus.ACTIVE ||
            (userSubscription.status ===
              this.$helpers.userSubscriptionStatus.CANCELED &&
              this.$moment(userSubscription.ends_at).isAfter(this.$moment()))
          );
        });
      }

      return [];
    },
    showCustomerDataInvoiceWarning() {
      if (
        this.getCreateBookingTransactionDialog.booking &&
        this.getCreateBookingTransactionDialog.booking.service
      ) {
        const wantsInvoice =
          this.getCreateBookingTransactionDialog.booking.service.wants_invoice;
        const user = this.getCreateBookingTransactionDialog.booking.user;

        if (wantsInvoice && (!user || !canIssueInvoiceForUser(user))) {
          return true;
        }
      }

      return false;
    },
    showFakturowniaWarning() {
      if (
        this.getCreateBookingTransactionDialog.booking &&
        this.getCreateBookingTransactionDialog.booking.service
      ) {
        const wantsInvoice =
          this.getCreateBookingTransactionDialog.booking.service.wants_invoice;

        if (wantsInvoice && !this.isFakturowniaEnabled) {
          return true;
        }
      }

      return false;
    },
  },
  watch: {
    getCreateBookingTransactionDialog(val) {
      if (val) {
        this.amount = val.amount / 100;
        this.isPaid = true;
        this.status = bookingStatuses.approved;
        this.transactionId = null;
        this.loadUserSubscriptions();

        if (val.booking && val.booking.products) {
          this.productsToPay = val.booking.products.map(
            (product) => product.id
          );
        }
      }
    },
    paysWithSubscription(val) {
      if (val) {
        this.status = bookingStatuses.approved;
        this.transactionId = null;
        this.isPaid = true;
      }
    },
    isPaid(val) {
      if (
        val &&
        this.getCreateBookingTransactionDialog.booking &&
        this.getCreateBookingTransactionDialog.booking.products
      ) {
        this.productsToPay =
          this.getCreateBookingTransactionDialog.booking.products.map(
            (product) => product.id
          );
      } else {
        this.assignProductTransactionsToProducts();
      }
    },
  },
  methods: {
    ...mapActions({
      create: "booking/createBookingPaymentTransaction",
      delete: "booking/deleteBookingPaymentTransaction",
      setCreateBookingTransactionDialog:
        "booking/setCreateBookingTransactionDialog",
      fetchAllUserSubscriptions: "subscriptions/fetchAllUserSubscriptions",
      refreshUserPayments: "user/refreshUserPayments",
      refreshProductList: "simpleStore/refreshProductList",
    }),
    loadUserSubscriptions() {
      if (
        this.getCreateBookingTransactionDialog &&
        this.getCreateBookingTransactionDialog.booking &&
        this.getCreateBookingTransactionDialog.booking.user
      ) {
        this.isLoading = true;
        this.fetchAllUserSubscriptions({
          user_id: this.getCreateBookingTransactionDialog.booking.user.id,
        })
          .then(({ data }) => {
            this.userSubscriptions = data;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    closeModal() {
      this.setCreateBookingTransactionDialog(null);
    },
    handleSave() {
      if (this.$refs.form.validate()) {
        this.add();
      }
    },
    async add() {
      this.isMakingPayment = true;
      try {
        pushEvent("createBookingPaymentTransaction");

        let data = null;

        if (this.paysWithSubscription && this.selectedUserSubscriptionId) {
          data = {
            booking_id: this.getCreateBookingTransactionDialog.booking.id,
            user_subscription_id: this.selectedUserSubscriptionId,
          };
        } else {
          data = {
            booking_id: this.getCreateBookingTransactionDialog.booking.id,
            amount: Math.round(this.amount * 100),
            transaction_id: this.transactionId,
            payment_method: this.paymentMethod,
            status: this.status,
            is_paid: this.isPaid,
            simple_store_product_ids: this.productsToPay,
          };
        }

        await this.create(data);
        this.refreshUserPayments();

        if (this.productsToPay && this.productsToPay.length > 0) {
          this.refreshProductList();
        }

        this.$emit("close");
        this.setCreateBookingTransactionDialog(null);
      } catch (error) {
        if (this.$helpers.isResponseErrorCode(error, "USER_IS_REQUIRED")) {
          errorNotification("payment_user_is_required", error, false);
        } else if (
          this.$helpers.isResponseErrorCode(
            error,
            "ACTIVE_SUBSCRIPTION_MISSING"
          )
        ) {
          errorNotification(
            "pay_with_subscription_error_message",
            error,
            false
          );
        } else {
          errorNotification(null, error);
        }
      } finally {
        this.isMakingPayment = false;
      }
    },
    getSubscriptionText(userSubscription) {
      return (
        userSubscription.subscription.name +
        ` (${this.$moment(userSubscription.created_at).format(
          this.$helpers.hourDayMonthAndYearDate
        )} [${userSubscription.id}])`
      );
    },
    productHasTransaction(product) {
      return !!this.findProductTransaction(product);
    },
    findProductTransaction(product) {
      if (
        this.getCreateBookingTransactionDialog.booking &&
        this.getCreateBookingTransactionDialog.booking
          .simpleStoreProductPaymentTransactions
      ) {
        return this.getCreateBookingTransactionDialog.booking.simpleStoreProductPaymentTransactions.find(
          (transaction) => transaction.simple_store_product_id === product.id
        );
      }

      return null;
    },
    assignProductTransactionsToProducts() {
      if (
        this.getCreateBookingTransactionDialog.booking &&
        this.getCreateBookingTransactionDialog.booking
          .simpleStoreProductPaymentTransactions
      ) {
        this.productsToPay =
          this.getCreateBookingTransactionDialog.booking.simpleStoreProductPaymentTransactions.map(
            (transaction) => transaction.simple_store_product_id
          );
      }
    },
    getProductLabel(product) {
      const productTransaction = this.findProductTransaction(product);
      let paidInfo = "";

      if (productTransaction) {
        const dateTime = this.$moment(productTransaction.paid_at).format(
          helpers.hourDayMonthAndYearDate
        );
        paidInfo = ` ${this.$trans("paid")}, ${dateTime}`;
      }

      return `${product.name} (${this.$options.filters.money(
        product.price
      )}${paidInfo})`;
    },
  },
};
</script>
