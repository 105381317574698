import storeIndex from "@/store/index";

import booking from "@/store/modules/booking";
import group from "@/store/modules/group";
import permission from "@/store/modules/permission";
import role from "@/store/modules/role";
import service from "@/store/modules/service";
import assets from "@/lib/calendesk-js-library/store/assets";
import resource from "@/store/modules/resource";
import mobileEditor from "@/store/modules/mobileEditor";
import invoices from "@/store/modules/invoices";
import newsletter from "@/store/modules/newsletter";
import customerNotification from "@/store/modules/customerNotification";
import store from "@/store/modules/store";
import simpleStore from "@/store/modules/simpleStore";
import subscriptions from "@/store/modules/subscriptions";
import google from "@/store/modules/google";
import microsoft from "@/store/modules/microsoft";
import integrations from "@/store/modules/integrations";
import location from "@/store/modules/location";
import bookingReminder from "@/store/modules/bookingReminder";

export default function () {
  storeIndex.registerModule("booking", booking);
  storeIndex.registerModule("group", group);
  storeIndex.registerModule("permission", permission);
  storeIndex.registerModule("role", role);
  storeIndex.registerModule("service", service);
  storeIndex.registerModule("assets", assets);
  storeIndex.registerModule("resource", resource);
  storeIndex.registerModule("store", store);
  storeIndex.registerModule("mobileEditor", mobileEditor);
  storeIndex.registerModule("invoices", invoices);
  storeIndex.registerModule("newsletter", newsletter);
  storeIndex.registerModule("customerNotification", customerNotification);
  storeIndex.registerModule("simpleStore", simpleStore);
  storeIndex.registerModule("subscriptions", subscriptions);
  storeIndex.registerModule("google", google);
  storeIndex.registerModule("microsoft", microsoft);
  storeIndex.registerModule("integrations", integrations);
  storeIndex.registerModule("location", location);
  storeIndex.registerModule("bookingReminder", bookingReminder);
  // If you register a new module here, remember to unregister it in unregisterModules.js
}
