<template>
  <v-row justify="center">
    <v-col cols="12">
      <div>
        <div class="font-weight-medium mb-2">{{ $trans("tags") }}:</div>
      </div>
      <div>
        <v-chip v-for="tag in tags" :key="tag.id" class="my-1 mr-2" x-small>
          <div>
            <span class="font-weight-medium">{{ tag.name }}</span>
          </div>
        </v-chip>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "EventTags",
  props: {
    tags: {
      type: Array,
      required: true,
    },
  },
};
</script>
