var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_vm._l((_vm.services),function(service,index){return [_c('v-row',{key:index},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$trans('service_name'),"rules":[_vm.rules.required, _vm.rules.maxChars(255)],"validate-on-blur":"","outlined":"","hide-details":"auto"},on:{"change":_vm.changed},model:{value:(service.name),callback:function ($$v) {_vm.$set(service, "name", $$v)},expression:"service.name"}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"dense":"","outlined":"","hide-details":"auto","items":_vm.durationList,"item-text":_vm.renderDuration,"item-value":"duration","label":_vm.$trans('duration'),"rules":[_vm.rules.required, _vm.rules.maxNumber]},on:{"change":_vm.changed},model:{value:(service.duration),callback:function ($$v) {_vm.$set(service, "duration", $$v)},expression:"service.duration"}})],1),(!service.isFree)?_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"6","md":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dense":"","type":"number","hide-details":"auto","validate-on-blur":"","outlined":"","rules":[
                _vm.rules.required,
                _vm.rules.maxNumber,
                _vm.rules.minNumber(service.price, 0) ],"label":_vm.$trans('price_in_currency', { currency: _vm.currency })},on:{"change":_vm.changed},model:{value:(service.price),callback:function ($$v) {_vm.$set(service, "price", $$v)},expression:"service.price"}},on))]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$trans("price_additional_info"))+" ")])],1):_vm._e(),_c('v-col',{staticClass:"d-flex align-start pt-0",attrs:{"cols":"6","md":"4"}},[_c('v-checkbox',{attrs:{"hide-details":"auto","label":_vm.$trans('service_free_option')},on:{"change":_vm.changed},model:{value:(service.isFree),callback:function ($$v) {_vm.$set(service, "isFree", $$v)},expression:"service.isFree"}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"font-weight-medium mb-2"},[_vm._v(" "+_vm._s(_vm.$trans("signup_onboarding_services_employees_label"))+" ")]),_c('v-select',{attrs:{"deletable-chips":"","outlined":"","items":_vm.employees,"item-text":_vm.getEmployeeName,"item-value":"tmpId","multiple":"","small-chips":"","label":_vm.$trans('employees')},on:{"change":_vm.changed},model:{value:(service.employeeIds),callback:function ($$v) {_vm.$set(service, "employeeIds", $$v)},expression:"service.employeeIds"}})],1),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"2"}},[(index > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"light","icon":""},nativeOn:{"click":function($event){return _vm.remove(index)}}},on),[_c('v-icon',[_vm._v("$trash-default")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$trans("delete"))+" ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mb-4"})],1)],1)]}),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"blue","dark":"","outlined":""},nativeOn:{"click":function($event){return _vm.add.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$trans("add_service"))+" ")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }