import { render, staticRenderFns } from "./CPlanFeatureTooltip.vue?vue&type=template&id=6ca50a02&scoped=true"
import script from "./CPlanFeatureTooltip.vue?vue&type=script&lang=js"
export * from "./CPlanFeatureTooltip.vue?vue&type=script&lang=js"
import style0 from "./CPlanFeatureTooltip.vue?vue&type=style&index=0&id=6ca50a02&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ca50a02",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VImg,VTooltip})
