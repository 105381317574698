import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  currentUserSubscriptionsTransactionListResponse: [],
  refreshSubscriptionsListStatus: 0,
  refreshUserSubscriptionsListStatus: 0,
  searchedSubscriptions: null,
  createUserSubscriptionTransactionDialog: null,
});

const getters = {
  getRefreshSubscriptionsListStatus: (state) =>
    state.refreshSubscriptionsListStatus,
  getRefreshUserSubscriptionsListStatus: (state) =>
    state.refreshUserSubscriptionsListStatus,
  getSearchedSubscriptions: (state) => state.searchedSubscriptions,
  getCreateUserSubscriptionTransactionDialog: (state) =>
    state.createUserSubscriptionTransactionDialog,
};

const state = getDefaultState();

const actions = {
  fetchAllSubscriptions(context, data) {
    return new Promise((resolve, reject) => {
      api
        .getAllSubscriptions(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchSubscription(context, id) {
    return new Promise((resolve, reject) => {
      api
        .getSubscription(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllUserSubscriptions(context, data) {
    return new Promise((resolve, reject) => {
      api
        .getAllUserSubscriptions(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchSubscriptions({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .subscriptionsSearch(data)
        .then(({ data }) => {
          commit("SET_SEARCHED_SUBSCRIPTIONS", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createUserSubscription(context, data) {
    return new Promise((resolve, reject) => {
      api
        .createUserSubscription(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createUserSubscriptionPaymentTransaction(context, data) {
    return new Promise((resolve, reject) => {
      api
        .createUserSubscriptionPaymentTransaction(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteUserSubscriptionPaymentTransaction(context, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteUserSubscriptionPaymentTransaction(
          data.user_subscription_id,
          data.user_subscription_payment_transaction_id
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  cancelUserSubscription(context, data) {
    return new Promise((resolve, reject) => {
      api
        .cancelUserSubscription(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteUserSubscription(context, subscriptionId) {
    return new Promise((resolve, reject) => {
      api
        .deleteUserSubscription(subscriptionId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllSubscriptionTransactions({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .getAllUserSubscriptionPaymentTransactions(data)
        .then(({ data }) => {
          commit(
            "SET_CURRENT_USER_SUBSCRIPTIONS_TRANSACTION_LIST_RESPONSE",
            data
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  create(context, data) {
    return new Promise((resolve, reject) => {
      api
        .createSubscription(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update(context, data) {
    return new Promise((resolve, reject) => {
      api
        .updateSubscription(data.id, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  delete(context, id) {
    return new Promise((resolve, reject) => {
      api
        .deleteSubscription(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  refreshSubscriptionsList({ commit, dispatch }) {
    dispatch("cache/clearCache", "subscriptions-list", {
      root: true,
    });

    commit(
      "SET_REFRESH_SUBSCRIPTIONS_LIST_STATUS",
      Math.floor(Math.random() * 10000000)
    );
  },
  refreshUserSubscriptionsList({ commit, dispatch }) {
    dispatch("cache/clearCache", "user-subscription-list", {
      root: true,
    });

    commit(
      "SET_REFRESH_USER_SUBSCRIPTIONS_LIST_STATUS",
      Math.floor(Math.random() * 10000000)
    );
  },
  setCreateUserSubscriptionTransactionDialog({ commit }, payload) {
    commit("SET_CREATE_USER_SUBSCRIPTION_TRANSACTION_DIALOG", payload);
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_CURRENT_USER_SUBSCRIPTIONS_TRANSACTION_LIST_RESPONSE(state, response) {
    state.currentUserSubscriptionsTransactionListResponse = response;
  },
  SET_REFRESH_SUBSCRIPTIONS_LIST_STATUS(state, value) {
    state.refreshSubscriptionsListStatus = value;
  },
  SET_REFRESH_USER_SUBSCRIPTIONS_LIST_STATUS(state, value) {
    state.refreshUserSubscriptionsListStatus = value;
  },
  SET_SEARCHED_SUBSCRIPTIONS(state, value) {
    state.searchedSubscriptions = value;
  },
  SET_CREATE_USER_SUBSCRIPTION_TRANSACTION_DIALOG(state, value) {
    state.createUserSubscriptionTransactionDialog = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
