<template>
  <v-row
    v-if="
      parsedEvent.paymentTransaction ||
      (parsedEvent.simpleStoreProductPaymentTransactions &&
        parsedEvent.simpleStoreProductPaymentTransactions.length > 0)
    "
  >
    <v-col cols="12">
      <div>
        <div class="font-weight-medium mb-2">{{ $trans("payment_2") }}:</div>
        <div>
          <div v-if="parsedEvent.paymentTransaction">
            {{
              parsedEvent.paymentTransaction.paid_at
                | dateTime($helpers.hourDayMonthAndYearDate)
            }}
            -
            {{ parsedEvent.paymentTransaction.price | money }}
            ({{ $trans(parsedEvent.paymentTransaction.provider_tag)
            }}<span v-if="parsedEvent.paymentTransaction.transaction_id"
              >, {{ parsedEvent.paymentTransaction.transaction_id }}</span
            >
            <span v-if="parsedEvent.paymentTransaction.promo_code"
              >, {{ parsedEvent.paymentTransaction.promo_code }}</span
            >)
            <template v-if="parsedEvent.paymentTransaction.invoice_number">
              {{ parsedEvent.paymentTransaction.invoice_number }}
            </template>
          </div>
          <div
            v-if="
              parsedEvent.simpleStoreProductPaymentTransactions &&
              parsedEvent.simpleStoreProductPaymentTransactions.length > 0
            "
            :class="{
              'mt-4': parsedEvent.paymentTransaction,
            }"
          >
            <div
              v-for="(
                transaction, index
              ) in parsedEvent.simpleStoreProductPaymentTransactions"
              :key="index"
            >
              <v-icon small>$basket</v-icon>
              {{ transaction.product.name }},
              {{
                transaction.paid_at | dateTime($helpers.hourDayMonthAndYearDate)
              }}
              - {{ transaction.price | money }} ({{
                $trans(transaction.provider_tag)
              }}<span v-if="transaction.transaction_id"
                >, {{ transaction.transaction_id }}</span
              ><span v-if="transaction.promo_code"
                >, {{ transaction.promo_code }}</span
              >)
              <template v-if="transaction.invoice_number">
                {{ transaction.invoice_number }}
              </template>
            </div>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "EventPayment",
  props: {
    parsedEvent: {
      type: Object,
      required: true,
    },
  },
};
</script>
