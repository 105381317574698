import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  currentProductListResponse: [],
  currentProductTransactionListResponse: [],
  refreshSimpleStoreProductListStatus: 0,
  refreshSimpleStoreTransactionListStatus: 0,
  lastAddedProduct: null,
  allProducts: null,
});

const getters = {
  getCurrentProductListResponse: (state) => state.currentProductListResponse,
  getRefreshSimpleStoreProductListStatus: (state) =>
    state.refreshSimpleStoreProductListStatus,
  getRefreshSimpleStoreTransactionListStatus: (state) =>
    state.refreshSimpleStoreTransactionListStatus,
  get: ({ currentProductListResponse }) => {
    if (currentProductListResponse && currentProductListResponse.data) {
      currentProductListResponse = currentProductListResponse.data;
    } else {
      currentProductListResponse = [];
    }

    return currentProductListResponse;
  },
  getLastAddedProduct: (state) => state.lastAddedProduct,
  getAllProducts: (state) => state.allProducts,
};

const state = getDefaultState();

const actions = {
  fetchAllProducts({ commit }, data) {
    let isAllProducts = false;

    if (!data) {
      data = {
        limit: 999999, // HACK:
      };

      isAllProducts = true;
    }

    return new Promise((resolve, reject) => {
      api
        .getAllSimpleStoreProducts(data)
        .then(({ data }) => {
          commit("SET_CURRENT_PRODUCT_LIST_RESPONSE", data);

          if (isAllProducts) {
            commit("SET_ALL_PRODUCTS", data.data);
          }

          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllProductTransactions({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .getAllSimpleStoreProductTransactions(data)
        .then(({ data }) => {
          commit("SET_CURRENT_PRODUCT_TRANSACTION_LIST_RESPONSE", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  create({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createSimpleStoreProduct(data)
        .then(({ data }) => {
          commit("SET_LAST_ADDED_PRODUCT", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update(context, data) {
    return new Promise((resolve, reject) => {
      api
        .updateSimpleStoreProduct(data.id, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  delete(context, id) {
    return new Promise((resolve, reject) => {
      api
        .deleteSimpleStoreProduct(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createPaymentTransaction({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      api
        .createSimpleStoreProductPaymentTransaction(data)
        .then(({ data }) => {
          dispatch("cache/clearCache", "event-list", {
            root: true,
          });

          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deletePaymentTransaction({ dispatch }, transactionId) {
    return new Promise((resolve, reject) => {
      api
        .deleteSimpleStoreProductPaymentTransaction(transactionId)
        .then(({ data }) => {
          dispatch("cache/clearCache", "event-list", {
            root: true,
          });
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  refreshProductList({ commit, dispatch }) {
    dispatch("cache/clearCache", "simple-store-product-list-v2", {
      root: true,
    });

    commit(
      "SET_REFRESH_SIMPLE_STORE_PRODUCT_LIST_STATUS",
      Math.floor(Math.random() * 10000000)
    );
  },
  refreshTransactionList({ commit, dispatch }) {
    dispatch("cache/clearCache", "simple-store-transaction-list", {
      root: true,
    });
    commit(
      "SET_REFRESH_SIMPLE_STORE_TRANSACTION_LIST_STATUS",
      Math.floor(Math.random() * 10000000)
    );
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_CURRENT_PRODUCT_LIST_RESPONSE(state, response) {
    state.currentProductListResponse = response;
  },
  SET_CURRENT_PRODUCT_TRANSACTION_LIST_RESPONSE(state, response) {
    state.currentProductTransactionListResponse = response;
  },
  SET_REFRESH_SIMPLE_STORE_PRODUCT_LIST_STATUS(state, value) {
    state.refreshSimpleStoreProductListStatus = value;
  },
  SET_REFRESH_SIMPLE_STORE_TRANSACTION_LIST_STATUS(state, value) {
    state.refreshSimpleStoreTransactionListStatus = value;
  },
  SET_LAST_ADDED_PRODUCT(state, id) {
    state.lastAddedProduct = id;
  },
  SET_ALL_PRODUCTS(state, response) {
    state.allProducts = response;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
