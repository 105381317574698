<template>
  <v-select
    v-model="selectedProduct"
    outlined
    :items="products"
    :label="$trans('product_in_stripe')"
    item-text="name"
    hide-details="auto"
    return-object
    :rules="rules"
    :loading="loading"
    :disabled="disabled"
    :no-data-text="$trans('nothing_here')"
    @change="$emit('input', selectedProduct)"
  >
    <template v-if="!hideAddButton" #append-outer>
      <slot />

      <v-tooltip bottom>
        <template #activator="{ on }">
          <div class="outlined-icon ml-3 mt-1" @click="handleAddNew" v-on="on">
            <v-icon>$plus</v-icon>
          </div>
        </template>
        {{ $trans("add") }}
      </v-tooltip>
    </template>
  </v-select>
</template>

<script>
import dialogTypes from "@/components/ConfirmDialogs/confirmDialogTypes";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "StripeProductField",
  mixins: [sharedActions],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    products: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideAddButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedProduct: this.value,
    };
  },
  watch: {
    value(val) {
      this.selectedProduct = this.value;
    },
  },
  methods: {
    handleAddNew() {
      this.openConfirmDialog({
        type: dialogTypes.ADD_STRIPE_PRODUCT,
      });
    },
  },
};
</script>
