import api from "@/lib/calendesk-js-library/api/api";
import { trans } from "@/lib/calendesk-js-library/prototypes/trans";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";

const getDefaultState = () => ({
  resources: null,
  resourceId: null,
  refreshResourcesStatus: false,
});

const state = getDefaultState();
const getters = {
  get: ({ resources }) => {
    if (resources !== null) {
      resources = resources.data;
    } else {
      resources = [];
    }
    return resources;
  },
  shouldRefreshResources: (state) => state.refreshResourcesStatus,
  getResourceId: (state) => state.resourceId,
};
const actions = {
  fetchAll({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .resources({
          limit: 999999, // HACK:
        })
        .then(({ data }) => {
          commit("SET_RESOURCES", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchPaginated(context, data) {
    return new Promise((resolve, reject) => {
      api
        .resources(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  create({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createResource(data)
        .then(({ data }) => {
          successNotification(trans("added_resource"));
          commit("SET_RESOURCE_ID", data);
          resolve();
        })
        .catch((error) => {
          errorNotification(trans("error_occurred"), error);
          reject(error);
        });
    });
  },
  update(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateResource(payload, payload.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  delete(context, resourceId) {
    return new Promise((resolve, reject) => {
      api
        .deleteResource(resourceId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  refreshResources({ dispatch, commit }) {
    dispatch("cache/clearCache", "resource-list", {
      root: true,
    });

    commit("SET_REFRESH_RESOURCES", true);
    setTimeout(() => {
      commit("SET_REFRESH_RESOURCES", false);
    }, 1000);
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_RESOURCES(state, items) {
    state.resources = items;
  },
  SET_RESOURCE_ID(state, id) {
    state.resourceId = id;
  },
  SET_REFRESH_RESOURCES(state, value) {
    state.refreshResourcesStatus = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
