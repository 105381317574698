<template>
  <div class="white fill-height">
    <v-btn
      v-if="!accountBlocked"
      fab
      outlined
      absolute
      left
      class="mt-4"
      color="cd_color_14"
      @click="closePastDueDialog"
    >
      <v-icon dark color="light"> $close</v-icon>
    </v-btn>
    <v-container
      v-if="!isLoadingPastDueInvoice"
      class="white fill-height"
      :class="{ 'pt-16': !$vuetify.breakpoint.mdAndUp }"
    >
      <v-row v-if="!paymentSuccessful" justify="center" no-gutters>
        <v-col cols="12" md="6" class="d-flex align-center">
          <v-container>
            <v-row>
              <v-col>
                <span class="text-h4"
                  >{{
                    accountBlocked
                      ? $trans("past_due_subscription_title")
                      : $trans("past_due_subscription_title_2")
                  }}
                  <template v-if="pastDueInvoice && !accountBlocked"
                    >({{
                      pastDueInvoice.amount_due
                        | money(
                          adminConfiguration
                            ? adminConfiguration.tenant_currency
                            : null
                        )
                    }})</template
                  ></span
                >
              </v-col>
            </v-row>
            <v-row v-if="loggedUserIsAdmin">
              <v-col>
                <template v-if="accountBlocked">
                  <p>{{ $trans("past_due_subscription_subtitle_1") }}</p>
                  <p>{{ $trans("past_due_subscription_subtitle_2") }}</p>
                </template>
                <template v-else>
                  <p>{{ $trans("past_due_subscription_subtitle_5") }}</p>
                  <p>{{ $trans("past_due_subscription_subtitle_6") }}</p>
                </template>
              </v-col>
            </v-row>
            <v-row v-else-if="accountBlocked">
              <v-col>
                <p>{{ $trans("past_due_subscription_subtitle_3") }}</p>
              </v-col>
            </v-row>
            <template v-if="loggedUserIsAdmin">
              <v-row>
                <v-col>
                  <div class="text-h5">{{ $trans("payment_method") }}:</div>
                  <payments-method />
                </v-col>
              </v-row>
              <v-row class="mt-6">
                <v-col class="d-flex align-center flex-wrap">
                  <v-btn
                    dark
                    depressed
                    x-large
                    :loading="isLoadingPayment"
                    class="mr-2 my-2"
                    color="blue"
                    @click="retryPayment"
                  >
                    {{ $trans("retry_past_due_invoice_title") }}
                    <template v-if="pastDueInvoice"
                      >({{
                        pastDueInvoice.amount_due
                          | money(
                            adminConfiguration
                              ? adminConfiguration.tenant_currency
                              : null
                          )
                      }})</template
                    >
                  </v-btn>
                  <v-btn
                    color="blue"
                    outlined
                    x-large
                    class="my-2"
                    @click="handleOpenChatClick"
                  >
                    {{ $trans("wb_chat_with_us") }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-container>
        </v-col>
        <v-col class="d-none d-md-flex align-center justify-center" cols="6">
          <v-img
            :src="
              require('@/lib/calendesk-js-library/assets/payment-failed-2.png')
            "
            class="ma-2"
            contain
            max-height="600"
          />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-container v-if="!showThankYouPage">
            <v-row class="mb-8">
              <v-col
                cols="12"
                class="d-flex justify-space-around align-center square-loader"
              >
                <square-loader />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                class="d-flex justify-space-around align-center pb-0"
              >
                <span
                  class="text-h5 text-center mb-4"
                  v-html="$trans('building_plans_title')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                class="d-flex justify-space-around align-center pt-0"
              >
                <span class="text-center">
                  {{ $trans("building_plans_subtitle") }}
                </span>
              </v-col>
            </v-row>
          </v-container>
          <plan-configuration-summary v-else />
        </v-col>
      </v-row>
      <div>
        <three-ds-dialog
          v-if="show3DSDialog"
          :show="show3DSDialog"
          :src="stripe3DsSrc"
          @close="close3DsFrame"
        />
      </div>
    </v-container>
    <v-container v-else class="white fill-height">
      <v-row justify="center" no-gutters>
        <v-col cols="12" class="d-flex align-center justify-center">
          <v-progress-circular indeterminate color="primary" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import userActions from "@/calendesk/mixins/userActions";
import planActions from "@/calendesk/mixins/planActions";
import PaymentsMethod from "@/views/dashboard/pages/Billing/components/PaymentMethod.vue";
import threeDSActions from "@/calendesk/mixins/threeDSActions";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import SquareLoader from "@/views/pages/components/SquareLoader";
import ThreeDsDialog from "@/components/Dialogs/ThreeDsDialog";
import PlanConfigurationSummary from "@/views/dashboard/pages/Plans/PlanConfigurationSummary";
import money from "../filters/money";
import { handleOpenChatClick } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "PastDueSubscription",
  components: {
    PlanConfigurationSummary,
    ThreeDsDialog,
    SquareLoader,
    PaymentsMethod,
  },
  mixins: [userActions, planActions, threeDSActions],
  props: {
    accountBlocked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoadingPayment: false,
      isLoadingPastDueInvoice: false,
      paymentSuccessful: false,
      showThankYouPage: false,
      pastDueInvoice: null,
    };
  },
  computed: {
    ...mapGetters({
      getGlobalErrorCode: "setup/getGlobalErrorCode",
    }),
  },
  created() {
    this.$on("ThreeDsSuccess", this.handlePaymentSuccess);
    this.$on("ThreeDsFailed", this.handlePaymentFail);

    this.isLoadingPastDueInvoice = true;
    this.getPastDueSubscriptionInvoice()
      .then((pastDueInvoice) => {
        this.pastDueInvoice = pastDueInvoice;
      })
      .finally(() => {
        this.isLoadingPastDueInvoice = false;
      });
  },
  beforeDestroy() {
    this.$off("ThreeDsSuccess", this.handlePaymentSuccess);
    this.$off("ThreeDsFailed", this.handlePaymentFail);
  },
  methods: {
    money,
    ...mapActions({
      retryPastDueSubscriptionPayment:
        "payments/retryPastDueSubscriptionPayment",
      getPastDueSubscriptionInvoice: "payments/getPastDueSubscriptionInvoice",
      fetchRequiredAppComponents: "setup/fetchRequiredAppComponents",
    }),
    retryPayment() {
      this.isLoadingPayment = true;

      this.retryPastDueSubscriptionPayment().then((data) => {
        const redirectRequire = this.auth3dsRedirectRequiredForData(data);
        if (redirectRequire) {
          this.beforeOpen3DsFrame(data);
        } else {
          this.handlePaymentSuccess();
        }
      });
    },
    beforeOpen3DsFrame(data) {
      this.isLoadingPayment = false;
      this.open3DsFrame(data);
    },
    handleOpenChatClick,
    handlePaymentSuccess() {
      this.paymentSuccessful = true;

      this.clearCache("invoices/fetchInvoices");
      setTimeout(this.reFetchAllAndShowThankYouPage, 5000);
    },
    reFetchAllAndShowThankYouPage() {
      this.fetchRequiredAppComponents().finally(() => {
        this.showThankYouPage = false;
      });
    },
    handlePaymentFail(error) {
      this.isLoadingPayment = false;
      errorNotification("buy_package_failed", error, false);
    },
    closePastDueDialog() {
      this.$root.$emit("closePlanView", false);
    },
  },
};
</script>

<style lang="scss"></style>
