import api from "@/lib/calendesk-js-library/api/api";
import {
  prepareBookingObject,
  prepareUnavailableBookingSpotObject,
  prepareUpdateGroupBookingObject,
} from "@/calendesk/tools/calendarHelpers";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";

const getDefaultState = () => ({
  items: null,
  refreshBookingsStatus: false,
  refreshUnavailableBookingsSlotsStatus: false,
  createBookingTransactionDialog: null,
  bookingCalendarState: false,
  manageEventTitle: null,
  manageEventButtonTitle: null,
  manageEventButtonLoadingState: false,
});

const state = getDefaultState();
const getters = {
  bookings: ({ items }) => {
    if (items !== null) {
      items = items.data;
    } else {
      items = [];
    }
    return items;
  },
  shouldRefreshBookings: (state) => state.refreshBookingsStatus,
  shouldRefreshUnavailableBookingsSlotsStatus: (state) =>
    state.refreshUnavailableBookingsSlotsStatus,
  getCreateBookingTransactionDialog: (state) =>
    state.createBookingTransactionDialog,
  getBookingCalendarState: (state) => state.bookingCalendarState,
  getManageEventTitle: (state) => state.manageEventTitle,
  getManageEventButtonTitle: (state) => state.manageEventButtonTitle,
  getManageEventButtonLoadingState: (state) =>
    state.manageEventButtonLoadingState,
};
const actions = {
  fetchEvents(context, data) {
    return new Promise((resolve, reject) => {
      api
        .events(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetch({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .bookings(data)
        .then(({ data }) => {
          commit("SET_ITEMS", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchGroup(context, data) {
    return new Promise((resolve, reject) => {
      api
        .groupBookings(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchBooking(context, bookingId) {
    return new Promise((resolve, reject) => {
      api
        .booking(bookingId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchGroupBooking(context, groupId) {
    return new Promise((resolve, reject) => {
      api
        .groupBooking(groupId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateGroupBooking(context, data) {
    return new Promise((resolve, reject) => {
      api
        .updateGroupBooking(
          prepareUpdateGroupBookingObject(data),
          data.multiSlotGroupId
        )
        .then(({ data }) => {
          successNotification("updated_calendar_event_correctly");
          resolve(data);
        })
        .catch((error) => {
          errorNotification("updating_calendar_event_failed", error, false);
          reject(error);
        });
    });
  },
  fetchUnavailableBookingSlot(context, unavailableBookingSlotId) {
    return new Promise((resolve, reject) => {
      api
        .unavailableBookingSlot(unavailableBookingSlotId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAvailableBookingSlots(context, data) {
    return new Promise((resolve, reject) => {
      api
        .getAvailableBookingSlots(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchUnavailableBookingSlots(context, data) {
    return new Promise((resolve, reject) => {
      api
        .unavailableSlots(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchFile(context, data) {
    return new Promise((resolve, reject) => {
      api
        .generateFileRecords("events", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchUnavailableBookingSlotFile(context, data) {
    return new Promise((resolve, reject) => {
      api
        .generateFileRecords("unavailable_booking_slots", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createBooking({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .createBooking(prepareBookingObject(payload))
        .then(() => {
          successNotification("created_calendar_event_correctly");
          dispatch("refreshBookings");
          resolve();
        })
        .catch((error) => {
          errorNotification("creating_calendar_event_failed", error);
          reject(error);
        });
    });
  },
  updateBooking({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateBooking(prepareBookingObject(payload), payload.id)
        .then(() => {
          successNotification("updated_calendar_event_correctly");
          dispatch("refreshBookings");
          resolve();
        })
        .catch((error) => {
          errorNotification("updating_calendar_event_failed", error, false);
          reject(error);
        });
    });
  },
  updateMultiple({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateMultipleBookings(payload)
        .then(() => {
          successNotification("updated_multiple_bookings_correctly");
          dispatch("refreshBookings");
          resolve();
        })
        .catch((error) => {
          errorNotification("updating_bookings_failed", error);
          reject(error);
        });
    });
  },
  deleteMultiple({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .deleteMultipleBookings(payload)
        .then(() => {
          successNotification("deleted_multiple_bookings_correctly");
          resolve();
        })
        .catch((error) => {
          errorNotification("deleting_bookings_failed", error, false);
          reject(error);
        });
    });
  },
  createUnavailableBookingSpot({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .createUnavailableBookingSpot(
          prepareUnavailableBookingSpotObject(payload)
        )
        .then(() => {
          dispatch("refreshBookings");
          successNotification("created_calendar_event_correctly");
          resolve();
        })
        .catch((error) => {
          errorNotification("operation_failed", error, false);
          reject(error);
        });
    });
  },
  deleteMultipleUnavailableBookingSpot({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .deleteMultipleUnavailableSlotsBookings(payload)
        .then(() => {
          successNotification(
            "deleted_multiple_unavailable_booking_slots_correctly"
          );
          resolve();
        })
        .catch((error) => {
          errorNotification("delete_failed", error, false);
          dispatch("refreshBookings");
          reject(error);
        });
    });
  },
  updateUnavailableBookingSpot({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateUnavailableBookingSpot(
          prepareUnavailableBookingSpotObject(payload),
          payload.id
        )
        .then(() => {
          dispatch("refreshBookings");
          successNotification("updated_calendar_event_correctly");
          resolve();
        })
        .catch((error) => {
          errorNotification("updating_calendar_event_failed", error, false);
          reject(error);
        });
    });
  },
  deleteBooking(context, id) {
    return new Promise((resolve, reject) => {
      api
        .deleteBooking(id)
        .then(() => {
          successNotification("delete_succeeded");
          resolve();
        })
        .catch((error) => {
          errorNotification("delete_failed", error, false);
          reject(error);
        });
    });
  },
  deleteUnavailableBookingSpot(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .deleteUnavailableBookingSpot(payload)
        .then(() => {
          successNotification("delete_succeeded");
          resolve();
        })
        .catch((error) => {
          errorNotification("delete_failed", error, false);
          reject(error);
        });
    });
  },
  createBookingPaymentTransaction({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .createBookingPaymentTransaction(payload)
        .then(() => {
          dispatch("refreshBookings");

          dispatch(
            "cache/clearCache",
            "simpleStore/fetchAllProductTransactions",
            {
              root: true,
            }
          );

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteBookingPaymentTransaction({ dispatch }, bookingId) {
    return new Promise((resolve, reject) => {
      api
        .deleteBookingPaymentTransaction(bookingId)
        .then(() => {
          dispatch("refreshBookings");
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setCreateBookingTransactionDialog({ commit }, payload) {
    commit("SET_CREATE_BOOKING_TRANSACTION_DIALOG", payload);
  },

  refreshBookings({ commit, dispatch }) {
    dispatch("cache/clearCache", "event-list", {
      root: true,
    });

    commit("SET_REFRESH_BOOKINGS", true);
    setTimeout(() => {
      commit("SET_REFRESH_BOOKINGS", false);
    }, 1000);
  },

  setBookingCalendarState({ commit }, status) {
    commit("SET_BOOKING_CALENDAR_STATE", status);
  },
  setManageEventTitle({ commit }, title) {
    commit("SET_MANAGE_EVENT_TITLE", title);
  },
  setManageEventButtonTitle({ commit }, title) {
    commit("SET_MANAGE_EVENT_BUTTON_TITLE", title);
  },
  setManageEventButtonState({ commit }, state) {
    commit("SET_MANAGE_EVENT_BUTTON_LOADING_STATE", state);
  },
};

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_ITEMS(state, items) {
    state.items = items;
  },
  SET_REFRESH_BOOKINGS(state, payload) {
    state.refreshBookingsStatus = payload;
  },
  SET_MANAGE_EVENT_TITLE(state, payload) {
    state.manageEventTitle = payload;
  },
  SET_MANAGE_EVENT_BUTTON_TITLE(state, payload) {
    state.manageEventButtonTitle = payload;
  },
  SET_MANAGE_EVENT_BUTTON_LOADING_STATE(state, payload) {
    state.manageEventButtonLoadingState = payload;
  },
  SET_BOOKING_CALENDAR_STATE(state, status) {
    state.bookingCalendarState = status;
  },
  SET_REFRESH_UNAVAILABLE_BOOKING_SLOTS(state, payload) {
    state.refreshUnavailableBookingsSlotsStatus = payload;
  },
  SET_CREATE_BOOKING_TRANSACTION_DIALOG(state, value) {
    state.createBookingTransactionDialog = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
