<template>
  <v-container v-if="event" id="manage-event-tabs" tag="section">
    <c-tabs
      ref="tabs"
      flat
      :items="items"
      :initial-tab="initialTab"
      :hide-tabs="isUpdating"
      :disabled="disabled"
      :hidden-tabs="isUpdating || isDuplicating"
      show-arrows
      :item-classes="getItemClasses"
      @change="onChange"
    />
  </v-container>
</template>
<script>
import CTabs from "@/components/CTabs/CTabs.vue";
import log from "@/lib/calendesk-js-library/tools/log";
import eventTitle from "@/views/dashboard/pages/Calendar/components/ManageEvent/shared/eventTitle";

export default {
  name: "ManageEvent",
  components: { CTabs },
  mixins: [eventTitle],
  props: {
    event: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initialTab: 0,
    };
  },
  computed: {
    getItemClasses() {
      return { "mt-0": true };
    },
    isGroupBooking() {
      return (
        this.event && this.event.service && this.event.service.max_people > 1
      );
    },
    isUpdating() {
      return (
        this.event &&
        (!!this.event.id ||
          (!!this.event.multiSlotGroupId && this.isGroupBooking))
      );
    },
    isDuplicating() {
      return this.event && this.event.duplicate;
    },
    items() {
      const items = [];

      if (
        (!this.isUpdating && !this.isDuplicating) ||
        ((this.isUpdating || this.isDuplicating) &&
          this.event &&
          (this.event.type === this.$helpers.eventTypes.booking ||
            this.event.type === this.$helpers.eventTypes.groupBooking))
      ) {
        items.push({
          tab: "manage_booking_event",
          content: () => import("./components/ManageBookingEvent.vue"),
          props: { event: this.event },
        });
      }

      if (
        (!this.isUpdating && !this.isDuplicating) ||
        ((this.isUpdating || this.isDuplicating) &&
          this.event &&
          this.event.type === this.$helpers.eventTypes.unavailableBookingSlot)
      ) {
        items.push({
          tab: "manage_unavailable_booking_slot_event",
          content: () =>
            import("./components/ManageUnavailableBookingSlotEvent.vue"),
          props: {
            event: this.event,
          },
        });
      }

      return items;
    },
  },
  mounted() {
    if (this.event) {
      const isBookingType =
        this.event.type === this.$helpers.eventTypes.booking ||
        this.event.type === this.$helpers.eventTypes.groupBooking;
      this.reloadTitles(isBookingType);
    }
  },
  methods: {
    fire() {
      if (this.$refs.tabs) {
        const activeRef = this.items[this.$refs.tabs.tab].tab;

        if (
          this.$refs.tabs.$refs[activeRef] &&
          this.$refs.tabs.$refs[activeRef][0] &&
          typeof this.$refs.tabs.$refs[activeRef][0].fire === "function"
        ) {
          this.$refs.tabs.$refs[activeRef][0].fire();
        } else {
          log.warn("Fire function not implemented!");
        }
      } else {
        log.warn("Tabs ref missing!");
      }
    },
    onChange(tabId) {
      let isBooking = true;
      if (this.items.length === 2 && tabId === 1) {
        isBooking = false;
      }

      this.reloadTitles(isBooking);
    },
  },
};
</script>
