<template>
  <v-expansion-panels
    v-model="selectedExpansionPanelId"
    accordion
    flat
    class="section-3-expansion-panels"
  >
    <v-expansion-panel :id="2">
      <c-plan-f-a-q-title :title="$trans('plans_trial_faq_2_title')" />
      <v-expansion-panel-content>
        <p class="mt-4" v-html="$trans('plans_trial_faq_2_description_1')" />
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel :id="3">
      <c-plan-f-a-q-title :title="$trans('plans_trial_faq_3_title')" />
      <v-expansion-panel-content>
        <p class="mt-4" v-html="$trans('plans_trial_faq_3_description_1')" />
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel :id="4">
      <c-plan-f-a-q-title :title="$trans('plans_trial_faq_4_title')" />
      <v-expansion-panel-content>
        <p class="mt-4" v-html="$trans('plans_trial_faq_4_description_1')" />
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel :id="5">
      <c-plan-f-a-q-title :title="$trans('plans_trial_faq_5_title')" />
      <v-expansion-panel-content>
        <p class="mt-4" v-html="$trans('plans_trial_faq_5_description_1')" />
        <p v-html="$trans('plans_trial_faq_5_description_2')" />
        <p v-html="$trans('plans_trial_faq_5_description_3')" />
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel :id="6">
      <c-plan-f-a-q-title :title="$trans('plans_trial_faq_6_title')" />
      <v-expansion-panel-content>
        <p class="mt-4" v-html="$trans('plans_trial_faq_6_description_1')" />
        <p v-html="$trans('plans_trial_faq_6_description_2')" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import CPlanFAQTitle from "@/views/dashboard/pages/Plans/components/CPlanFAQTitle.vue";

export default {
  name: "CPlanFAQ",
  components: { CPlanFAQTitle },
  data() {
    return {
      selectedExpansionPanelId: 0,
    };
  },
};
</script>

<style lang="scss">
.section-3-expansion-panels > .v-expansion-panel {
  border: thin solid #e6e8ec !important;
  border-radius: 32px;
  margin-bottom: 24px;
}

.section-3-expansion-panels .v-expansion-panel--active {
  .expand-header,
  .expand-icon {
    color: var(--v-c_dark_3-base) !important;
  }
}

.expand-icon {
  order: 0;
}

.expand-header {
  order: 1;
}
</style>
