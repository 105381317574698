<template>
  <v-row justify="center">
    <v-col cols="12">
      <div>
        <div class="font-weight-medium mb-2">{{ $trans("date") }}:</div>
        <div class="d-flex align-center">
          <div class="mr-2">{{ parsedEvent.calendarFullDate }}</div>
          <div v-if="getDuration > 0" class="mr-2">
            <v-icon>$clock</v-icon>{{ getDuration }}
          </div>
          <div v-if="isBookingType && getPrice > 0" class="mr-2">
            <v-icon>$cash</v-icon>
            {{ getPrice | money }}
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {
  calculateDuration,
  getServicePrice,
} from "@/lib/calendesk-js-library/tools/helpers";
import duration from "@/lib/calendesk-js-library/filters/duration";
import money from "@/lib/calendesk-js-library/filters/money";
import eventTypes from "@/components/Event/mixins/eventTypes";

export default {
  name: "EventDetails",
  mixins: [eventTypes],
  props: {
    parsedEvent: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getDuration() {
      return duration(
        calculateDuration(
          this.parsedEvent.startDate,
          this.parsedEvent.endDate,
          this.parsedEvent.startTime,
          this.parsedEvent.endTime
        )
      );
    },
    getPrice() {
      if (this.parsedEvent.service) {
        return getServicePrice(this.parsedEvent.service);
      }

      return 0;
    },
  },
  methods: {
    money,
    getServicePrice,
  },
};
</script>
