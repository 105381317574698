import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  currentListResponse: [],
  refreshNewsletterStatus: 0,
});

const getters = {
  getCurrentListResponse: (state) => state.currentListResponse,
  getRefreshNewsletterStatus: (state) => state.refreshNewsletterStatus,
};

const state = getDefaultState();

const actions = {
  fetchAll({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .getAllNewsletterRecords(data)
        .then(({ data }) => {
          commit("SET_CURRENT_LIST_RESPONSE", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchFile(context, data) {
    return new Promise((resolve, reject) => {
      api
        .generateFileRecords("newsletter", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  create({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createNewsletter(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateNewsletter(data.id, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteMultiple({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteMultipleNewsletterRecords(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  refreshNewsletter({ commit }) {
    commit(
      "SET_REFRESH_NEWSLETTER_STATUS",
      Math.floor(Math.random() * 10000000)
    );
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_CURRENT_LIST_RESPONSE(state, response) {
    state.currentListResponse = response;
  },
  SET_REFRESH_NEWSLETTER_STATUS(state, value) {
    state.refreshNewsletterStatus = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
