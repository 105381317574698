<template>
  <v-dialog
    v-model="dialog"
    scrollable
    fullscreen
    :loading="isLoading"
    content-class="white fill-height"
    persistent
  >
    <v-card flat>
      <v-toolbar v-if="payload" flat max-height="56px" fixed>
        <v-btn fab x-small :disabled="isLoading" @click="closeModal">
          <v-icon> $close </v-icon>
        </v-btn>
        <v-toolbar-title class="ml-4">
          <div class="d-flex align-center justify-center flex-wrap">
            <div class="text-wrap">{{ payload.title }}</div>
          </div>
        </v-toolbar-title>
        <v-spacer />
        <div>
          <v-btn
            color="secondary"
            depressed
            outlined
            :loading="isLoading"
            @click="handleSave"
          >
            <v-icon left>{{ payload.ctaButtonIcon || "$save" }}</v-icon>
            <span>{{ payload.ctaButtonTitle || null }}</span>
          </v-btn>
        </div>
      </v-toolbar>
      <v-card-text class="mt-8 mx-0 px-0">
        <v-form ref="form" @submit.prevent="handleSave">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  hide-details="auto"
                  :disabled="isLoading"
                  :rules="[rules.required, rules.maxChars(255)]"
                  :label="$trans('simple_store_product_list_product_name')"
                  outlined
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="price"
                  :disabled="isLoading"
                  hide-details="auto"
                  type="number"
                  :rules="[rules.required, rules.maxNumber]"
                  :label="$trans('simple_store_product_list_product_price')"
                  outlined
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <calendesk-information-message>
                  {{ $trans("invoice_info_1") }}
                </calendesk-information-message>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-checkbox
                  v-model="wantsInvoice"
                  :disabled="isLoading"
                  hide-details
                  :label="$trans('wants_invoice_select_title')"
                />
              </v-col>
            </v-row>
            <v-row v-if="wantsInvoice">
              <v-col class="d-flex align-center">
                {{ $trans("tax_amount_title") }}

                <v-select
                  v-model="tax"
                  :disabled="isLoading"
                  dense
                  style="max-width: 120px"
                  class="ml-2"
                  outlined
                  hide-details="auto"
                  :title="$trans('select')"
                  :rules="[rules.required]"
                  append-icon="$percent"
                  :items="taxValues"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <tip-tap
                  v-model="additionalInformation"
                  :label="
                    $trans('simple_store_product_additional_information_title')
                  "
                  :placeholder="
                    $trans('simple_store_product_additional_information_title')
                  "
                  :subtitle="
                    $trans(
                      'simple_store_product_additional_information_description',
                    )
                  "
                  :disabled="isLoading"
                  @input="additionalInformation = $event"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <tip-tap
                  v-model="description"
                  :label="$trans('simple_store_product_description_title')"
                  :placeholder="
                    $trans('simple_store_product_description_title')
                  "
                  :subtitle="$trans('simple_store_product_description_info')"
                  :disabled="isLoading"
                  @input="description = $event"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="font-weight-medium">
                {{ $trans("wb_gdpr_fields") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <gdpr-fields
                  v-model="gdprFields"
                  @change="gdprFields = $event"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="successUrl"
                  hide-details="auto"
                  :disabled="isLoading"
                  :rules="successUrl ? [rules.maxChars(255), rules.url] : []"
                  :label="$trans('subscription_success_url')"
                  :hint="$trans('subscription_success_url_example')"
                  persistent-hint
                  outlined
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="cancelUrl"
                  hide-details="auto"
                  :disabled="isLoading"
                  :rules="cancelUrl ? [rules.maxChars(255), rules.url] : []"
                  :label="$trans('subscription_cancel_url')"
                  :hint="$trans('subscription_cancel_url_example')"
                  persistent-hint
                  outlined
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-checkbox
                  v-model="status"
                  :disabled="isLoading"
                  hide-details
                  false-value="inactive"
                  true-value="active"
                  :label="$trans('product_active')"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import {
  maxChars,
  maxNumber,
  required,
  url,
} from "@/lib/calendesk-js-library/forms/validators";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import {
  getTaxValues,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage";
import TipTap from "@/lib/calendesk-js-library/components/TipTap";
import GdprFields from "@/components/GdprFields/GdprFields";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "ManageSimpleStoreProductDialog",
  components: { GdprFields, TipTap, CalendeskInformationMessage },
  mixins: [sharedActions],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      taxValues: getTaxValues(),
      isLoading: false,
      id: null,
      name: null,
      description: null,
      additionalInformation: null,
      gdprFields: null,
      price: null,
      wantsInvoice: false,
      successUrl: null,
      cancelUrl: null,
      status: "active",
      tax: "23",
      rules: {
        required,
        maxChars,
        maxNumber,
        url,
      },
    };
  },
  watch: {
    dialog(value) {
      this.$emit("input", value);
    },
    value(value) {
      this.dialog = value;
      this.reload();
    },
  },
  created() {
    this.reload();
  },
  methods: {
    ...mapActions({
      refreshProductList: "simpleStore/refreshProductList",
      fetchStats: "dashboard/fetchStats",
      create: "simpleStore/create",
      updateProduct: "simpleStore/update",
    }),
    reload() {
      this.isLoading = false;

      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }

      if (this.payload && this.payload.product) {
        this.id = this.payload.product.id;
        this.name = this.payload.product.name;
        this.description = this.payload.product.description;
        this.additionalInformation =
          this.payload.product.additional_information;
        this.price = this.payload.product.price / 100;
        this.wantsInvoice = this.payload.product.wants_invoice;
        this.tax = this.payload.product.tax;
        this.successUrl = this.payload.product.success_url;
        this.cancelUrl = this.payload.product.cancel_url;
        this.status = this.payload.product.status;
        this.gdprFields = this.payload.product.gdpr_fields;
      } else {
        this.id = null;
        this.name = null;
        this.description = null;
        this.additionalInformation = null;
        this.price = null;
        this.wantsInvoice = false;
        this.tax = "23";
        this.successUrl = null;
        this.cancelUrl = null;
        this.status = "active";
        this.gdprFields = null;
      }
    },
    handleSave() {
      if (this.$refs.form.validate()) {
        if (this.id) {
          this.update();
        } else {
          this.add();
        }
      }
    },
    async add() {
      this.isLoading = true;
      try {
        pushEvent("createSimpleStoreProduct");

        await this.create({
          name: this.name,
          description: this.description,
          price: Math.round(this.price * 100),
          tax: this.wantsInvoice ? this.tax : null,
          wants_invoice: this.wantsInvoice,
          success_url: this.successUrl,
          cancel_url: this.cancelUrl,
          status: this.status,
          additional_information: this.additionalInformation,
          gdpr_fields: JSON.stringify(this.gdprFields),
        });

        await this.fetchStats();
        await this.refreshProductList();
      } catch (error) {
        errorNotification(null, error);
      } finally {
        this.isLoading = false;
        this.closeModal();
      }
    },
    async update() {
      this.isLoading = true;
      try {
        pushEvent("updateSimpleStoreProduct");

        await this.updateProduct({
          id: this.id,
          name: this.name,
          description: this.description,
          price: Math.round(this.price * 100),
          tax: this.wantsInvoice ? this.tax : null,
          wants_invoice: this.wantsInvoice,
          success_url: this.successUrl,
          cancel_url: this.cancelUrl,
          status: this.status,
          additional_information: this.additionalInformation,
          gdpr_fields: JSON.stringify(this.gdprFields),
        });

        await this.refreshProductList();
      } catch (error) {
        errorNotification(null, error);
      } finally {
        this.isLoading = false;
        this.closeModal();
      }
    },
    closeModal() {
      this.dialog = false;
      this.$root.$emit("closeManageSimpleStoreProductModal");
    },
  },
};
</script>
