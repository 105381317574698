<template>
  <div class="text-center">
    <v-menu>
      <template #activator="{ on, attrs }">
        <v-btn v-if="showText" color="blue" v-bind="attrs" dark v-on="on">
          {{ $trans("add_variable_info_text") }}
          <v-icon color="white" right size="24"> $plus-circle-outline</v-icon>
        </v-btn>
        <v-btn v-else color="blue" v-bind="attrs" fab dark small v-on="on">
          <v-tooltip top>
            <template #activator="activator">
              <v-icon
                color="white"
                v-bind="activator.attrs"
                v-on="activator.on"
              >
                $plus-circle-outline
              </v-icon>
            </template>
            <span>{{ $trans("add_variable_info_text") }}</span>
          </v-tooltip>
        </v-btn>
      </template>
      <v-list max-height="500" class="overflow-y-auto">
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, i) in variables"
            :key="i"
            class="notification-variable-item"
          >
            <v-list-item-content @click="selectAction(item)">
              <v-list-item-title v-text="item.text" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "NotificationVariableMenu",
  props: {
    showText: {
      type: Boolean,
      default: false,
    },
    variables: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    selectAction(item) {
      this.$emit("select", `[${item.value}]`.toUpperCase());
    },
  },
};
</script>

<style scoped></style>
