import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  planTenantPackages: null,
});

const state = getDefaultState();
const getters = {};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};
const actions = {
  fetchCurrent() {
    return new Promise((resolve, reject) => {
      api
        .getCurrentTenantPlan()
        .then(({ data }) => {
          resolve(data[0]);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
