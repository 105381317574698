import { mapActions, mapGetters } from "vuex";
import CCompanyData from "@/components/CCompanyData/CCompanyData";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  components: { CCompanyData },
  data() {
    return {
      form: {
        default_address: {
          name: null,
          street: null,
          postal_code: null,
          city: null,
          country_iso_code: null,
          tax_number: null,
          vat_iso_prefix: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      invoiceData: "payments/getInvoiceData",
      adminConfiguration: "setup/getAdminConfiguration",
      configuration: "setup/getConfiguration",
      getIsReverseChargeApplied: "payments/getIsReverseChargeApplied",
    }),
  },
  created() {
    this.fillInvoiceDataForm();
  },
  methods: {
    ...mapActions({
      fetchInvoiceData: "payments/fetchInvoiceData",
      updateInvoiceData: "payments/updateInvoiceData",
      setIsReverseChargeApplied: "payments/setIsReverseChargeApplied",
    }),
    fillInvoiceDataForm() {
      if (this.invoiceData) {
        this.form.default_address.name =
          this.invoiceData.invoice_name || this.configuration.company_name;
        this.form.default_address.street = this.invoiceData.invoice_street;
        this.form.default_address.postal_code =
          this.invoiceData.invoice_postal_code;
        this.form.default_address.city = this.invoiceData.invoice_city;
        this.form.default_address.country_iso_code =
          this.invoiceData.invoice_country_iso_code ||
          this.configuration.language;
        this.form.default_address.tax_number =
          this.invoiceData.invoice_tax_number;
        this.form.default_address.vat_iso_prefix =
          this.invoiceData.invoice_vat_iso_prefix;
      } else {
        this.form.default_address.name = this.configuration.company_name;
        this.form.default_address.country_iso_code =
          this.configuration.language;
      }
    },
    getDefaultAddress(val) {
      this.form.default_address = val;
    },
    getAddressData() {
      this.$refs.company_data.emitAddress();

      return {
        invoice_name: this.form.default_address.name,
        invoice_street: this.form.default_address.street,
        invoice_postal_code: this.form.default_address.postal_code,
        invoice_city: this.form.default_address.city,
        invoice_country_iso_code: this.form.default_address.country_iso_code,
        invoice_tax_number: this.form.default_address.tax_number,
        invoice_vat_iso_prefix: this.form.default_address.vat_iso_prefix,
      };
    },
    saveInvoiceData() {
      pushEvent("updateInvoiceData");
      return this.updateInvoiceData(this.getAddressData());
    },
  },
};
