<template>
  <v-container :key="$route.name" tag="section" fluid class="white pa-0 ma-0">
    <v-btn
      v-if="!isExpired && !showBackButton && showPlans && !storeMode"
      fab
      outlined
      absolute
      left
      color="cd_color_14"
      class="mt-md-4"
      @click="closePlanView"
    >
      <v-icon dark color="light"> $close</v-icon>
    </v-btn>
    <v-btn
      v-if="
        (showBackButton && !storeMode) ||
        (showBackButton && storeMode && showSummaryOrder)
      "
      fab
      outlined
      absolute
      left
      color="cd_color_14"
      class="mt-md-4"
      @click="goBack"
    >
      <v-icon dark color="light"> $arrow-left</v-icon>
    </v-btn>
    <div v-if="!showLoader" :class="{ 'mt-4 pt-8': !storeMode }">
      <plan-tabs
        v-if="showPlans"
        flat
        :current-plan="currentPlan"
        :plan-expired="isExpired"
      />
      <order-summary v-if="showSummaryOrder" :is-in-store="storeMode" />
      <plan-configuration
        v-if="showPlanConfiguration"
        :is-in-store="storeMode"
        :plan-slug="selectedPlanSlug"
        :plan-period-type="selectedPlanPeriodType"
      />
      <plan-configuration-summary v-if="showThankYouPage" />
    </div>
    <div v-else>
      <v-container
        :class="{
          'mb-16 mt-0 pt-0 pb-16': storeMode,
        }"
      >
        <v-row class="mb-8">
          <v-col
            cols="12"
            class="d-flex justify-space-around align-center square-loader"
          >
            <square-loader />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-space-around align-center pb-0"
          >
            <span
              class="text-h5 text-center mb-4"
              v-html="$trans('building_plans_title')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-space-around align-center pt-0"
          >
            <span class="text-center">
              {{ $trans("building_plans_subtitle") }}
            </span>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <three-ds-dialog
      v-if="show3DSDialog"
      :show="show3DSDialog"
      :src="stripe3DsSrc"
      @close="close3DsFrame"
    />
  </v-container>
</template>

<script>
import PlanConfiguration from "@/views/dashboard/pages/Plans/PlanConfiguration.vue";
import PlanTabs from "@/views/dashboard/pages/Plans/PlanTabs.vue";
import OrderSummary from "@/views/dashboard/pages/Plans/OrderSummary.vue";
import planActions from "@/calendesk/mixins/planActions";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import SquareLoader from "@/views/pages/components/SquareLoader.vue";
import PlanConfigurationSummary from "@/views/dashboard/pages/Plans/PlanConfigurationSummary.vue";
import threeDSActions from "@/calendesk/mixins/threeDSActions";
import ThreeDsDialog from "@/components/Dialogs/ThreeDsDialog.vue";
import {
  chargeUser,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import { debounce } from "debounce";

export default {
  name: "CalendeskPlans",
  components: {
    ThreeDsDialog,
    PlanConfigurationSummary,
    SquareLoader,
    OrderSummary,
    PlanTabs,
    PlanConfiguration,
  },
  mixins: [planActions, threeDSActions],
  props: {
    flat: {
      type: Boolean,
      default: false,
    },
    isExpired: {
      type: Boolean,
      default: false,
    },
    storeMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBuyingPackages: false,
      showLoader: false,
      showPlans: true,
      showPlanConfiguration: false,
      showSummaryOrder: false,
      showThankYouPage: false,
      selectedPlanSlug: null,
      selectedPlanPeriodType: null,
    };
  },
  computed: {
    showBackButton() {
      return this.showPlanConfiguration || this.showSummaryOrder;
    },
    planPackageSlugs() {
      if (
        this.subscriptionToPay &&
        this.subscriptionToPay.selectedPlanPackages &&
        this.subscriptionToPay.selectedPlanPackages.length > 0
      ) {
        return this.subscriptionToPay.selectedPlanPackages.map(
          (plugin) => plugin.slug,
        );
      }

      return [];
    },
    extraPackageSlugs() {
      if (
        this.subscriptionToPay &&
        this.subscriptionToPay.selectedExtraPackages &&
        this.subscriptionToPay.selectedExtraPackages.length > 0
      ) {
        return this.subscriptionToPay.selectedExtraPackages.map(
          (plugin) => plugin.slug,
        );
      }

      return [];
    },
  },
  created() {
    this.$on("ThreeDsSuccess", this.subscribeSuccess);
    this.$on("ThreeDsFailed", this.subscribeFailed);

    this.setSubscriptionToPay(null);

    this.$root.$on("planSelectedEvent", this.handlePlanSelectedEvent);
    this.$root.$on("payForPlanEvent", this.handlePayForPlanEvent);
    this.$root.$on(
      "planSummaryAcceptedEvent",
      this.handlePlanSummaryAcceptedEvent,
    );

    if (this.storeMode) {
      this.handlePlanSelectedEvent({
        slug: this.plan.slug,
        period_type: this.periodType,
      });
    }
  },
  beforeDestroy() {
    this.$root.$off("planSelectedEvent", this.handlePlanSelectedEvent);
    this.$root.$off("payForPlanEvent", this.handlePayForPlanEvent);
    this.$root.$off(
      "planSummaryAcceptedEvent",
      this.handlePlanSummaryAcceptedEvent,
    );

    this.$off("ThreeDsSuccess", this.subscribeSuccess);
    this.$off("ThreeDsFailed", this.subscribeFailed);
  },
  methods: {
    beforeOpen3DsFrame(data) {
      this.showLoader = false;
      this.showSummaryOrder = true;
      this.open3DsFrame(data);
    },
    handlePlanSelectedEvent(data) {
      pushEvent("planSelect", {
        slug: data.slug,
      });

      if (data.slug === this.$helpers.plans.CD_BASE_LITE) {
        if (
          this.numberOfEmployees >
            this.$helpers.maxNumbersInPlan.lite.employees ||
          this.numberOfWebDrafts > this.$helpers.maxNumbersInPlan.lite.webDrafts
        ) {
          pushEvent("planSelectFailed", {
            slug: data.slug,
          });

          errorNotification("plan_unavailable_error", null, false);
          return;
        }
      } else if (data.slug === this.$helpers.plans.CD_BASE_STANDARD) {
        if (
          this.numberOfEmployees >
            this.$helpers.maxNumbersInPlan.standard.employees ||
          this.numberOfWebDrafts >
            this.$helpers.maxNumbersInPlan.standard.webDrafts
        ) {
          pushEvent("planSelectFailed", {
            slug: data.slug,
          });

          errorNotification("plan_unavailable_error", null, false);
          return;
        }
      }

      this.selectedPlanSlug = data.slug;
      this.selectedPlanPeriodType = data.period_type;
      this.showPlans = false;
      this.showPlanConfiguration = true;
    },
    handlePayForPlanEvent() {
      pushEvent("payForPlan");

      this.showPlanConfiguration = false;
      this.showSummaryOrder = true;
    },
    handlePlanSummaryAcceptedEvent() {
      pushEvent("planSummaryAccepted");

      this.showLoader = true;
      this.showSummaryOrder = false;
      this.processPaymentDebounced();
    },
    subscribeFailed(error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.code === "SUBSCRIPTION_PAST_DUE"
      ) {
        this.$root.$emit("closePlanView", false);
        this.$root.$emit("openPastDueView", false);
      } else {
        errorNotification("general_payment_error", error);
        this.showLoader = false;
        this.showSummaryOrder = true;

        pushEvent("subscribeFailed", {
          error: error,
        });
      }
    },
    subscribeSuccess() {
      this.showLoader = true;
      this.showSummaryOrder = false;

      if (
        !this.isBuyingPackages &&
        this.extraPackageSlugs &&
        this.extraPackageSlugs.length > 0
      ) {
        this.isBuyingPackages = true;
        this.purchaseExtraPackages(this.extraPackageSlugs);
      } else {
        this.paymentProcessed();
      }
    },
    processPaymentDebounced: debounce(function () {
      this.processPayment();
    }, 1000),
    processPayment() {
      if (this.subscriptionToPay) {
        this.subscribePlan({
          payment_method_identifier: this.defaultPaymentMethod
            ? this.defaultPaymentMethod.id
            : null,
          plan_period_id: this.subscriptionToPay.period.id,
          promo_code: this.subscriptionToPay.promoCode,
          packages: this.planPackageSlugs,
        })
          .then((data) => {
            const redirectRequire = this.auth3dsRedirectRequiredForData(data);
            if (redirectRequire) {
              this.beforeOpen3DsFrame(data);
            } else {
              this.subscribeSuccess();
            }
          })
          .catch((error) => {
            this.subscribeFailed(error);
          });
      }
    },
    purchaseExtraPackages(slugs) {
      pushEvent("purchaseExtraPackages", {
        slugs: slugs,
      });

      this.packagesPurchase({
        payment_method_identifier: this.defaultPaymentMethod.id,
        packages: slugs,
        quantity: 1,
      }).then((data) => {
        const redirectRequire = this.auth3dsRedirectRequiredForData(data);
        if (redirectRequire) {
          this.beforeOpen3DsFrame(data);
        } else {
          this.paymentProcessed();
        }
      });
    },
    paymentProcessed() {
      if (this.subscriptionToPay) {
        const amount =
          this.subscriptionToPay.period.price.amount +
          this.subscriptionToPay.selectedPlanPackagesPrice +
          this.subscriptionToPay.selectedExtraPackagesPrice -
          this.subscriptionToPay.promoCodePrice;

        const reportValue = Math.round((amount / 100) * 100) / 100;

        pushEvent("subscriptionPaymentProcessed", {
          slug: this.subscriptionToPay.plan.slug,
          amount: reportValue,
          currency: this.adminConfiguration.tenant_currency,
          revenue_value: reportValue,
        });

        chargeUser(
          this.subscriptionToPay.plan.slug,
          amount,
          this.adminConfiguration.tenant_currency,
        );
      }

      successNotification("subscription_set_successfully");
      this.clearCache("invoices/fetchInvoices");
      this.setSubscriptionToPay(null);
      setTimeout(this.reFetchAllAndShowThankYouPage, 5000);
    },
    reFetchAllAndShowThankYouPage() {
      this.fetchRequiredAppComponents().finally(() => {
        this.showLoader = false;
        this.showThankYouPage = true;
      });
    },
    closePlanView() {
      this.$root.$emit("closePlanView", false);
    },
    goBack() {
      if (this.showPlanConfiguration) {
        this.setSubscriptionToPay(null);

        this.showPlans = true;
        this.showPlanConfiguration = false;
      } else if (this.showSummaryOrder) {
        this.showSummaryOrder = false;
        this.showPlanConfiguration = true;
      }
    },
  },
};
</script>

<style lang="scss">
.square-loader {
  min-height: 250px;
}
</style>
