<template>
  <v-dialog
    v-model="show3DSDialog"
    :width="$vuetify.breakpoint.width <= 600 ? null : 615"
    :fullscreen="$vuetify.breakpoint.width <= 600"
  >
    <v-card>
      <v-container fluid>
        <v-row>
          <v-col class="d-flex align-center justify-center">
            <h5 class="mr-2 text-h6">
              {{ $trans("additional_auth_required") }}
            </h5>
            <v-btn icon absolute right @click="$emit('close')">
              <v-icon> $close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <external-frame
              :width="
                $vuetify.breakpoint.width <= 600
                  ? $vuetify.breakpoint.width - 15
                  : 600
              "
              :height="400"
              :src="src"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ExternalFrame from "@/lib/calendesk-js-library/components/dialogs/components/ExternalFrame";

export default {
  name: "ThreeDsDialog",
  components: { ExternalFrame },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show3DSDialog: this.show,
    };
  },
  watch: {
    show(val) {
      this.show3DSDialog = val;
    },
  },
};
</script>
