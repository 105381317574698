<template>
  <div v-if="getService">
    <service-card-details
      :dense="dense"
      :justify-left="justifyLeft"
      :justify-right="justifyRight"
      :clickable="clickable"
      :hide-details="hideDetails"
      :hide-duration="hideDuration"
      :service="getService"
      :service-type="getServiceType"
      :push-transition="pushTransition"
    />
    <div
      v-if="products && products.length > 0"
      class="mt-2"
      :class="{
        'text-left': justifyLeft,
        'text-right': justifyRight,
      }"
    >
      <div v-for="product in products" :key="product.id">
        <v-icon small>$basket</v-icon>
        {{ getProductName(product) }}
      </div>
    </div>
  </div>
</template>
<script>
import ServiceCardDetails from "@/lib/calendesk-js-library/components/service/ServiceCardDetails.vue";
import { mapGetters } from "vuex";

export default {
  name: "EventService",
  components: { ServiceCardDetails },
  props: {
    service: {
      type: Object,
      required: false,
      default: () => {},
    },
    serviceType: {
      type: Object,
      required: false,
      default: null,
    },
    serviceId: {
      type: Number,
      required: false,
      default: null,
    },
    serviceTypeId: {
      type: Number,
      required: false,
      default: null,
    },
    products: {
      type: Array,
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hideDuration: {
      type: Boolean,
      default: false,
    },
    justifyLeft: {
      type: Boolean,
      default: false,
    },
    justifyRight: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    pushTransition: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      services: "category/getServices",
    }),
    getService() {
      if (this.serviceId && this.services) {
        return this.services.find((service) => service.id === this.serviceId);
      }

      return this.service;
    },
    getServiceType() {
      if (this.serviceTypeId && this.services && this.getService.types) {
        return this.getService.types.find(
          (service) => service.id === this.serviceTypeId
        );
      }

      return this.serviceType;
    },
  },
  methods: {
    getProductName(product) {
      return (
        product.name + " (" + this.$options.filters.money(product.price) + ")"
      );
    },
  },
};
</script>
