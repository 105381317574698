import { trans } from "@/lib/calendesk-js-library/prototypes/trans";

export default [
  {
    text: trans("day"),
    value: "day",
  },
  {
    text: trans("week"),
    value: "week",
  },
  {
    text: trans("month"),
    value: "month",
  },
  {
    text: trans("year"),
    value: "year",
  },
];
