import api from "@/lib/calendesk-js-library/api/api";
import helpers from "@/lib/calendesk-js-library/tools/helpers";

const getDefaultState = () => ({
  refreshLocations: false,
  cachedLocations: [],
  cachedRuleLocations: [],
});

function sharedLoadLocationsToCacheById(
  cachedLocations,
  locationIds,
  dispatch,
) {
  return new Promise((resolve, reject) => {
    const cachedLocationIds = cachedLocations.map((location) => location.id);
    const missingLocationIds = (locationIds || []).filter(
      (id) => !cachedLocationIds.includes(id),
    );

    if (missingLocationIds.length > 0) {
      dispatch("fetchLocations", {
        ids: missingLocationIds.join(","),
        limit: missingLocationIds.length,
      })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      resolve();
    }
  });
}

const state = getDefaultState();
const getters = {
  shouldRefreshLocations: (state) => state.refreshLocations,
  getCachedLocations: (state) => state.cachedLocations,
  getCachedRuleLocations: (state) => {
    const onlineLocationItem = {
      id: helpers.staticRuleLocationTypes.ONLINE,
      type: helpers.staticRuleLocationTypes.ONLINE,
    };

    const phoneLocationItem = {
      id: helpers.staticRuleLocationTypes.PHONE,
      type: helpers.staticRuleLocationTypes.PHONE,
    };

    return [onlineLocationItem, phoneLocationItem, ...state.cachedLocations];
  },
};
const actions = {
  loadLocationsToCacheById({ state, dispatch }, locationIds) {
    return sharedLoadLocationsToCacheById(
      state.cachedLocations,
      locationIds,
      dispatch,
    );
  },
  loadRuleLocationsToCacheById({ dispatch, getters }, locationIds) {
    return sharedLoadLocationsToCacheById(
      getters.getCachedRuleLocations,
      locationIds,
      dispatch,
    );
  },
  fetchLocations({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .getLocations(data)
        .then(({ data }) => {
          commit("UPDATE_LOCATIONS_CACHE", data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createLocation(context, data) {
    return new Promise((resolve, reject) => {
      api
        .createLocation(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateLocation(context, data) {
    return new Promise((resolve, reject) => {
      api
        .updateLocation(data, data.location_id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteLocation(context, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteLocation(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  refreshLocations({ dispatch, commit }) {
    dispatch("cache/clearCache", "location-list", {
      root: true,
    });
    dispatch("cache/clearCache", "categories-services-list", {
      root: true,
    });

    commit("CLEAN_CACHED_LOCATIONS");
    commit("SET_REFRESH_LOCATIONS", true);
    setTimeout(() => {
      commit("SET_REFRESH_LOCATIONS", false);
    }, 1000);
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_REFRESH_LOCATIONS(state, value) {
    state.refreshLocations = value;
  },
  CLEAN_CACHED_LOCATIONS(state) {
    state.cachedLocations = [];
    state.cachedRuleLocations = [];
  },
  UPDATE_LOCATIONS_CACHE(state, newLocations) {
    const locationIds = new Set(
      state.cachedLocations.map((location) => location.id),
    );
    newLocations.forEach((location) => {
      if (!locationIds.has(location.id)) {
        state.cachedLocations.push(location);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
