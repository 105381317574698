var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.parsedEvent)?_c('v-item-group',{staticClass:"row-list-buttons__wrapper v-btn-toggle"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","outlined":"","small":_vm.small},on:{"click":function($event){$event.stopPropagation();return _vm.fire('edit')}}},on),[_c('v-icon',{attrs:{"color":"light"}},[_vm._v("$pencil")])],1)]}}],null,false,1547652625)},[_c('span',[_vm._v(_vm._s(_vm.$trans("edit")))])]),(_vm.canCancelItem(_vm.parsedEvent))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","outlined":"","small":_vm.small},on:{"click":function($event){$event.stopPropagation();return _vm.fire('cancel')}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("$close")])],1)]}}],null,false,4140404774)},[(_vm.isGroupBooking(_vm.parsedEvent))?_c('span',[_vm._v(_vm._s(_vm.$trans("cancel_group_bookings_title")))]):_c('span',[_vm._v(_vm._s(_vm.$trans("cancel")))])]):_vm._e(),(_vm.canDeleteItem(_vm.parsedEvent))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","outlined":"","small":_vm.small},on:{"click":function($event){$event.stopPropagation();return _vm.fire('delete')}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("$trash-default")])],1)]}}],null,false,3857947765)},[(_vm.isGroupBooking(_vm.parsedEvent))?_c('span',[_vm._v(_vm._s(_vm.$trans("delete_group_bookings_title")))]):_c('span',[_vm._v(_vm._s(_vm.$trans("delete")))])]):_vm._e(),(_vm.canItemHandlePayments(_vm.parsedEvent))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","outlined":"","small":_vm.small},on:{"click":function($event){$event.stopPropagation();return _vm.fire(_vm.parsedEvent.paid ? 'payments' : 'pay')}}},on),[_c('v-icon',{attrs:{"color":"light"}},[_vm._v("$cash")])],1)]}}],null,false,1838241378)},[(_vm.parsedEvent.paid)?_c('span',[_vm._v(_vm._s(_vm.$trans("pay")))]):_c('span',[_vm._v(_vm._s(_vm.$trans("user_payments")))])]):_vm._e(),(_vm.canEventBeDuplicated(_vm.parsedEvent))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","outlined":"","small":_vm.small},on:{"click":function($event){$event.stopPropagation();return _vm.fire('duplicate')}}},on),[_c('v-icon',{attrs:{"color":"light"}},[_vm._v("$copy")])],1)]}}],null,false,3253128674)},[(_vm.isGroupBooking(_vm.parsedEvent))?_c('span',[_vm._v(_vm._s(_vm.$trans("duplicate_group_bookings_title")))]):_c('span',[_vm._v(_vm._s(_vm.$trans("duplicate")))])]):_vm._e(),(_vm.canItemSendNotificationsToCustomers(_vm.parsedEvent))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","outlined":"","small":_vm.small},on:{"click":function($event){$event.stopPropagation();return _vm.fire('send_notification_to_customers')}}},on),[_c('v-icon',{attrs:{"color":"light"}},[_vm._v("$send")])],1)]}}],null,false,1204425838)},[(_vm.isGroupBooking(_vm.parsedEvent))?_c('span',[_vm._v(_vm._s(_vm.$trans("send_customers_notification_title")))]):_c('span',[_vm._v(_vm._s(_vm.$trans("send_customer_notification_title")))])]):_vm._e(),(
      _vm.isUnavailableBookingSlot(_vm.parsedEvent) &&
      _vm.canItemSendNotificationsToEmployees(_vm.parsedEvent)
    )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","outlined":"","small":_vm.small},on:{"click":function($event){$event.stopPropagation();return _vm.fire('send_notification_to_employees')}}},on),[_c('v-icon',{attrs:{"color":"light"}},[_vm._v("$send")])],1)]}}],null,false,3361600136)},[_c('span',[_vm._v(_vm._s(_vm.$trans("send_employee_notification_title")))])]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }