import VStripeCard from "v-stripe-elements/lib/VStripeCard";
import { mapActions, mapGetters } from "vuex";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";

export default {
  components: {
    VStripeCard,
  },
  data() {
    return {
      cardName: null,
      stripe: null,
    };
  },
  computed: {
    ...mapGetters({
      getSubscriptionToPay: "payments/getSubscriptionToPay",
    }),
    apiKey() {
      return process.env.VUE_APP_STRIPE_API_KEY;
    },
    paymentMethodData() {
      return {
        payment_method: {
          card: this.$refs.stripeCard.card,
          billing_details: {
            name: this.cardName,
          },
        },
      };
    },
  },
  methods: {
    ...mapActions({
      fetchDefaultPaymentMethod: "payments/fetchDefaultPaymentMethod",
      fetchDefaultPaymentMethodInvokeToken:
        "payments/fetchDefaultPaymentMethodInvokeToken",
      setupDefaultPaymentMethod: "payments/setupDefaultPaymentMethod",
    }),
    async getCardSetup() {
      const paymentMethodToken =
        await this.fetchDefaultPaymentMethodInvokeToken();

      if (paymentMethodToken) {
        return await this.$refs.stripeCard.stripe.confirmCardSetup(
          paymentMethodToken.client_secret,
          this.paymentMethodData
        );
      }
      errorNotification(null, { error: "paymentMethodToken missing!" });

      return null;
    },
    async processPaymentStoring() {
      const cardSetup = await this.getCardSetup();

      if (
        cardSetup &&
        cardSetup.setupIntent &&
        cardSetup.setupIntent.payment_method
      ) {
        await this.setupDefaultPaymentMethod({
          payment_method_identifier: cardSetup.setupIntent.payment_method,
          default: true,
        });

        return true;
      }
      if (cardSetup && cardSetup.error && cardSetup.error.message) {
        errorNotification(cardSetup.error.message, cardSetup);
      } else {
        errorNotification(null, cardSetup);
      }

      return false;
    },
  },
};
