var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getEmployee && _vm.getEmployee.user)?_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.detailsInTooltip ||
    (!_vm.getEmployee.user.email &&
      !_vm.getEmployee.user.default_phone &&
      !_vm.getEmployee.title)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('div',_vm._g({},on),[_c('div',{staticClass:"d-flex align-center",class:{
          'justify-left': _vm.justifyLeft,
          'justify-center': !_vm.justifyLeft,
          pointer: _vm.clickable,
        },on:{"click":function($event){$event.stopPropagation();_vm.clickable
            ? _vm.$helpers.goToEmployee(_vm.getEmployee, _vm.pushTransition)
            : null}}},[_c('v-avatar',{staticClass:"mr-2 align-self-start",attrs:{"left":"","size":_vm.dense ? 32 : 42}},[_c('v-img',{attrs:{"src":_vm.$helpers.getAvatarSrc(_vm.getEmployee.user)}})],1),_c('span',[_vm._v(_vm._s(_vm.$helpers.getEmployeeName(_vm.getEmployee)))])],1),(
          !_vm.detailsInTooltip &&
          (_vm.getEmployee.user.email ||
            _vm.getEmployee.user.default_phone ||
            _vm.getEmployee.user.title)
        )?_c('div',{staticClass:"mt-4"},[(_vm.getEmployee.title && !_vm.hideTitle)?_c('div',[_vm._v(" "+_vm._s(_vm.$trans("employee_position"))+": "+_vm._s(_vm.getEmployee.title)+" ")]):_vm._e(),(_vm.getEmployee.user.email && !_vm.hideEmail)?_c('div',[_vm._v(" "+_vm._s(_vm.$trans("email"))+": "+_vm._s(_vm.getEmployee.user.email)+" ")]):_vm._e(),(_vm.getEmployee.user.default_phone && !_vm.hidePhone)?_c('div',[_vm._v(" "+_vm._s(_vm.$trans("phone"))+": "+_vm._s(_vm.getEmployee.user.default_phone.e164)+" ")]):_vm._e()]):_vm._e()])]}}],null,false,4172854624)},[(
      _vm.detailsInTooltip &&
      (_vm.getEmployee.user.email ||
        _vm.getEmployee.user.default_phone ||
        _vm.getEmployee.title)
    )?_c('div',[(_vm.getEmployee.title && !_vm.hideTitle)?_c('div',[_vm._v(" "+_vm._s(_vm.$trans("employee_position"))+": "+_vm._s(_vm.getEmployee.title)+" ")]):_vm._e(),(_vm.getEmployee.user.email && !_vm.hideEmail)?_c('div',[_vm._v(" "+_vm._s(_vm.$trans("email"))+": "+_vm._s(_vm.getEmployee.user.email)+" ")]):_vm._e(),(_vm.getEmployee.user.default_phone && !_vm.hidePhone)?_c('div',[_vm._v(" "+_vm._s(_vm.$trans("phone"))+": "+_vm._s(_vm.getEmployee.user.default_phone.e164)+" ")]):_vm._e()]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }