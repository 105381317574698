export default {
  computed: {
    isFullScreen() {
      return this.$vuetify.breakpoint.width < 1024;
    },
    getWidth() {
      if (this.$vuetify.breakpoint.width >= 1024) {
        return "80%";
      }

      return "100%";
    },
  },
};
