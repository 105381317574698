<template>
  <v-row>
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        {{ $trans("event_history_title") }}:
      </div>

      <div v-if="hasEventCreator">
        {{ $trans("created_by") }}:
        {{ getEventSource(parsedEvent.createdBy) }} ({{
          parsedEvent.createdAt | dateTime($helpers.hourDayMonthAndYearDate)
        }})
      </div>

      <div v-if="hasEventUpdater">
        {{ $trans("updated_by") }}:
        {{ getEventSource(parsedEvent.updatedBy) }} ({{
          parsedEvent.updatedAt | dateTime($helpers.hourDayMonthAndYearDate)
        }})
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "EventHistory",
  props: {
    parsedEvent: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasEventCreator() {
      return (
        this.parsedEvent.createdBy ||
        (this.parsedEvent.data &&
          (this.parsedEvent.data.is_google_event ||
            this.parsedEvent.data.is_outlook_event))
      );
    },
    hasEventUpdater() {
      return (
        this.parsedEvent.updatedBy ||
        (this.parsedEvent.data &&
          (this.parsedEvent.data.is_google_event ||
            this.parsedEvent.data.is_outlook_event))
      );
    },
  },
  methods: {
    getEventSource(user) {
      if (user) return user;
      if (this.parsedEvent.data && this.parsedEvent.data.is_google_event)
        return this.$trans("google_calendar");
      if (this.parsedEvent.data && this.parsedEvent.data.is_outlook_event)
        return this.$trans("microsoft_outlook");
      return "";
    },
  },
};
</script>
