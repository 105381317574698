<template>
  <v-dialog
    v-model="dialog"
    scrollable
    fullscreen
    :loading="isLoading"
    content-class="white fill-height"
    persistent
  >
    <v-card flat>
      <v-toolbar v-if="payload" flat max-height="56px" fixed>
        <v-btn fab x-small :disabled="isLoading" @click="closeModal">
          <v-icon> $close </v-icon>
        </v-btn>
        <v-toolbar-title class="ml-4">
          <div class="d-flex align-center justify-center flex-wrap">
            <div class="text-wrap">{{ payload.title }}</div>
          </div>
        </v-toolbar-title>
        <v-spacer />
        <div>
          <v-btn
            color="secondary"
            depressed
            outlined
            :loading="isLoading"
            @click="handleSave"
          >
            <v-icon left>{{ payload.ctaButtonIcon || "$save" }}</v-icon>
            <span>{{ payload.ctaButtonTitle || null }}</span>
          </v-btn>
        </div>
      </v-toolbar>
      <v-card-text class="mt-8 mx-0 px-0">
        <v-form ref="form" @submit.prevent="handleSave">
          <v-container v-if="loaded">
            <v-row>
              <v-col class="text-h5">
                {{ $trans("subscription_settings") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <calendesk-information-message>
                  {{ $trans("subscriptions_settings_information") }}
                </calendesk-information-message>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  hide-details="auto"
                  :disabled="isLoading"
                  :rules="[rules.required, rules.maxChars(255)]"
                  :label="$trans('subscription_name')"
                  :hint="$trans('subscription_name_example')"
                  persistent-hint
                  outlined
                  class="required-star"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col">
                <tip-tap
                  v-model="description"
                  :placeholder="$trans('description')"
                  :disabled="isLoading"
                  @input="description = $event"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <services-field
                  v-model="selectedServiceIds"
                  multiple
                  small-chips
                  deletable-chips
                  return-ids
                  :disabled="isLoading"
                  keep-menu-open-on-select
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-checkbox
                  v-model="wantsServiceLimits"
                  :disabled="isLoading"
                  hide-details
                  :label="$trans('service_limits_label')"
                />
              </v-col>
            </v-row>
            <template v-if="wantsServiceLimits">
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="serviceLimit"
                    hide-details="auto"
                    :disabled="isLoading"
                    :rules="[
                      rules.required,
                      rules.maxNumber,
                      rules.minNumber(serviceLimit, 1),
                    ]"
                    :label="$trans('service_limits_count_label')"
                    outlined
                    class="required-star"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    v-model="wantsRecurringServiceLimits"
                    :disabled="isLoading"
                    hide-details
                    :label="$trans('service_limits_reset_time')"
                  />
                </v-col>
              </v-row>
              <v-row v-if="wantsRecurringServiceLimits">
                <v-col class="d-flex align-center">
                  <div class="text-no-wrap mr-2 checkbox-intro">
                    {{ $trans("each") }}
                  </div>
                  <v-text-field
                    v-model="intervalCount"
                    :rules="[
                      rules.required,
                      rules.maxNumber,
                      rules.minNumber(intervalCount, 1),
                    ]"
                    outlined
                    hide-details
                    type="number"
                    dense
                    class="small-width-input"
                    :disabled="isLoading"
                  />
                  <v-select
                    v-model="recurringInterval"
                    :rules="[rules.required]"
                    class="ml-2"
                    hide-details
                    :items="recurringFrequencies"
                    outlined
                    :disabled="isLoading"
                    dense
                  />
                </v-col>
              </v-row>
            </template>
            <v-divider class="my-4" />
            <v-row>
              <v-col class="text-h5">
                {{ $trans("payment_settings") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <calendesk-information-message>
                  {{ $trans("subscription_payment_settings_information") }}
                </calendesk-information-message>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <calendesk-warning-message>
                  {{ $trans("subscription_prices_warning") }}
                </calendesk-warning-message>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-if="isUpdatingSubscription"
                  outlined
                  :label="$trans('product_in_stripe')"
                  :value="selectedProduct ? selectedProduct.name : null"
                  hide-details
                  disabled
                />
                <stripe-product-field
                  v-else
                  v-model="selectedProduct"
                  :hide-add-button="isUpdatingSubscription"
                  :disabled="isLoading"
                  :products="products"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row v-if="selectedProduct" :no-gutters="!isUpdatingSubscription">
              <v-col cols="12">
                <v-text-field
                  v-if="isUpdatingSubscription"
                  outlined
                  :label="$trans('product_price_in_stripe')"
                  :value="
                    initialProductPrice
                      ? getStripePriceName(initialProductPrice)
                      : null
                  "
                  hide-details
                  disabled
                />
                <stripe-product-price-field
                  v-else
                  v-model="selectedProductPriceId"
                  :disabled="isLoading"
                  :rules="[rules.required]"
                  :product="productForPriceField"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-checkbox
                  v-model="wantsStopAutomaticPayments"
                  :disabled="isUpdatingSubscription || isLoading"
                  hide-details
                  :label="
                    $trans(
                      'subscription_stop_automatic_payments_checkbox_title',
                    )
                  "
                />
              </v-col>
            </v-row>
            <v-row v-if="wantsStopAutomaticPayments">
              <v-col class="d-flex align-center">
                <div class="text-no-wrap mr-2 checkbox-intro">
                  {{ $trans("subscription_stop_automatic_payments_after") }}
                </div>
                <v-text-field
                  v-model="stopAutomaticPaymentsIntervalCount"
                  :rules="[
                    rules.required,
                    rules.maxNumber,
                    rules.minNumber(stopAutomaticPaymentsIntervalCount, 1),
                  ]"
                  outlined
                  hide-details
                  type="number"
                  dense
                  class="small-width-input"
                  :disabled="isUpdatingSubscription || isLoading"
                />
                <v-select
                  v-model="stopAutomaticPaymentsRecurringInterval"
                  :rules="[rules.required]"
                  class="ml-2"
                  hide-details
                  :items="recurringFrequencies"
                  outlined
                  :disabled="isUpdatingSubscription || isLoading"
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <calendesk-information-message>
                  {{ $trans("invoice_info_1") }}
                </calendesk-information-message>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-checkbox
                  v-model="requireBillingData"
                  :disabled="isLoading"
                  hide-details
                  :label="$trans('require_billing_data_checkbox')"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-checkbox
                  v-model="onlyCompanyInvoiceDataType"
                  :disabled="isLoading"
                  hide-details
                  :label="$trans('only_company_invoice_data_type_checkbox')"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-checkbox
                  v-model="wantsInvoice"
                  :disabled="isLoading"
                  hide-details
                  :label="$trans('wants_invoice_select_title')"
                />
              </v-col>
            </v-row>
            <v-row v-if="wantsInvoice">
              <v-col class="d-flex align-center">
                {{ $trans("tax_amount_title") }}

                <v-select
                  v-model="tax"
                  :disabled="isLoading"
                  dense
                  style="max-width: 120px"
                  class="ml-2"
                  outlined
                  hide-details="auto"
                  :title="$trans('select')"
                  :rules="[rules.required]"
                  append-icon="$percent"
                  :items="taxValues"
                />
              </v-col>
            </v-row>
            <v-divider class="my-4" />
            <v-row>
              <v-col class="text-h5">
                {{ $trans("other_settings_title") }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-checkbox
                  v-model="allowUsersToCancel"
                  :disabled="isLoading"
                  hide-details
                  :label="
                    $trans('subscription_allow_users_to_cancel_checkbox_title')
                  "
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-checkbox
                  v-model="disableRobotIndexing"
                  :disabled="isLoading"
                  hide-details
                  :label="$trans('disable_indexing_by_robots')"
                />
              </v-col>
            </v-row>
            <v-divider class="my-4" />
            <v-row>
              <v-col class="text-h5">
                {{ $trans("wb_gdpr_fields") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <gdpr-fields
                  v-model="gdprFields"
                  :disabled="isLoading"
                  @change="gdprFields = $event"
                />
              </v-col>
            </v-row>
            <v-divider class="my-4" />
            <v-row>
              <v-col>
                <div class="text-h5">
                  {{ $trans("subscription_redirect_title") }}
                </div>
                <div>{{ $trans("subscription_redirect_question") }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="successUrl"
                  hide-details="auto"
                  :disabled="isLoading"
                  :rules="successUrl ? [rules.maxChars(255), rules.url] : []"
                  :label="$trans('subscription_success_url')"
                  :hint="$trans('subscription_success_url_example')"
                  persistent-hint
                  outlined
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="cancelUrl"
                  hide-details="auto"
                  :disabled="isLoading"
                  :rules="cancelUrl ? [rules.maxChars(255), rules.url] : []"
                  :label="$trans('subscription_cancel_url')"
                  :hint="$trans('subscription_cancel_url_example')"
                  persistent-hint
                  outlined
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <calendesk-information-message>
                  {{ $trans("subscription_status_info") }}
                </calendesk-information-message>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-checkbox
                  v-model="status"
                  :disabled="isLoading"
                  hide-details
                  false-value="inactive"
                  true-value="active"
                  :label="$trans('subscription_active')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text--secondary">
                {{ $trans("fields_required") }}
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  maxChars,
  maxNumber,
  minNumber,
  required,
  url,
} from "@/lib/calendesk-js-library/forms/validators";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import {
  getStripePriceName,
  getTaxValues,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage";
import StripeProductField from "@/components/Forms/StripeProductField";
import StripeProductPriceField from "@/components/Forms/StripeProductPriceField";
import recurringFrequencies from "@/calendesk/prototypes/recurringFrequencies";
import GdprFields from "@/components/GdprFields/GdprFields";
import TipTap from "@/lib/calendesk-js-library/components/TipTap";
import ServicesField from "@/lib/calendesk-js-library/components/ServicesField.vue";
import CalendeskWarningMessage from "@/lib/calendesk-js-library/components/CalendeskWarningMessage.vue";

export default {
  name: "ManageUserSubscriptionDialog",
  components: {
    CalendeskWarningMessage,
    ServicesField,
    TipTap,
    GdprFields,
    CalendeskInformationMessage,
    StripeProductField,
    StripeProductPriceField,
  },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: this.value,
      isUpdatingSubscription: false,
      taxValues: getTaxValues(),
      isLoading: false,
      loaded: false,
      name: null,
      description: null,
      successUrl: null,
      cancelUrl: null,
      gdprFields: [],
      wantsInvoice: false,
      disableRobotIndexing: false,
      onlyCompanyInvoiceDataType: false,
      requireBillingData: false,
      selectedProduct: null,
      selectedProductPriceId: null,
      initialProductPrice: null,
      selectedServiceIds: null,
      serviceLimit: null,
      wantsServiceLimits: false,
      wantsRecurringServiceLimits: false,
      wantsStopAutomaticPayments: false,
      stopAutomaticPaymentsIntervalCount: 1,
      stopAutomaticPaymentsRecurringInterval: "month",
      allowUsersToCancel: true,
      intervalCount: 1,
      recurringInterval: "month",
      status: "active",
      recurringFrequencies,
      tax: "23",
      rules: {
        required,
        maxChars,
        maxNumber,
        minNumber,
        url,
      },
    };
  },
  computed: {
    ...mapGetters({
      products: "stripe/getStripeConnectProducts",
    }),
    productForPriceField() {
      if (this.products && this.selectedProduct) {
        return this.products.find(
          (product) => product.id === this.selectedProduct.id,
        );
      }

      return null;
    },
  },
  watch: {
    selectedProduct() {
      this.selectedProductPriceId = null;
    },
    dialog(value) {
      this.$emit("input", value);
    },
    value(value) {
      this.dialog = value;
      this.reload();
    },
  },
  methods: {
    ...mapActions({
      fetchConnectProducts: "stripe/fetchConnectProducts",
      create: "subscriptions/create",
      update: "subscriptions/update",
      refreshSubscriptionsList: "subscriptions/refreshSubscriptionsList",
      refreshUserSubscriptionsList:
        "subscriptions/refreshUserSubscriptionsList",
      fetchStats: "dashboard/fetchStats",
      searchSubscriptions: "subscriptions/searchSubscriptions",
    }),
    reload() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }

      this.isUpdatingSubscription = false;
      this.isLoading = false;
      this.loaded = false;
      this.name = null;
      this.description = null;
      this.successUrl = null;
      this.cancelUrl = null;
      this.gdprFields = [];
      this.wantsInvoice = false;
      this.disableRobotIndexing = false;
      this.onlyCompanyInvoiceDataType = false;
      this.requireBillingData = false;
      this.selectedProduct = null;
      this.selectedProductPriceId = null;
      this.initialProductPrice = null;
      this.selectedServiceIds = null;
      this.serviceLimit = null;
      this.wantsServiceLimits = false;
      this.wantsRecurringServiceLimits = false;
      this.wantsStopAutomaticPayments = false;
      this.stopAutomaticPaymentsIntervalCount = 1;
      this.stopAutomaticPaymentsRecurringInterval = "month";
      this.allowUsersToCancel = true;
      this.intervalCount = 1;
      this.recurringInterval = "month";
      this.status = "active";
      this.tax = "23";

      if (this.payload && this.payload.subscription) {
        this.isUpdatingSubscription = true;
        setTimeout(() => {
          this.loadSubscription(this.payload.subscription);
          this.loaded = true;
        }, 500);
      } else {
        this.isLoading = true;
        this.fetchConnectProducts({
          limit: 99999, // HACK:
        }).finally(() => {
          this.isLoading = false;
          this.loaded = true;
        });
      }
    },
    getStripePriceName,
    loadSubscription(subscription) {
      this.name = subscription.name;
      this.successUrl = subscription.success_url;
      this.cancelUrl = subscription.cancel_url;
      this.description = subscription.description;
      this.status = subscription.status;
      this.gdprFields = subscription.gdpr_fields;
      this.allowUsersToCancel = subscription.allow_users_to_cancel;
      this.stopAutomaticPaymentsRecurringInterval =
        subscription.stop_automatic_payments_recurring_interval;
      this.stopAutomaticPaymentsIntervalCount =
        subscription.stop_automatic_payments_interval_count;
      this.wantsStopAutomaticPayments =
        this.stopAutomaticPaymentsRecurringInterval &&
        this.stopAutomaticPaymentsIntervalCount;

      this.selectedServiceIds = subscription.services.map(
        (service) => service.id,
      );
      this.requireBillingData = !!subscription.require_billing_data;

      if (subscription.service_limit) {
        this.wantsServiceLimits = true;
        this.serviceLimit = subscription.service_limit;

        if (subscription.recurring_interval) {
          this.wantsRecurringServiceLimits = true;
          this.recurringInterval = subscription.recurring_interval;
          this.intervalCount = subscription.interval_count;
        }
      }

      if (subscription.wants_invoice) {
        this.wantsInvoice = true;
        this.tax = subscription.tax;
      }

      this.disableRobotIndexing = subscription.disable_robot_indexing;
      this.onlyCompanyInvoiceDataType =
        subscription.only_company_invoice_data_type;

      if (subscription.price) {
        this.selectedProduct = subscription.price.product;
        this.selectedProductPriceId = subscription.price.id;
        this.initialProductPrice = subscription.price;
      }
    },
    handleSave() {
      if (this.$refs.form.validate()) {
        this.save();
      } else {
        errorNotification("form_is_invalid", {}, false);
      }
    },
    async save() {
      this.isLoading = true;
      try {
        const data = {
          stripe_product_price_id: this.selectedProductPriceId,
          name: this.name,
          description: this.description,
          status: this.status,
          service_ids: this.selectedServiceIds || [],
          success_url: this.successUrl,
          cancel_url: this.cancelUrl,
          gdpr_fields: JSON.stringify(this.gdprFields),
          require_billing_data: this.requireBillingData,
          disable_robot_indexing: this.disableRobotIndexing,
          only_company_invoice_data_type: this.onlyCompanyInvoiceDataType,

          allow_users_to_cancel: this.allowUsersToCancel,
        };

        if (this.wantsStopAutomaticPayments) {
          data.stop_automatic_payments_recurring_interval =
            this.stopAutomaticPaymentsRecurringInterval;
          data.stop_automatic_payments_interval_count =
            this.stopAutomaticPaymentsIntervalCount;
        }

        if (this.wantsServiceLimits) {
          data.service_limit = this.serviceLimit;

          if (this.wantsRecurringServiceLimits) {
            data.recurring_interval = this.recurringInterval;
            data.interval_count = this.intervalCount;
          }
        }

        if (this.wantsInvoice) {
          data.wants_invoice = this.wantsInvoice;
          data.tax = this.tax;
        }

        if (!this.isUpdatingSubscription) {
          pushEvent("createSubscription");
          await this.create(data);
          await this.fetchStats().then(() => {
            this.refreshSubscriptionsList();
          });
        } else {
          pushEvent("updateSubscription");

          data.id = this.payload.subscription.id;
          await this.update(data);
          this.refreshSubscriptionsList();
        }

        this.isLoading = false;
        this.closeModal();
      } catch (error) {
        errorNotification(null, error);
      } finally {
        this.refreshUserSubscriptionsList();

        // Reload subscriptions
        await this.searchSubscriptions({ order_by: "id", ascending: 0 });

        this.isLoading = false;
      }
    },
    closeModal() {
      this.dialog = false;
      this.$root.$emit("closeManageUserSubscriptionModal");
    },
  },
};
</script>
