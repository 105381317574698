<template>
  <v-container fluid>
    <template v-for="(service, index) in services">
      <v-row :key="index">
        <v-col cols="12" class="d-flex align-center">
          <v-text-field
            v-model="service.name"
            dense
            :label="$trans('service_name')"
            :rules="[rules.required, rules.maxChars(255)]"
            validate-on-blur
            outlined
            hide-details="auto"
            @change="changed"
          />
        </v-col>
        <v-col cols="12" md="4" class="d-flex align-center">
          <v-select
            v-model="service.duration"
            dense
            outlined
            hide-details="auto"
            :items="durationList"
            :item-text="renderDuration"
            item-value="duration"
            :label="$trans('duration')"
            :rules="[rules.required, rules.maxNumber]"
            @change="changed"
          />
        </v-col>
        <v-col
          v-if="!service.isFree"
          cols="6"
          md="4"
          class="d-flex align-center"
        >
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-text-field
                v-model="service.price"
                dense
                type="number"
                hide-details="auto"
                validate-on-blur
                outlined
                :rules="[
                  rules.required,
                  rules.maxNumber,
                  rules.minNumber(service.price, 0),
                ]"
                :label="$trans('price_in_currency', { currency })"
                v-on="on"
                @change="changed"
              />
            </template>
            {{ $trans("price_additional_info") }}
          </v-tooltip>
        </v-col>

        <v-col cols="6" md="4" class="d-flex align-start pt-0">
          <v-checkbox
            v-model="service.isFree"
            hide-details="auto"
            :label="$trans('service_free_option')"
            @change="changed"
          />
        </v-col>

        <v-col cols="10">
          <div class="font-weight-medium mb-2">
            {{ $trans("signup_onboarding_services_employees_label") }}
          </div>
          <v-select
            v-model="service.employeeIds"
            deletable-chips
            outlined
            :items="employees"
            :item-text="getEmployeeName"
            item-value="tmpId"
            multiple
            small-chips
            :label="$trans('employees')"
            @change="changed"
          />
        </v-col>

        <v-col cols="2" class="d-flex align-center justify-end">
          <v-tooltip v-if="index > 0" bottom>
            <template #activator="{ on }">
              <v-btn color="light" icon @click.native="remove(index)" v-on="on">
                <v-icon>$trash-default</v-icon>
              </v-btn>
            </template>
            {{ $trans("delete") }}
          </v-tooltip>
        </v-col>

        <v-col cols="12">
          <v-divider class="mb-4" />
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col>
        <v-btn color="blue" dark outlined @click.native="add">
          {{ $trans("add_service") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  email,
  maxChars,
  maxNumber,
  minChars,
  minNumber,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import {
  pushEvent,
  serviceDurationList,
} from "@/lib/calendesk-js-library/tools/helpers";
import duration from "@/lib/calendesk-js-library/filters/duration";

export default {
  name: "CSignupOnboardingServices",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    employees: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      services: this.value,
      durationList: serviceDurationList(),
      rules: {
        required,
        email,
        minChars,
        maxChars,
        minNumber,
        maxNumber,
      },
    };
  },
  watch: {
    value(val) {
      this.services = val;
    },
    employees(val) {
      if (val && val.length > 0) {
        for (const service of this.services) {
          if (service.employeeIds && service.employeeIds.length > 0) {
            service.employeeIds = service.employeeIds.filter((employeeId) =>
              val.find((employee) => employee.tmpId === employeeId)
            );
          }
        }
      }
    },
  },
  methods: {
    changed() {
      this.$emit("change", this.$helpers.cloneObject(this.services));
    },
    add() {
      pushEvent("onboardingStepServicesAdd");

      const employeeIds = this.employees
        .filter((employee) => !!employee.isPublic)
        .map((employee) => employee.tmpId);
      this.services.push({
        employeeIds,
        duration: 60,
      });
      this.changed();
    },
    remove(serviceIndex) {
      pushEvent("onboardingStepServicesRemove");

      const services = this.$helpers.cloneObject(this.services);
      this.services = services.filter((item, index) => index !== serviceIndex);

      this.changed();
    },
    renderDuration(value) {
      return duration(value.duration);
    },
    getEmployeeName(employee) {
      return `${employee.name} ${employee.surname}`;
    },
  },
};
</script>
