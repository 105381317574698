import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  items: [],
});

const state = getDefaultState();
const getters = {
  getRoles: (state) => state.items,
};
const actions = {
  fetch({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .roles(data)
        .then(({ data }) => {
          commit("SET_ITEMS", data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_ITEMS(state, items) {
    state.items = items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
