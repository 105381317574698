<template>
  <div class="wrapper">
    <v-row justify="center" no-gutters>
      <v-col
        class="c-text-18 font-weight-bold"
        :class="{ 'c_dark--text': !featured, 'white--text': featured }"
      >
        {{ header.name }}
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-4">
      <v-col class="pb-0 mb-0">
        <template v-if="!isEnterprise">
          <span
            class="c-text-36 font-weight-bold"
            :class="{ 'c_dark--text': !featured, 'white--text': featured }"
          >
            {{
              getPrice
                | money(
                  adminConfiguration
                    ? adminConfiguration.tenant_currency
                    : null,
                  true
                )
            }}</span
          >&nbsp;<span class="c-text-16 c_gray_3--text">{{
            $trans("monthly_no_vat")
          }}</span
          ><br />
          <span v-if="isMonthToMonth" class="c-text-16 c_gray_3--text">{{
            $trans("plans_month_to_month_payment")
          }}</span>
          <span v-else class="c-text-16 c_gray_3--text">{{
            $trans("plans_billed_annually")
          }}</span>
        </template>
        <template v-else>
          <div class="pa-2 c-text-16 c_gray_3--text">
            {{ $trans("enterprise_price_list_info") }}
          </div>
        </template>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-btn
          v-if="!isEnterprise"
          class="pa-5 text-none tag-go-to-plans c_white_1--text mb-4"
          color="c_dark_3"
          rounded
          :small="$vuetify.breakpoint.mdAndDown"
          elevation="0"
          @click="selectPlan"
        >
          <span class="font-sans-bold">
            {{ $trans("select") }}
          </span>
        </v-btn>
        <v-btn
          v-else
          class="pa-5 text-none tag-go-to-plans c_white_1--text mb-4"
          color="c_dark_3"
          rounded
          :small="$vuetify.breakpoint.mdAndDown"
          elevation="0"
          @click="goToContactForm"
        >
          <span class="font-sans-bold">
            {{ $trans("contact_sales") }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    header: {
      type: Object,
      default: () => {},
    },
    featured: {
      type: Boolean,
      default: false,
    },
    isEnterprise: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      adminConfiguration: "setup/getAdminConfiguration",
    }),
    getPrice() {
      return this.header.price[
        this.adminConfiguration.tenant_currency.toLowerCase()
      ];
    },
    isMonthToMonth() {
      return this.header.period === "month";
    },
  },
  methods: {
    selectPlan() {
      this.$root.$emit("planSelectedEvent", {
        slug: this.header.value,
        period_type: this.header.period,
      });
    },
    goToContactForm() {
      this.$root.$emit("openContactForm");
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 160px;
}

@media only screen and (min-width: 960px) {
  .wrapper {
    height: 200px;
  }
}
</style>
