import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  writeAccessCalendars: null,
  readAccessCalendars: null,
  watchedCalendars: null,
  recentlyDisabledByUser: false,
});

const state = getDefaultState();
const getters = {
  getWriteAccessCalendars: (state) => state.writeAccessCalendars || [],
  getReadAccessCalendars: (state) => state.readAccessCalendars || [],
  getWatchedCalendars: (state) => state.watchedCalendars || [],
  getRecentlyDisabledByUser: (state) => state.recentlyDisabledByUser,
};
const actions = {
  fetchCalendars({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getListOfOutlookCalendars()
        .then(({ data }) => {
          const writeAccess = data.filter((calendar) => calendar.can_edit);
          commit("SET_WRITE_ACCESS_CALENDARS", writeAccess);
          commit("SET_READ_ACCESS_CALENDARS", data);

          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  enableOutlookCalendarForEmployee(context, data) {
    return new Promise((resolve, reject) => {
      api
        .enableOutlookCalendarForEmployee(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  pushEvents() {
    return new Promise((resolve, reject) => {
      api
        .pushEventsToOutlookCalendars()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchWatchedEmployeeOutlookCalendars({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getWatchedEmployeeOutlookCalendars()
        .then(({ data }) => {
          commit("SET_WATCHED_CALENDARS", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateWatchedEmployeeOutlookCalendars(context, data) {
    return new Promise((resolve, reject) => {
      api
        .updateWatchedEmployeeOutlookCalendars(data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setRecentlyDisabledByUser({ commit }, status) {
    commit("SET_RECENTLY_DISABLED_BY_USER", status);
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_WRITE_ACCESS_CALENDARS(state, calendars) {
    state.writeAccessCalendars = calendars;
  },
  SET_READ_ACCESS_CALENDARS(state, calendars) {
    state.readAccessCalendars = calendars;
  },
  SET_WATCHED_CALENDARS(state, calendars) {
    state.watchedCalendars = calendars;
  },
  SET_RECENTLY_DISABLED_BY_USER(state, status) {
    state.recentlyDisabledByUser = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
