import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  invoices: null,
});

const state = getDefaultState();

const getters = {
  getInvoices: (state) => state.invoices,
};

const actions = {
  fetchInvoices({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .getInvoices(data)
        .then(({ data }) => {
          commit("SET_INVOICES", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadInvoice(context, invoiceId) {
    return new Promise((resolve, reject) => {
      api
        .downloadInvoice(invoiceId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_INVOICES(state, data) {
    state.invoices = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
