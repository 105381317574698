import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  refreshCustomerNotificationStatus: 0,
});

const getters = {
  getCurrentListResponse: (state) => state.currentListResponse,
  getRefreshCustomerNotificationStatus: (state) =>
    state.refreshCustomerNotificationStatus,
};

const state = getDefaultState();

const actions = {
  fetchAll(context, data) {
    return new Promise((resolve, reject) => {
      api
        .getCustomerNotifications(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  get(context, notificationId) {
    return new Promise((resolve, reject) => {
      api
        .getCustomerNotification(notificationId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  create(context, data) {
    return new Promise((resolve, reject) => {
      api
        .createCustomerNotification(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  refreshNewsletter({ commit }) {
    commit(
      "SET_REFRESH_NEWSLETTER_STATUS",
      Math.floor(Math.random() * 10000000),
    );
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_REFRESH_NEWSLETTER_STATUS(state, value) {
    state.refreshCustomerNotificationStatus = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
