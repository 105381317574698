import eventTypes from "@/components/Event/mixins/eventTypes";

export default {
  mixins: [eventTypes],
  data() {
    return {
      parsedEvent: null,
    };
  },
};
