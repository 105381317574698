export default {
  computed: {
    isBookingType() {
      return !!(
        this.parsedEvent &&
        this.parsedEvent.type === this.$helpers.eventTypes.booking
      );
    },
    isGroupBookingType() {
      return !!(
        this.parsedEvent &&
        this.parsedEvent.type === this.$helpers.eventTypes.groupBooking
      );
    },
    isUnavailableBookingSlotType() {
      return !!(
        this.parsedEvent &&
        this.parsedEvent.type ===
          this.$helpers.eventTypes.unavailableBookingSlot
      );
    },
    isBookingOrGroupType() {
      return this.isBookingType || this.isGroupBookingType;
    },
  },
};
