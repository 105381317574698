var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center flex-wrap mb-2"},[(_vm.getIconForType(_vm.parsedEvent.location.type))?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.getIconForType(_vm.parsedEvent.location.type))+" ")]):_c('img',{staticClass:"mr-2 ml-1",attrs:{"height":"15","src":_vm.getImageForType(_vm.parsedEvent.location.type),"alt":_vm.$trans('service_location_' + _vm.parsedEvent.location.type)}}),_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$trans("service_location"))+": "+_vm._s(_vm.$trans("service_location_" + _vm.parsedEvent.location.type))+" ")])],1),(
        _vm.parsedEvent.location.type ===
          _vm.$helpers.serviceLocationTypes.GOOGLE_MEET ||
        _vm.parsedEvent.location.type === _vm.$helpers.serviceLocationTypes.ZOOM ||
        _vm.parsedEvent.location.type === _vm.$helpers.serviceLocationTypes.TEAMS ||
        _vm.parsedEvent.location.type === _vm.$helpers.serviceLocationTypes.SKYPE ||
        _vm.parsedEvent.location.type === _vm.$helpers.serviceLocationTypes.WHATSAPP
      )?_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"text-break"},[_c('a',{attrs:{"href":_vm.getLink,"target":"_blank","title":_vm.parsedEvent.location.type}},[_vm._v(_vm._s(_vm.getLink))])]),(_vm.getLink)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"blue","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$helpers.copyAndNotify(_vm.getLink)}}},[_c('v-icon',[_vm._v("$copy")])],1):_vm._e()],1):(_vm.parsedEvent.location.data)?_c('div',[_vm._v(" "+_vm._s(_vm.parsedEvent.location.data)+" ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }