import confirmDialogTypes from "@/components/ConfirmDialogs/confirmDialogTypes";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";

export default [
  // Shared
  {
    type: sharedConfirmDialogTypes.COMMON,
    content: () =>
      import(
        "@/lib/calendesk-js-library/components/confirmDialogs/components/CommonDialog"
      ),
  },
  {
    type: sharedConfirmDialogTypes.REMOVE_ASSET,
    content: () =>
      import(
        "@/lib/calendesk-js-library/components/confirmDialogs/components/RemoveAssetDialog"
      ),
  },
  {
    type: sharedConfirmDialogTypes.CHANGE_NAME_ASSET,
    content: () =>
      import(
        "@/lib/calendesk-js-library/components/confirmDialogs/components/ChangeNameAsset"
      ),
  },

  // Local
  {
    type: confirmDialogTypes.USER_REMOVE,
    content: () => import("./RemoveUserDialog"),
  },
  {
    type: confirmDialogTypes.USER_LOCK,
    content: () => import("./UserLockDialog"),
  },
  {
    type: confirmDialogTypes.USER_UNLOCK,
    content: () => import("./UserUnlockDialog"),
  },
  {
    type: confirmDialogTypes.SET_NEW_PASSWORD,
    content: () => import("./SetNewPasswordDialog"),
  },
  {
    type: confirmDialogTypes.ADD_TO_GROUP,
    content: () => import("./AddToGroupDialog"),
  },
  {
    type: confirmDialogTypes.REMOVE_USER_AVATAR,
    content: () => import("./RemoveUserAvatarDialog"),
  },
  {
    type: confirmDialogTypes.ADD_OR_UPDATE_GROUP,
    content: () => import("./GroupDialog"),
  },
  {
    type: confirmDialogTypes.ADD_CATEGORY,
    content: () => import("./AddCategoryDialog"),
  },
  {
    type: confirmDialogTypes.REMOVE_CATEGORY,
    content: () => import("./RemoveCategoryDialog"),
  },
  {
    type: confirmDialogTypes.EDIT_CATEGORY,
    content: () => import("./EditCategoryDialog"),
  },
  {
    type: confirmDialogTypes.ADD_OR_UPDATE_RESOURCE,
    content: () => import("./ResourceDialog"),
  },
  {
    type: confirmDialogTypes.ADD_OR_UPDATE_TAG,
    content: () => import("./TagDialog"),
  },
  {
    type: confirmDialogTypes.REMOVE_RECURRING_EVENT,
    content: () => import("./RemoveRecurringEventDialog"),
  },
  {
    type: confirmDialogTypes.REMOVE_EVENTS,
    content: () => import("./RemoveEventsDialog.vue"),
  },
  {
    type: confirmDialogTypes.CANCEL_USER_SUBSCRIPTION,
    content: () => import("./CancelUserSubscriptionDialog.vue"),
  },
  {
    type: confirmDialogTypes.EDIT_RECURRING_EVENT,
    content: () => import("./UpdateRecurringEventDialog"),
  },
  {
    type: confirmDialogTypes.ADD_STRIPE_PRODUCT,
    content: () => import("./AddStripeProductDialog"),
  },
  {
    type: confirmDialogTypes.ADD_STRIPE_PRODUCT_PRICE,
    content: () => import("./AddStripeProductPriceDialog"),
  },
  {
    type: confirmDialogTypes.GDPR_FIELD,
    content: () => import("@/components/GdprFields/GdprFieldDialog"),
  },
];
