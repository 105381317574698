<template>
  <v-container fluid>
    <v-row>
      <v-col v-if="!defaultPaymentMethod" class="text-left pa-0">
        <v-btn color="blue" outlined @click="openAddPaymentMethodDialog">
          {{ $trans("add_payment_method") }}
        </v-btn>
      </v-col>
      <v-col v-else class="pa-0">
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              class="d-flex align-center justify-start flex-wrap pl-0"
            >
              <div class="d-flex align-center mr-4 my-2">
                <v-img :src="cardLogoURL" max-width="48" class="mr-2" />
                <span v-if="cardInfo" class="text-h6">{{ cardInfo }}</span>
              </div>
              <div>
                <v-btn
                  color="blue"
                  outlined
                  @click="openAddPaymentMethodDialog"
                >
                  {{ $trans("change_payment_method") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import acceptedCards from "@/calendesk/tools/acceptedCards";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "PaymentsMethod",
  mixins: [sharedActions],
  computed: {
    ...mapGetters({
      defaultPaymentMethod: "payments/getDefaultPaymentMethod",
    }),
    cardInfo() {
      if (this.defaultPaymentMethod && this.defaultPaymentMethod.card) {
        return `•••• •••• •••• ${this.defaultPaymentMethod.card.last4}`;
      }

      return null;
    },
    cardLogoURL() {
      if (
        this.defaultPaymentMethod &&
        this.defaultPaymentMethod.card &&
        this.defaultPaymentMethod.card.brand in acceptedCards
      ) {
        return require(`@/assets/cards/${this.defaultPaymentMethod.card.brand}.png`);
      }

      return null;
    },
  },
  methods: {
    ...mapActions({
      deletePaymentMethods: "payments/deletePaymentMethods",
    }),
    openAddPaymentMethodDialog() {
      this.openDialog({
        type: dialogTypes.ADD_PAYMENT_METHOD_DIALOG,
        title: this.$trans("add_payment_method"),
        size: dialogSize.MIDDLE,
        ctaButtonAction: "handleSave",
        ctaButtonIcon: "$save",
        ctaButtonTitle: this.$trans("save"),
        persistent: true,
      });
    },
    deletePaymentMethodConfirmDialog() {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        confirmationText: this.$trans("remove"),
        confirmAction: () => {
          this.setCommonDialogLoader(true);
          this.deletePaymentMethods()
            .then(() => {
              pushEvent("removePaymentMethod");
              successNotification("payment_method_deleted_successfully");
            })
            .catch((error) => {
              errorNotification("payment_method_delete_failed", error, false);
            })
            .finally(() => {
              this.setCommonDialogLoader(false);
              this.closeConfirmDialog();
            });
        },
      });
    },
  },
};
</script>
