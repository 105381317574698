import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  apiKey: null,
});

const state = getDefaultState();
const getters = {
  getApiKey: (state) => state.apiKey,
};
const actions = {
  fetchApiKey({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .getApiKey(data)
        .then(({ data }) => {
          commit("SET_API_KEY", data.key);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createApiKey({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createApiKey(data)
        .then(({ data }) => {
          commit("SET_API_KEY", data.key);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteApiKey({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteApiKey(data)
        .then(() => {
          commit("SET_API_KEY", null);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_API_KEY(state, apiKey) {
    state.apiKey = apiKey;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
