<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <span class="text-body-2">{{ subscriptionText }}</span
        ><br />
        <h3 class="text-h4 blue--text">
          {{ plan.name }}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        {{ $trans("subscription_update_information") }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-alert color="snow_gray" class="p-0 m-0 mb-0">
          <v-container class="pt-0 pb-0">
            <v-row no-gutters>
              <v-col
                cols="6"
                class="font-weight-medium d-flex justify-end align-center pa-4"
              >
                {{ $trans("plan") }}: {{ plan.name }}
              </v-col>
              <v-col cols="5" class="d-flex justify-end align-center">
                <div v-if="promoCodePrice" class="text-right">
                  <span class="text-decoration-line-through">{{
                    period.price.amount
                      | money(
                        adminConfiguration
                          ? adminConfiguration.tenant_currency
                          : null
                      )
                  }}</span>
                  {{
                    planPriceWithPromo
                      | money(
                        adminConfiguration
                          ? adminConfiguration.tenant_currency
                          : null
                      )
                  }}
                </div>
                <span v-else>
                  {{
                    period.price.amount
                      | money(
                        adminConfiguration
                          ? adminConfiguration.tenant_currency
                          : null
                      )
                  }}
                </span>
              </v-col>
            </v-row>
            <template v-if="selectedPlanPackages.length > 0">
              <v-row
                v-for="plugin in selectedPlanPackages"
                :key="plugin.slug"
                no-gutters
              >
                <v-col
                  cols="6"
                  class="font-weight-medium d-flex justify-end align-center pa-4"
                >
                  {{
                    $trans(
                      "plugins_" +
                        plugin.service.slug +
                        "_" +
                        plan.slug +
                        "_name"
                    )
                  }}
                </v-col>
                <v-col cols="5" class="d-flex justify-end align-center">
                  {{
                    plugin.price.amount
                      | money(
                        adminConfiguration
                          ? adminConfiguration.tenant_currency
                          : null
                      )
                  }}
                </v-col>
              </v-row>
            </template>
            <v-divider />
            <v-row no-gutters>
              <v-col
                cols="6"
                class="font-weight-medium d-flex justify-end align-center pa-4"
              >
                {{ subscriptionText }}
              </v-col>
              <v-col cols="5" class="d-flex justify-end align-center">
                <div v-if="getIsReverseChargeApplied" class="text-right">
                  <span>{{
                    subscriptionSummaryPrice
                      | money(
                        adminConfiguration
                          ? adminConfiguration.tenant_currency
                          : null
                      )
                  }}</span
                  ><br />
                  <small>{{ $trans("reverse_charge_info") }}</small>
                </div>
                <div v-else class="text-right">
                  {{
                    subscriptionSummaryPrice
                      | money(
                        adminConfiguration
                          ? adminConfiguration.tenant_currency
                          : null
                      )
                  }}
                  (+
                  {{
                    subscriptionSummaryTaxPrice
                      | money(
                        adminConfiguration
                          ? adminConfiguration.tenant_currency
                          : null
                      )
                  }}
                  {{ $trans("invoice_vat") }} )
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="promoCodeData && promoCodeData.number_of_months > 0"
              no-gutters
            >
              <v-col class="d-flex justify-end" offset="6" cols="5">
                <small
                  >{{ $trans("promo_number_of_months") }}
                  {{ promoCodeData.number_of_months }}</small
                >
              </v-col>
            </v-row>
          </v-container>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="selectedExtraPackages.length > 0">
      <v-col cols="12" class="mt-1">
        <v-alert color="snow_gray">
          <v-container class="pt-0 pb-0">
            <v-row
              v-for="plugin in selectedExtraPackages"
              :key="plugin.slug"
              no-gutters
            >
              <v-col
                cols="6"
                class="font-weight-medium d-flex justify-end align-center pa-4"
              >
                {{
                  $trans(
                    "plugins_" + plugin.service.slug + "_" + plan.slug + "_name"
                  )
                }}
              </v-col>
              <v-col cols="5" class="d-flex justify-end align-center">
                {{
                  plugin.price.amount
                    | money(
                      adminConfiguration
                        ? adminConfiguration.tenant_currency
                        : null
                    )
                }}
              </v-col>
            </v-row>
            <v-divider />
            <v-row no-gutters>
              <v-col
                cols="6"
                class="font-weight-medium d-flex justify-end align-center pa-4"
              >
                {{ $trans("on_time_payment") }}
              </v-col>
              <v-col cols="5" class="d-flex justify-end align-center">
                <span v-if="getIsReverseChargeApplied">
                  {{
                    oneTimeSummaryPrice
                      | money(
                        adminConfiguration
                          ? adminConfiguration.tenant_currency
                          : null
                      )
                  }}
                </span>
                <span v-else>
                  {{
                    oneTimeSummaryPrice
                      | money(
                        adminConfiguration
                          ? adminConfiguration.tenant_currency
                          : null
                      )
                  }}
                  (+
                  {{
                    oneTimeSummaryTaxPrice
                      | money(
                        adminConfiguration
                          ? adminConfiguration.tenant_currency
                          : null
                      )
                  }}
                  {{ $trans("invoice_vat") }} )
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="subscriptionSummaryPrice > 0 || promoCode">
      <v-col cols="12" sm="6" md="4" class="pt-0 d-flex align-center">
        <v-text-field
          v-model="promoCode"
          :label="$trans('promo_code')"
          hide-details
          :rules="[rules.maxChars(255)]"
          outlined
          validate-on-blur
          :loading="verifyingPromoCode"
          :disabled="disabled || verifyingPromoCode || !!promoCodeData"
          @input="validatePromoCode"
        />

        <v-btn
          v-if="promoCodeData"
          icon
          small
          :disabled="disabled"
          class="ml-2"
          @click="removePromoCode"
        >
          <v-icon color="error"> $trash-default</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "debounce";
import { maxChars } from "@/lib/calendesk-js-library/forms/validators";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "OrderPlanSummary",
  mixins: [sharedActions],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      plan: null,
      period: null,
      periodType: null,
      promoCodePrice: 0,
      promoCode: null,
      promoCodeData: null,
      verifyingPromoCode: false,
      vatRate: 23,
      selectedPlanPackages: [],
      selectedExtraPackages: [],
      selectedPlanPackagesPrice: 0,
      selectedExtraPackagesPrice: 0,
      rules: {
        maxChars,
      },
    };
  },
  computed: {
    ...mapGetters({
      adminConfiguration: "setup/getAdminConfiguration",
      invoiceData: "payments/getInvoiceData",
      subscriptionToPay: "payments/getSubscriptionToPay",
      getIsReverseChargeApplied: "payments/getIsReverseChargeApplied",
    }),
    planPriceWithPromo() {
      if (this.period) {
        return this.period.price.amount - this.promoCodePrice;
      }

      return 0;
    },
    subscriptionText() {
      if (this.plan && this.periodType === this.$helpers.periodTypes.MONTH) {
        return this.$trans("monthly_subscription");
      }

      return this.$trans("yearly_subscription");
    },
    subscriptionSummaryPrice() {
      return this.planPriceWithPromo + this.selectedPlanPackagesPrice;
    },
    oneTimeSummaryPrice() {
      return this.selectedExtraPackagesPrice;
    },
    subscriptionSummaryTaxPrice() {
      if (this.getIsReverseChargeApplied) {
        return 0;
      }

      return this.subscriptionSummaryPrice * (this.vatRate / 100);
    },
    oneTimeSummaryTaxPrice() {
      if (this.getIsReverseChargeApplied) {
        return 0;
      }

      return this.oneTimeSummaryPrice * (this.vatRate / 100);
    },
  },
  created() {
    this.plan = this.subscriptionToPay.plan;
    this.period = this.subscriptionToPay.period;
    this.periodType = this.subscriptionToPay.periodType;
    this.selectedPlugins = this.subscriptionToPay.selectedPlugins;
    this.selectedPlanPackages = this.subscriptionToPay.selectedPlanPackages;
    this.selectedExtraPackages = this.subscriptionToPay.selectedExtraPackages;
    this.selectedPlanPackagesPrice =
      this.subscriptionToPay.selectedPlanPackagesPrice;
    this.selectedExtraPackagesPrice =
      this.subscriptionToPay.selectedExtraPackagesPrice;
  },
  methods: {
    ...mapActions({
      verifyPromoCode: "packages/verifyPromoCode",
      setSubscriptionToPay: "payments/setSubscriptionToPay",
    }),
    validatePromoCode: debounce(function () {
      this.verifyingPromoCode = true;
      this.verifyPromoCode({
        code: this.promoCode,
        period: this.period.id,
      })
        .then((data) => {
          this.promoCodeData = data;
          this.calculatePromoCodePrice();
          successNotification("promo_code_is_valid");
        })
        .catch((error) => {
          errorNotification("promo_code_is_invalid", error, false);
        })
        .finally(() => {
          this.verifyingPromoCode = false;
        });
    }, 600),
    calculatePromoCodePrice() {
      if (this.promoCodeData) {
        if (this.promoCodeData.amount_off) {
          this.promoCodePrice = this.promoCodeData.amount_off;
        } else if (this.promoCodeData.percent_off) {
          this.promoCodePrice =
            (this.period.price.amount / 100) * this.promoCodeData.percent_off;
        }

        if (this.promoCodePrice > this.period.price.amount) {
          this.promoCodePrice = this.period.price.amount;
        }

        const data = this.$helpers.cloneObject(this.subscriptionToPay);
        data.promoCode = this.promoCode;
        data.promoCodePrice = this.promoCodePrice;
        this.setSubscriptionToPay(data);
      }
    },
    removePromoCode() {
      this.promoCodePrice = 0;
      this.promoCodeData = null;
      this.promoCode = null;
    },
  },
};
</script>
