<template>
  <v-dialog
    v-if="newClientModal"
    v-model="newClientModal"
    persistent
    scrollable
    :fullscreen="!$vuetify.breakpoint.mdAndUp"
    :width="$vuetify.breakpoint.mdAndUp ? '900px' : '100%'"
  >
    <v-card color="white">
      <v-toolbar flat max-height="56px" fixed>
        <v-btn fab x-small @click="closeModal">
          <v-icon> $close </v-icon>
        </v-btn>
        <v-card-title>
          <div>
            {{ $trans("add_client") }}
          </div>
        </v-card-title>
        <v-spacer />
        <v-btn
          color="secondary"
          depressed
          outlined
          :loading="isAddingClient"
          :disabled="isAddingClient"
          @click="handleSave"
        >
          <v-icon left>$plus-circle</v-icon>
          <span>{{ $trans("add") }}</span>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <user-full-form
          ref="form"
          dialog-mode
          @isAddingClient="isAddingClient = $event"
          @userAdded="handleUserAdded"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserFullForm from "@/views/dashboard/pages/Users/components/UserFullForm.vue";
import { successNotification } from "@/lib/calendesk-js-library/tools/notification";

export default {
  name: "NewClientModal",
  components: { UserFullForm },
  data() {
    return {
      isAddingClient: false,
    };
  },
  computed: {
    ...mapGetters({
      newClientModal: "calendar/getNewClientModal",
    }),
    modal: {
      set(value) {
        this.setNewClientModal(value);
      },
      get() {
        return this.newClientModal;
      },
    },
  },
  methods: {
    ...mapActions({
      setNewClientModal: "calendar/setNewClientModal",
    }),
    closeModal() {
      this.setNewClientModal(false);
    },
    handleSave() {
      this.$refs.form.handleSave();
    },
    handleUserAdded() {
      this.closeModal();
      successNotification("user_created_successfully");
    },
  },
};
</script>
