<template>
  <v-autocomplete
    v-model="currency"
    outlined
    :items="items"
    :label="label"
    :disabled="disabled"
    :dense="dense"
    :hide-details="hideDetails"
    :prepend-icon="showIcon ? '$cash' : null"
    :placeholder="$trans('autocomplete_hint')"
    :rules="[rules.required]"
    @input="inputEventHandle"
  />
</template>

<script>
import { required } from "@/lib/calendesk-js-library/forms/validators";

export default {
  name: "CCurrencySelect",
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "Currency",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      rules: { required },
      items: [
        {
          text: this.$trans("c_currency_select_aud"),
          value: "AUD",
        },
        {
          text: this.$trans("c_currency_select_bgn"),
          value: "BGN",
        },
        {
          text: this.$trans("c_currency_select_brl"),
          value: "BRL",
        },
        {
          text: this.$trans("c_currency_select_cad"),
          value: "CAD",
        },
        {
          text: this.$trans("c_currency_select_chf"),
          value: "CHF",
        },
        {
          text: this.$trans("c_currency_select_clp"),
          value: "CLP",
        },
        {
          text: this.$trans("c_currency_select_czk"),
          value: "CZK",
        },
        {
          text: this.$trans("c_currency_select_dkk"),
          value: "DKK",
        },
        {
          text: this.$trans("c_currency_select_eur"),
          value: "EUR",
        },
        {
          text: this.$trans("c_currency_select_gbp"),
          value: "GBP",
        },
        {
          text: this.$trans("c_currency_select_hkd"),
          value: "HKD",
        },
        {
          text: this.$trans("c_currency_select_huf"),
          value: "HUF",
        },
        {
          text: this.$trans("c_currency_select_ils"),
          value: "ILS",
        },
        {
          text: this.$trans("c_currency_select_inr"),
          value: "INR",
        },
        {
          text: this.$trans("c_currency_select_jpy"),
          value: "JPY",
        },
        {
          text: this.$trans("c_currency_select_mxn"),
          value: "MXN",
        },
        {
          text: this.$trans("c_currency_select_myr"),
          value: "MYR",
        },
        {
          text: this.$trans("c_currency_select_nok"),
          value: "NOK",
        },
        {
          text: this.$trans("c_currency_select_nzd"),
          value: "NZD",
        },
        {
          text: this.$trans("c_currency_select_php"),
          value: "PHP",
        },
        {
          text: this.$trans("c_currency_select_pln"),
          value: "PLN",
        },
        {
          text: this.$trans("c_currency_select_ron"),
          value: "RON",
        },
        {
          text: this.$trans("c_currency_select_rub"),
          value: "RUB",
        },
        {
          text: this.$trans("c_currency_select_sgd"),
          value: "SGD",
        },
        {
          text: this.$trans("c_currency_select_sek"),
          value: "SEK",
        },
        {
          text: this.$trans("c_currency_select_thb"),
          value: "THB",
        },
        {
          text: this.$trans("c_currency_select_twd"),
          value: "TWD",
        },
        {
          text: this.$trans("c_currency_select_uah"),
          value: "UAH",
        },
        {
          text: this.$trans("c_currency_select_usd"),
          value: "USD",
        },
        {
          text: this.$trans("c_currency_select_zar"),
          value: "ZAR",
        },
        {
          text: this.$trans("c_currency_select_bif"),
          value: "BIF",
        },
        {
          text: this.$trans("c_currency_select_djf"),
          value: "DJF",
        },
        {
          text: this.$trans("c_currency_select_gnf"),
          value: "GNF",
        },
        {
          text: this.$trans("c_currency_select_kmf"),
          value: "KMF",
        },
        {
          text: this.$trans("c_currency_select_krw"),
          value: "KRW",
        },
        {
          text: this.$trans("c_currency_select_mga"),
          value: "MGA",
        },
        {
          text: this.$trans("c_currency_select_pyg"),
          value: "PYG",
        },
        {
          text: this.$trans("c_currency_select_rwf"),
          value: "RWF",
        },
        {
          text: this.$trans("c_currency_select_ugx"),
          value: "UGX",
        },
        {
          text: this.$trans("c_currency_select_vnd"),
          value: "VND",
        },
        {
          text: this.$trans("c_currency_select_vuv"),
          value: "VUV",
        },
        {
          text: this.$trans("c_currency_select_xaf"),
          value: "XAF",
        },
        {
          text: this.$trans("c_currency_select_xof"),
          value: "XOF",
        },
        {
          text: this.$trans("c_currency_select_xpf"),
          value: "XPF",
        },
      ],
      currency: this.value,
    };
  },
  watch: {
    value() {
      this.currency = this.value;
    },
  },
  methods: {
    inputEventHandle(currency) {
      this.$emit("input", currency);
    },
  },
};
</script>
