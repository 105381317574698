var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"color":"c_dark","bottom":"","max-width":"400","z-index":"999999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var _obj, _obj$1;

var on = ref.on;
var attrs = ref.attrs;return [_c('div',_vm._g(_vm._b({staticClass:"d-none d-md-flex align-center wrapper"},'div',attrs,false),on),[(_vm.feature[_vm.planType + '_icon'])?[(_vm.feature.icon_as_image)?_c('v-img',{staticClass:"mr-2",attrs:{"src":_vm.feature[_vm.planType + '_icon'],"max-width":"24","max-height":"24"}}):_c('v-icon',{staticClass:"mr-2",attrs:{"size":"24","color":_vm.feature[_vm.planType + '_icon_color']}},[_vm._v(" "+_vm._s(_vm.feature[_vm.planType + "_icon"])+" ")])]:_vm._e(),_c('span',{staticClass:"c-text-16",class:( _obj = {
          'font-weight-bold': _vm.feature['bold_' + _vm.planType]
        }, _obj[_vm.feature[_vm.planType + '_text_color'] + '--text'] = true, _obj )},[_vm._v(" "+_vm._s(_vm.feature[_vm.planType])+" ")])],2),_c('div',_vm._g(_vm._b({staticClass:"d-md-none align-center text-center wrapper"},'div',attrs,false),on),[(_vm.feature[_vm.planType + '_icon'])?_c('div',{staticClass:"full-width"},[(_vm.feature.icon_as_image)?_c('v-img',{staticClass:"mx-auto mb-2",attrs:{"src":_vm.feature[_vm.planType + '_icon'],"max-width":"24","max-height":"24"}}):_c('v-icon',{attrs:{"size":"24","color":_vm.feature[_vm.planType + '_icon_color']}},[_vm._v(" "+_vm._s(_vm.feature[_vm.planType + "_icon"])+" ")])],1):_vm._e(),_c('span',{staticClass:"c-text-13",class:( _obj$1 = {
          'font-weight-bold': _vm.feature['bold_' + _vm.planType]
        }, _obj$1[_vm.feature[_vm.planType + '_text_color'] + '--text'] = true, _obj$1 )},[_vm._v(" "+_vm._s(_vm.feature[_vm.planType])+" ")])])]}}])},[_c('span',[_vm._v(_vm._s(_vm.feature["tooltip_" + _vm.planType]))])])}
var staticRenderFns = []

export { render, staticRenderFns }