var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","fullscreen":"","loading":_vm.isLoading,"content-class":"white fill-height","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"flat":""}},[(_vm.payload)?_c('v-toolbar',{attrs:{"flat":"","max-height":"56px","fixed":""}},[_c('v-btn',{attrs:{"fab":"","x-small":"","disabled":_vm.isLoading},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v(" $close ")])],1),_c('v-toolbar-title',{staticClass:"ml-4"},[_c('div',{staticClass:"d-flex align-center justify-center flex-wrap"},[_c('div',{staticClass:"text-wrap"},[_vm._v(_vm._s(_vm.payload.title))])])]),_c('v-spacer'),_c('div',[_c('v-btn',{attrs:{"color":"secondary","depressed":"","outlined":"","loading":_vm.isLoading},on:{"click":_vm.handleSave}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$send")]),_c('span',[_vm._v(_vm._s(_vm.$trans("send")))])],1)],1)],1):_vm._e(),_c('v-card-text',{staticClass:"mt-8 mx-0 px-0"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSave.apply(null, arguments)}}},[_c('v-container',[_c('v-row',{staticClass:"mb-8"},[_c('v-col',{staticClass:"text-truncate",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$trans("to"))+": ("+_vm._s(_vm.$trans("send_customer_notification_number_info"))+": "+_vm._s(_vm.numberOfReceivers)+") "+_vm._s(_vm.receiversToShow)+" ")])],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.notificationTypes,"label":_vm.$trans('notification_type_title'),"outlined":"","hide-details":"","disabled":_vm.isLoading},model:{value:(_vm.notificationType),callback:function ($$v) {_vm.notificationType=$$v},expression:"notificationType"}})],1)],1),(
              _vm.notificationType === _vm.$helpers.customerNotificationType.email ||
              _vm.notificationType === _vm.$helpers.customerNotificationType.push
            )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"subject",attrs:{"rules":[_vm.rules.required, _vm.rules.maxChars(255)],"label":_vm.$trans('notification_subject'),"outlined":"","counter":_vm.counterSubjectLength,"counter-value":_vm.counterValue,"disabled":_vm.isLoading},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('notification-variable-menu',{staticClass:"text-field-notification-variable-menu",attrs:{"variables":_vm.getVariables},on:{"select":function($event){return _vm.insertTextOnCursorPosition($event, 'subject', 'subject')}}})]},proxy:true}],null,false,3037755138),model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})],1)],1):_vm._e(),(
              _vm.notificationType === _vm.$helpers.customerNotificationType.email
            )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('tip-tap',{ref:"textarea",attrs:{"placeholder":_vm.$trans('notification_content'),"disabled":_vm.isLoading},on:{"input":function($event){_vm.content = $event}},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1),_c('v-col',[_c('notification-variable-menu',{attrs:{"show-text":"","variables":_vm.getVariables},on:{"select":function($event){return _vm.insertTextToHTMLTextarea($event, 'textarea')}}})],1)],1):_c('v-row',[(
                _vm.getNonGsmCharacters(_vm.content) &&
                _vm.getNonGsmCharacters(_vm.content).length > 0
              )?_c('v-col',[_vm._v(" "+_vm._s(_vm.$trans("notification_contains_special_characters"))+": "+_vm._s(_vm.getNonGsmCharacters(_vm.content).join(", "))+" ")]):_vm._e(),_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"12"}},[_c('v-textarea',{ref:"textarea",attrs:{"placeholder":_vm.$trans('notification_content'),"disabled":_vm.isLoading,"height":"150","outlined":"","counter":_vm.counterContentLength,"counter-value":_vm.counterValue,"hint":_vm.getTextareaHint,"persistent-hint":""},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"12"}},[_c('notification-variable-menu',{attrs:{"show-text":"","variables":_vm.getVariables},on:{"select":function($event){return _vm.insertTextOnCursorPosition($event, 'textarea', 'content')}}})],1),(_vm.getTextareaHint)?_c('v-col',[_c('small',[_vm._v("*"+_vm._s(_vm.$trans("sms_number_of_messages_info")))])]):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }