<template>
  <v-container fluid class="pa-0 ma-0">
    <template v-if="selectedFields && selectedFields.length > 0">
      <v-row v-for="(field, index) in selectedFields" :key="index" no-gutters>
        <v-col cols="6">
          <div>
            {{ field.name }} (<span v-if="field.required">{{
              $trans("wb_dynamic_form_should_require")
            }}</span
            ><span v-else>{{ $trans("optional_field") }}</span
            >)
          </div>
        </v-col>
        <v-col cols="6">
          <v-btn icon :disabled="disabled" @click="deleteField(index)">
            <v-icon color="error">$trash-default</v-icon>
          </v-btn>
          <v-btn
            icon
            color="light"
            :disabled="disabled"
            @click="editField(index)"
          >
            <v-icon> $pencil</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-row no-gutters>
      <v-col>
        <v-btn
          color="secondary"
          outlined
          :disabled="disabled"
          small
          @click="addField"
        >
          <v-icon left>$plus-circle</v-icon>
          {{ $trans("wb_add_rgdpr_field_element") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dialogTypes from "@/components/ConfirmDialogs/confirmDialogTypes";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  mixins: [sharedActions],
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedFields: [],
    };
  },
  watch: {
    value() {
      this.assignValue();
    },
    "getConfirmDialog.open": function (isOpen) {
      if (
        !isOpen &&
        this.getConfirmDialog.data &&
        this.getConfirmDialog.data.field
      ) {
        const { field } = this.getConfirmDialog.data;
        const find = this.selectedFields.find((item) => item.key === field.key);

        if (find) {
          find.name = field.name;
          find.label = field.label;
          find.required = field.required;
          find.key = field.key;
        } else {
          this.selectedFields.push({
            name: field.name,
            label: field.label,
            required: field.required,
            key: field.key,
          });
        }

        this.onChange();
      }
    },
  },
  created() {
    this.assignValue();
  },
  methods: {
    assignValue() {
      this.selectedFields = this.value
        ? this.$helpers.cloneObject(this.value)
        : [];
    },
    deleteField(index) {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        confirmationText: this.$trans("delete"),
        confirmAction: () => {
          this.selectedFields.splice(index, 1);
          this.onChange();
          this.closeConfirmDialog();
        },
      });
    },
    addField() {
      this.openConfirmDialog({
        type: dialogTypes.GDPR_FIELD,
      });
    },
    editField(index) {
      this.openConfirmDialog({
        type: dialogTypes.GDPR_FIELD,
        data: {
          field: this.selectedFields[index],
        },
      });
    },
    onChange() {
      this.$emit("change", this.$helpers.cloneObject(this.selectedFields));
    },
  },
};
</script>
