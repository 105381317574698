import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  data: null,
  refreshGroupsStatus: null,
  newGroup: null,
});

const state = getDefaultState();
const getters = {
  groups: (state) => state.data,
  shouldRefreshGroups: (state) => state.refreshGroupsStatus,
  getNewGroup: (state) => state.newGroup,
};
const actions = {
  fetchAll({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .groups({
          limit: 999999, // HACK:
        })
        .then(({ data }) => {
          commit("SET_DATA", data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchPaginated(context, data) {
    return new Promise((resolve, reject) => {
      api
        .groups(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  create(context, name) {
    return new Promise((resolve, reject) => {
      api
        .createGroup({ name })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateGroup(payload, payload.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  delete(context, groupId) {
    return new Promise((resolve, reject) => {
      api
        .deleteGroup(groupId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  refreshGroups({ dispatch, commit }) {
    dispatch("cache/clearCache", "group-list", {
      root: true,
    });

    commit("SET_REFRESH_GROUPS", true);
    setTimeout(() => {
      commit("SET_REFRESH_GROUPS", false);
    }, 1000);
  },
  setNewGroup({ commit }, group) {
    commit("SET_NEW_GROUP", group);
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_REFRESH_GROUPS(state, value) {
    state.refreshGroupsStatus = value;
  },
  SET_NEW_GROUP(state, group) {
    state.newGroup = group;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
