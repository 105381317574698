<template>
  <v-row justify="center">
    <v-col cols="12">
      <div>
        <div class="font-weight-medium mb-2">{{ $trans("client") }}:</div>
        <div>
          <div>
            <user-card-details
              dense
              justify-left
              clickable
              :user="parsedEvent.user"
            />
          </div>
          <div v-if="bookingControlUrl" class="mt-2">
            <div>
              <span class="font-weight-medium mb-2"
                >{{ $trans("booking_customer_link") }}:</span
              >
              <div class="d-flex align-center">
                <div class="text-break">
                  <a :href="bookingControlUrl" target="_blank"
                    >{{ bookingControlUrl }}
                  </a>
                </div>
                <v-btn
                  color="blue"
                  icon
                  class="ml-4"
                  @click.stop="$helpers.copyAndNotify(bookingControlUrl)"
                >
                  <v-icon>$copy</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import UserCardDetails from "@/components/UserCardDetails.vue";
import { mapGetters } from "vuex";

export default {
  name: "EventUser",
  components: { UserCardDetails },
  props: {
    parsedEvent: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      webDrafts: "webDraft/drafts",
    }),
    bookingControlUrl() {
      return this.getDomain && this.parsedEvent.control
        ? `${this.getDomain}/bookings/${this.parsedEvent.control}`
        : "";
    },
    getDomain() {
      if (
        this.parsedEvent &&
        this.webDrafts &&
        this.webDrafts.length > 0 &&
        this.webDrafts[0].domain
      ) {
        let draft = this.webDrafts[0];
        if (this.parsedEvent.draftUuid) {
          draft = this.webDrafts.find(
            (draft) => draft.uuid === this.parsedEvent.draftUuid
          );
        } else {
          draft = this.webDrafts.find(
            (draft) => draft.domain && draft.domain.is_main
          );
        }

        if (draft && draft.domain) {
          return `https://${draft.domain.domain}`;
        }
      }

      return null;
    },
  },
  methods: {},
};
</script>
