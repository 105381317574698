<template>
  <v-item-group
    v-if="parsedEvent"
    class="row-list-buttons__wrapper v-btn-toggle"
  >
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn
          depressed
          outlined
          :small="small"
          @click.stop="fire('edit')"
          v-on="on"
        >
          <v-icon color="light">$pencil</v-icon>
        </v-btn>
      </template>
      <span>{{ $trans("edit") }}</span>
    </v-tooltip>

    <v-tooltip v-if="canCancelItem(parsedEvent)" bottom>
      <template #activator="{ on }">
        <v-btn
          depressed
          outlined
          :small="small"
          @click.stop="fire('cancel')"
          v-on="on"
        >
          <v-icon color="error">$close</v-icon>
        </v-btn>
      </template>
      <span v-if="isGroupBooking(parsedEvent)">{{
        $trans("cancel_group_bookings_title")
      }}</span>
      <span v-else>{{ $trans("cancel") }}</span>
    </v-tooltip>

    <v-tooltip v-if="canDeleteItem(parsedEvent)" bottom>
      <template #activator="{ on }">
        <v-btn
          depressed
          outlined
          :small="small"
          @click.stop="fire('delete')"
          v-on="on"
        >
          <v-icon color="error">$trash-default</v-icon>
        </v-btn>
      </template>
      <span v-if="isGroupBooking(parsedEvent)">{{
        $trans("delete_group_bookings_title")
      }}</span>
      <span v-else>{{ $trans("delete") }}</span>
    </v-tooltip>

    <v-tooltip v-if="canItemHandlePayments(parsedEvent)" bottom>
      <template #activator="{ on }">
        <v-btn
          depressed
          outlined
          :small="small"
          @click.stop="fire(parsedEvent.paid ? 'payments' : 'pay')"
          v-on="on"
        >
          <v-icon color="light">$cash</v-icon>
        </v-btn>
      </template>
      <span v-if="parsedEvent.paid">{{ $trans("pay") }}</span>
      <span v-else>{{ $trans("user_payments") }}</span>
    </v-tooltip>

    <v-tooltip v-if="canEventBeDuplicated(parsedEvent)" bottom>
      <template #activator="{ on }">
        <v-btn
          depressed
          outlined
          :small="small"
          @click.stop="fire('duplicate')"
          v-on="on"
        >
          <v-icon color="light">$copy</v-icon>
        </v-btn>
      </template>
      <span v-if="isGroupBooking(parsedEvent)">{{
        $trans("duplicate_group_bookings_title")
      }}</span>
      <span v-else>{{ $trans("duplicate") }}</span>
    </v-tooltip>

    <v-tooltip v-if="canItemSendNotificationsToCustomers(parsedEvent)" bottom>
      <template #activator="{ on }">
        <v-btn
          depressed
          outlined
          :small="small"
          @click.stop="fire('send_notification_to_customers')"
          v-on="on"
        >
          <v-icon color="light">$send</v-icon>
        </v-btn>
      </template>
      <span v-if="isGroupBooking(parsedEvent)">{{
        $trans("send_customers_notification_title")
      }}</span>
      <span v-else>{{ $trans("send_customer_notification_title") }}</span>
    </v-tooltip>
    <v-tooltip
      v-if="
        isUnavailableBookingSlot(parsedEvent) &&
        canItemSendNotificationsToEmployees(parsedEvent)
      "
      bottom
    >
      <template #activator="{ on }">
        <v-btn
          depressed
          outlined
          :small="small"
          @click.stop="fire('send_notification_to_employees')"
          v-on="on"
        >
          <v-icon color="light">$send</v-icon>
        </v-btn>
      </template>
      <span>{{ $trans("send_employee_notification_title") }}</span>
    </v-tooltip>
  </v-item-group>
</template>

<script>
import eventTypes from "@/components/Event/mixins/eventTypes";
import eventActions from "@/views/dashboard/pages/Sales/Events/components/EventList/mixins/eventActions";

export default {
  name: "EventActions",
  mixins: [eventTypes, eventActions],
  props: {
    parsedEvent: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    fire(action) {
      this.fireAction(action, this.$helpers.cloneObject(this.parsedEvent));
    },
  },
};
</script>
