<template>
  <v-row justify="center">
    <v-col cols="12">
      <div>
        <div class="font-weight-medium mb-2">
          {{ $trans("bookings_in_group") }} ({{ parsedEvent.bookings.length }}):
        </div>
      </div>
      <div>
        <div
          v-for="(booking, index) in parsedEvent.bookings"
          :key="booking.id"
          class="mb-2 mr-2"
        >
          <div class="mb-1">
            <span class="font-weight-medium"
              >{{ $trans("booking_id") }}: {{ booking.id }}</span
            >
          </div>
          <div v-if="booking.user" class="mb-2">
            <user-card-details
              dense
              justify-left
              clickable
              :user="booking.user"
            />
          </div>
          <div>
            <event-status-chips :parsed-event="booking" />
          </div>
          <div class="my-1">
            <event-actions
              small
              :parsed-event="getSingleBookingObject(booking)"
            />
          </div>
          <v-divider v-if="index < parsedEvent.bookings.length - 1" />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import EventStatusChips from "@/components/Event/EventStatusChips.vue";
import EventActions from "@/components/Event/EventActions.vue";
import UserCardDetails from "@/components/UserCardDetails.vue";

export default {
  name: "EventBookings",
  components: {
    UserCardDetails,
    EventActions,
    EventStatusChips,
  },
  props: {
    parsedEvent: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getSingleBookingObject(booking) {
      return {
        ...booking,
        service: this.parsedEvent.service,
        employee: this.parsedEvent.employee,
        multiSlotGroupId: this.parsedEvent.multiSlotGroupId,
        recurrenceParams: this.parsedEvent.recurrenceParams,
        location: this.parsedEvent.location,
      };
    },
  },
};
</script>
