import api from "@/lib/calendesk-js-library/api/api";
import router from "../../router/index";
import exampleDataPL from "@/views/dashboard/pages/Tools/Mobile/tools/exampleData_pl";
import exampleDataEn from "@/views/dashboard/pages/Tools/Mobile/tools/exampleData_en";

const location = localStorage.locale;

let exampleData;
if (location === "pl") {
  exampleData = exampleDataPL;
} else {
  exampleData = exampleDataEn;
}

const getDefaultState = () => ({
  draft: {},
  reload: null,
  data: {},
  colors: {},
  undo: [],
  redo: [],
  lastChange: {},
  previewView: 0,
  previewConfiguration: {},
  previewData: {
    services: [],
    employees: [],
    scheduledBookings: exampleData.scheduledBookings,
    afterBookings: exampleData.afterBookings,
    profile: exampleData.profile,
  },
  refreshMobileDrafts: false,
});

const state = getDefaultState();
const getters = {
  shouldRefreshMobileDrafts: (state) => state.refreshMobileDrafts,
  getDraft: (state) => state.draft,
  getReload: (state) => state.reload,
  getLoading: (state) =>
    !state.data.configuration ||
    !state.data.translations ||
    !state.previewData.services ||
    state.previewData.services.length < 1 ||
    !state.previewData.employees ||
    state.previewData.employees.length < 1,
  getColors: (state) => state.colors,
  getConfiguration: (state) => state.data.configuration,
  getTranslations: (state) => state.data.translations,
  canUndo: (state) => !state.undo.length > 0,
  canRedo: (state) => !state.redo.length > 0,
  getPreviewView: (state) => state.previewView,
  getPreviewConfiguration: (state) => state.previewConfiguration,
  getServices: (state) => state.previewData.services,
  getEmployees: (state) => state.previewData.employees,
  getScheduledBookings: (state) => state.previewData.scheduledBookings,
  getAfterBookings: (state) => state.previewData.afterBookings,
  getProfile: (state) => state.previewData.profile,
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_RELOAD(state) {
    state.reload = Math.floor(Math.random() * 100000000);
  },
  UPDATE_DRAFT(state, draft) {
    state.draft = draft;
  },
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_COLORS(state, colors) {
    state.colors = {
      ...state.colors,
      ...colors,
    };
  },
  UPDATE_CONFIGS(state, configuration) {
    state.data.configuration = {
      ...state.data.configuration,
      ...configuration.settings,
    };
    state.data.translations = {
      ...state.data.translations,
      ...configuration.translations,
    };
  },
  RESET_UNDO(state) {
    state.undo = [];
  },
  RESET_REDO(state) {
    state.redo = [];
  },
  REMOVE_UNDO(state) {
    state.undo.shift();
  },
  REMOVE_REDO(state) {
    state.redo.shift();
  },
  ADD_UNDO(state, data) {
    state.undo.unshift(data);
  },
  ADD_REDO(state, data) {
    state.redo.unshift(data);
  },
  SET_LAST_CHANGE(state, change) {
    state.lastChange = change;
  },
  SET_PREVIEW_VIEW(state, previewView) {
    if (previewView === 0) return;
    state.previewView = previewView;
  },
  SET_PREVIEW_VIEW_CONFIGURATION(state, previewConfiguration) {
    state.previewConfiguration = previewConfiguration;
  },
  SET_PREVIEW_DATA(state, data) {
    state.previewData = {
      ...state.previewData,
      ...data,
    };
  },
  SET_REFRESH_MOBILE_DRAFTS(state, value) {
    state.refreshMobileDrafts = value;
  },
};
const actions = {
  fetchAll(context, data) {
    return new Promise((resolve, reject) => {
      api
        .getMobileDrafts(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchData({ state, commit }, id) {
    const getMobileDraft = api
      .getMobileDraft(id)
      .then((response) => {
        commit("UPDATE_DRAFT", response.data.draft);
        commit("SET_DATA", {
          ...state.data,
          configuration: response.data.settings,
          translations: response.data.translations,
        });
        const colors = {};
        for (const key in response.data.settings) {
          if (key.includes("tenants_mobile_color")) {
            colors[key] = response.data.settings[key];
          }
        }
        commit("SET_COLORS", { ...colors });
      })
      .catch((error) => error);

    const params = { ascending: 1 };

    const categoriesAndServices = api
      .categoriesAndServices(params)
      .then(({ data }) => {
        if (!data.data || data.data.length < 1) {
          commit("SET_PREVIEW_DATA", { services: exampleData.services });
          return;
        }
        commit("SET_PREVIEW_DATA", { services: data.data });
      })
      .catch((error) => {
        console.log(error);
      });

    const employees = api
      .employees({ ascending: 1, status: "active" })
      .then(({ data }) => {
        if (!data.data || data.data.length < 1) {
          commit("SET_PREVIEW_DATA", { employees: exampleData.employees });
          return;
        }
        commit("SET_PREVIEW_DATA", { employees: data.data });
      })
      .catch((error) => {
        console.log(error);
      });

    Promise.all([getMobileDraft, categoriesAndServices, employees]);
  },
  changeConfiguration({ state, commit }, configuration) {
    commit("RESET_REDO");
    const undoConfigurations = {};
    const newConfiguration = {
      settings: {},
      translations: {},
      ...configuration,
    };
    for (const configsKey in newConfiguration) {
      undoConfigurations[configsKey] = {};
      for (const key in newConfiguration[configsKey]) {
        if (configsKey === "settings")
          undoConfigurations[configsKey][key] = state.data.configuration[key];
        if (configsKey === "translations")
          undoConfigurations[configsKey][key] = state.data.translations[key];
      }
    }
    commit("SET_LAST_CHANGE", newConfiguration);
    const filterSettings = filterImages(newConfiguration.settings);
    commit("ADD_UNDO", undoConfigurations);
    commit("UPDATE_CONFIGS", {
      ...newConfiguration,
      settings: filterSettings.preview,
    });
    actions.saveChangesOnServer(
      filterSettings.api,
      newConfiguration.translations
    );
  },
  saveChangesOnServer(settings, translations) {
    return new Promise((resolve, reject) => {
      api
        .updateMobileDraft(router.history.current.params.id, {
          settings,
          translations,
        })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateTranslation({ commit }, translations) {
    const config = {
      settings: {},
      ...translations,
    };
    commit("UPDATE_CONFIGS", { ...config });
  },
  undoChanges({ state, commit }) {
    const data = state.undo[0];
    const redo = getCurrent(state.data, data);
    commit("ADD_REDO", redo);

    commit("REMOVE_UNDO");
    commit("SET_LAST_CHANGE", data);
    const filterSettings = filterImages(data.settings);
    commit("UPDATE_CONFIGS", {
      ...data,
      settings: filterSettings.preview,
    });
    actions.saveChangesOnServer(filterSettings.api, data.translations);
  },
  redoChanges({ state, commit }) {
    const data = state.redo[0];

    const undo = getCurrent(state.data, data);

    commit("ADD_UNDO", undo);

    commit("REMOVE_REDO");
    commit("UPDATE_CONFIGS", data);
    commit("SET_LAST_CHANGE", data);
    const filterSettings = filterImages(data.settings);
    commit("UPDATE_CONFIGS", {
      ...data,
      settings: filterSettings.preview,
    });
    actions.saveChangesOnServer(filterSettings.api, data.translations);
  },
  saveConfiguration({ state }) {
    return new Promise((resolve) => {
      const filterSettings = filterImages(state.lastChange);
      api
        .updateMobileDraft(router.history.current.params.id, {
          settings: filterSettings.api,
          translations: state.lastChange.translations,
        })
        .then(() => {
          resolve();
        })
        .catch((error) => error);
    });
  },
  refreshMobileDrafts({ dispatch, commit }) {
    dispatch("cache/clearCache", "mobile-list", {
      root: true,
    });

    commit("SET_REFRESH_MOBILE_DRAFTS", Math.floor(Math.random() * 10000000));
  },
  updateDraft({ commit }, draft) {
    commit("UPDATE_DRAFT", draft);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

function getCurrent(currentState, changes) {
  const result = {};
  for (const configObject in changes) {
    result[configObject] = {};
    for (const key in changes[configObject]) {
      if (configObject === "settings") {
        result[configObject][key] = currentState.configuration[key];
      } else {
        result[configObject][key] = currentState[configObject][key];
      }
    }
  }
  return result;
}

function filterImages(settings) {
  const result = {
    preview: {
      ...settings,
    },
    api: {
      ...settings,
    },
  };
  for (const key in settings) {
    if (key.includes("tenants_mobile_image")) {
      if (settings[key].default_image) {
        result.preview[key] = settings[key].default_image;
        result.api[key] = settings[key].id;
      } else {
        result.preview[key] = settings[key];
        result.api[key] = settings[key].user_image_id;
      }
    }
  }
  return result;
}
