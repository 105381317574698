var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_vm._l((_vm.employees),function(employee,index){return [_c('v-row',{key:index},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$trans('name'),"rules":[_vm.rules.required, _vm.rules.maxChars(50)],"validate-on-blur":"","outlined":"","hide-details":"auto"},model:{value:(employee.name),callback:function ($$v) {_vm.$set(employee, "name", $$v)},expression:"employee.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$trans('surname'),"rules":[_vm.rules.required, _vm.rules.maxChars(50)],"validate-on-blur":"","outlined":"","hide-details":"auto"},model:{value:(employee.surname),callback:function ($$v) {_vm.$set(employee, "surname", $$v)},expression:"employee.surname"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","disabled":employee.root,"label":_vm.$trans('email'),"rules":[
            _vm.rules.email,
            _vm.rules.maxChars(60),
            _vm.rules.uniqueStringValue(
              employee.email,
              _vm.getAllFilteredEmail(employee.tmpId),
              _vm.$trans('email_must_be_unique')
            ) ],"validate-on-blur":"","outlined":"","hide-details":"auto","hint":employee.root ? _vm.$trans('root_email_info') : null,"persistent-hint":employee.root},model:{value:(employee.email),callback:function ($$v) {_vm.$set(employee, "email", $$v)},expression:"employee.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('phone-input',{attrs:{"default-country-code":_vm.defaultCountryCode,"dense":""},model:{value:(employee.phone),callback:function ($$v) {_vm.$set(employee, "phone", $$v)},expression:"employee.phone"}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"10"}},[_c('v-checkbox',{attrs:{"label":_vm.$trans('employee_public_checkbox')},on:{"change":_vm.updated},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('div',_vm._g({},on),[_c('span',[_vm._v(_vm._s(_vm.$trans("employee_public_checkbox")))])])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$trans("signup_onboarding_employee_is_public_info"))+" ")])]},proxy:true}],null,true),model:{value:(employee.isPublic),callback:function ($$v) {_vm.$set(employee, "isPublic", $$v)},expression:"employee.isPublic"}})],1),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(!employee.root)?_c('v-btn',_vm._g({attrs:{"color":"light","icon":""},nativeOn:{"click":function($event){return _vm.remove(index)}}},on),[_c('v-icon',[_vm._v("$trash-default")])],1):_vm._e()]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$trans("delete"))+" ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mb-4"})],1)],1)]}),(_vm.employees && _vm.employees.length < 5)?_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"blue","dark":"","outlined":""},nativeOn:{"click":function($event){return _vm.add.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$trans("add_employee"))+" ")])],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }