<template>
  <iframe
    v-if="src"
    ref="iframe"
    :src="src"
    class="frame"
    :width="width"
    :height="height"
  />
</template>

<script>
export default {
  name: "ExternalFrame",
  props: {
    src: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 600,
    },
    height: {
      type: Number,
      default: 400,
    },
  },
};
</script>

<style lang="scss" scoped>
.frame {
  border: none;
}
</style>
