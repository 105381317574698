import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  data: null,
  selectedService: null,
});

const state = getDefaultState();
const getters = {
  getSelectedService: (state) => state.selectedService,
};
const actions = {
  search(context, data) {
    return new Promise((resolve, reject) => {
      api
        .searchForServices(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  get({ commit }, serviceId) {
    return new Promise((resolve, reject) => {
      api
        .service(serviceId)
        .then(({ data }) => {
          commit("SET_SELECTED_SERVICE", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateService(payload, payload.id)
        .then(({ data }) => {
          dispatch("clearRelatedCache");

          dispatch("category/fetchWithServices", null, { root: true }).then(
            () => {
              resolve(data);
            }
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateMultiple({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateMultipleServices(payload)
        .then(({ data }) => {
          dispatch("clearRelatedCache");
          dispatch("category/fetchWithServices", null, { root: true }).then(
            () => {
              resolve(data);
            }
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  add({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .createService(payload)
        .then(({ data }) => {
          dispatch("cache/clearCache", "categories-services-list", {
            root: true,
          });
          dispatch("cache/clearCache", "availability-list", {
            root: true,
          });
          dispatch("category/fetchWithServices", null, { root: true }).then(
            () => {
              resolve(data);
            }
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  delete({ dispatch }, serviceId) {
    return new Promise((resolve, reject) => {
      api
        .deleteService(serviceId)
        .then(({ data }) => {
          dispatch("clearRelatedCache");

          dispatch("category/fetchWithServices", null, { root: true }).then(
            () => {
              resolve(data);
            }
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addServiceType({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .createServiceType(payload)
        .then(({ data }) => {
          dispatch("cache/clearCache", "categories-services-list", {
            root: true,
          });
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteServiceType({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .deleteServiceType(payload)
        .then(({ data }) => {
          dispatch("cache/clearCache", "categories-services-list", {
            root: true,
          });
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateServiceType({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateServiceType(payload)
        .then(({ data }) => {
          dispatch("cache/clearCache", "categories-services-list", {
            root: true,
          });
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  clearRelatedCache({ dispatch }) {
    dispatch("cache/clearCache", "categories-services-list", {
      root: true,
    });
    dispatch("cache/clearCache", "availability-list", {
      root: true,
    });
    dispatch("cache/clearCache", "availability-list-filters", {
      root: true,
    });
    dispatch("cache/clearCache", "event-list-user", {
      root: true,
    });
    dispatch("cache/clearCache", "event-list", {
      root: true,
    });
    dispatch("cache/clearCache", "event-list-filters", {
      root: true,
    });
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_SELECTED_SERVICE(state, service) {
    state.selectedService = service;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
